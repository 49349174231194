import { Component } from 'react';
import Gantt, { Task } from 'frappe-gantt';
import 'frappe-gantt/dist/frappe-gantt.css';
import { RouteComponentProps, withRouter } from 'react-router';
import { ResearchController } from '../../../controllers/research/research_controller';

import SpinnerLoader from '../../components/spinnerLoader';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';
import { SortType } from '../../../core/number_extentions';
import { User } from '../../../controllers/member/member_transfer_controller';
import { BaseButton } from '@radix-ui/themes/dist/cjs/components/base-button';
import { store } from '../../../data/storeMain';
import DashboardProjectDropdown from '../../components/ActionComponent/DashboardProjectDropdown';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import { AppRoutes } from '../../../core/constants';
import { $ReplaceRoute } from '../../../core/utils';

interface RouteParams {
  id: string;
}

enum GanttViewMode {
  QuarterDay = 'Quarter Day',
  HalfDay = 'Half Day',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

interface TimelineItem {
  appTasks: any[];
  subtasks: any[];
  id: number;
  title: string;
  startDate: Date;
  endDate: Date;
  name: string;
  progress: number;
  createdBy?: string;
  assignedUsers?: any[];
}

interface StateType {
  data: TimelineItem[];
  todayTasks: MainTask[];
  tasks: MainTask[];
  ListFormatDate: { id: number; title: string }[];
  formatDate: number;
  startDate: Date;
  endDate: Date;
  loading: boolean;
  zoomLevel: number;
  setZoominDisable: boolean;
  setZoomOutDisable: boolean;
  selectedResearch: { label: string; value: number };
  researhList: any;
  companyName: string;
}

interface MainTask {
  id: string;
  name: string;
  start: string;
  end: string;
  progress: number;
  dependencies: string;
  custom_class?: string | undefined;
  subtasks?: any[];
  createdBy?: string;
  assignedUsers?: User[];
}

interface SubTask {
  id: string;
  name: string;
  start: string;
  end: string;
  progress: number;
  dependencies: string;
  custom_class?: string | undefined;
  subtasks?: any[];
  createdBy?: string;
  assignedUsers?: User[];
}

const placeholderTasks: Task[] = [
  {
    id: '0',
    name: '',
    start: '',
    end: '',
    progress: 0,
    dependencies: '',
    custom_class: 'd-none',
  },
];

https: interface PropsType extends RouteComponentProps<RouteParams> {}

class TimeLine extends Component<PropsType, StateType> {
  private controller: ResearchController;
  private ganttInstance: Gantt | null = null;

  constructor(props: PropsType) {
    super(props);
    this.customeHtml = this.customeHtml.bind(this);
    this.state = {
      data: [],
      todayTasks: [],
      tasks: [],
      setZoominDisable: false,
      setZoomOutDisable: false,
      selectedResearch: store.getState().ResearchId,
      researhList: [],
      zoomLevel: 3,
      ListFormatDate: [
        { id: 1, title: 'Daily' },
        { id: 2, title: 'Weekly' },
        { id: 3, title: 'Monthly' },
      ],
      formatDate: 1,
      startDate: new Date(),
      endDate: new Date(),
      loading: false,
      companyName: '',
    };
    this.controller = new ResearchController();
  }

  componentDidMount() {
    const currentUrl = window.location.href;
    const startIndex = currentUrl.indexOf('3000/') + '3000/'.length;
    const subStringAfter3000 = currentUrl.substring(startIndex);
    const parts = subStringAfter3000.split('/');
    const companyName = parts[0];
    this.setState({ companyName: companyName });
    this.loadResearches();
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    this.setState({ startDate, endDate }, () => {
      this.getTimelineData(this.state.selectedResearch.value);
    });
  }

  loadResearches() {
    this.controller.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        const researches = res.researchesList?.map(({ id, title }) => ({
          value: id,
          label: title,
        }));
        this.setState({ researhList: researches });
      },
      (err) => {}
    );
  }

  handleChange(target: keyof StateType, val: any) {
    this.setState({
      [target]: val,
    } as Pick<StateType, keyof StateType>);
  }

  handleDateChange(target: keyof StateType, date: Date) {
    const updatedState: Pick<StateType, keyof StateType> = {
      [target]: date,
      data: [],
      todayTasks: [],
      tasks: [],
      ListFormatDate: [],
      formatDate: 0,
      startDate: new Date(),
      endDate: new Date(),
      loading: false,
      zoomLevel: 0,
      setZoominDisable: false,
      setZoomOutDisable: false,
      selectedResearch: { value: 0, label: '' },
      researhList: [],
      companyName: '',
    };

    this.setState(updatedState);
  }

  getTimelineData(SelectedResearch: number) {
    this.setState({ loading: true });
    const body = { id: SelectedResearch };
    this.controller.getTimeline(
      body,
      (res) => {
        if (res.length === 0) {
          // toast.error('No timeline data found');
          this.setState({ loading: false });
          // return
        }
        const formattedData: TimelineItem[] = res.map((item) => {
          const tasks: MainTask[] = [];
          const subTasks: SubTask[] = [];

          if (item.appTask) {
            const appTask = item.appTask;
            const task: MainTask = {
              id: appTask.id.toString(),
              name: appTask.title,
              start: new Date(appTask.startDate).toISOString(),
              end: new Date(appTask.endDate).toISOString(),
              progress: 0,
              subtasks: [],
              dependencies: '',
              createdBy: appTask.creatorUser
                ? `${appTask.creatorUser.firstName} ${appTask.creatorUser.lastName}`
                : '',
              assignedUsers: appTask.users.map(
                (user: { firstName: any; lastName: any; image: any; id: any }) => ({
                  name: `${user.firstName} ${user.lastName}`,
                  image: user.image,
                  id: user.id,
                })
              ),
            };
            tasks.push(task);
          }
          if (item.subAppTasks && item.subAppTasks.length > 0) {
            item.subAppTasks.forEach((subTask: any) => {
              const subtaskStartDate = new Date(subTask.startDate); // Use current subTask in the loop
              const subtaskEndDate = new Date(subTask.endDate);

              const formattedSubTask: SubTask = {
                id: subTask.id.toString(),
                name: subTask.title,
                start: subtaskStartDate.toISOString(),
                end: subtaskEndDate.toISOString(),
                progress: 0,
                createdBy: subTask.creatorUser
                  ? `${subTask.creatorUser.firstName} ${subTask.creatorUser.lastName}`
                  : '',
                assignedUsers: subTask.users.map(
                  (user: { firstName: any; lastName: any; image: any; id: any }) => ({
                    name: `${user.firstName} ${user.lastName}`,
                    image: user.image,
                    id: user.id,
                  })
                ),
                subtasks: [], // Subtasks can be populated if there are nested subtasks
                dependencies: '', // Dependencies can be set based on requirements
              };

              subTasks.push(formattedSubTask); // Push the formatted subtask into the subTasks array
            });
          }

          const timelineItem: TimelineItem = {
            id: item.id,
            title: item.title,
            startDate: new Date(item.startDate),
            endDate: new Date(item.endDate),
            name: item.title,
            progress: 0,
            appTasks: tasks,
            subtasks: subTasks,
            createdBy: item.createdBy
              ? `${item.createdBy.firstName} ${item.createdBy.lastName}`
              : '',
            assignedUsers: item.assignedUsers?.map(
              (user: { firstName: any; lastName: any; image: any; id: any }) => ({
                name: `${user.firstName} ${user.lastName}`,
                image: user.image,
                id: user.id,
              })
            ),
          };

          return timelineItem;
        });

        const groupedDataMap = new Map<number, TimelineItem>();
        formattedData.forEach((item) => {
          const existingItem = groupedDataMap.get(item.id);
          if (existingItem) {
            existingItem.appTasks.push(...item.appTasks);
            existingItem.subtasks.push(...item.subtasks);
          } else {
            groupedDataMap.set(item.id, item);
          }
        });
        const groupedData = Array.from(groupedDataMap.values());

        this.setState({ data: groupedData }, () => {
          this.renderGanttChart();
        });
      },
      (err) => {
        this.setState({ loading: false });
        console.error('Error fetching timeline:', err);
      }
    );
  }

  formatDate(date: Date): string {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date.toISOString().split('T')[0];
    } else {
      return '';
    }
  }

  renderGanttChart() {
    const { data } = this.state;
    const allTasks: Task[] = [];
    data.forEach((researchItem) => {
      const researchTask: MainTask = {
        id: researchItem.id.toString(),
        name: researchItem.name,
        start: new Date(researchItem.startDate).toISOString(),
        end: new Date(researchItem.endDate).toISOString(),
        progress: researchItem.progress,
        dependencies: '',
        custom_class: 'timeline-tasks Research-Style',
        createdBy: researchItem.createdBy,
        assignedUsers: researchItem.assignedUsers,
      };
      allTasks.push(researchTask);

      researchItem.appTasks.forEach((taskItem) => {
        const task: MainTask = {
          id: taskItem.id,
          name: taskItem.name,
          start: this.formatDate(new Date(taskItem.start)),
          end: this.formatDate(new Date(taskItem.end)),
          progress: taskItem.progress,
          dependencies: researchItem.id.toString(),
          custom_class: 'timeline-tasks main-task-style',
          createdBy: taskItem.createdBy,
          assignedUsers: taskItem.assignedUsers,
        };

        allTasks.push(task);
        if (researchItem.subtasks && Array.isArray(researchItem.subtasks)) {
          researchItem.subtasks.forEach((subtaskItem: any) => {
            const subtask: MainTask = {
              id: subtaskItem.id,
              name: subtaskItem.name,
              start: new Date(subtaskItem.start).toISOString(),
              end: new Date(subtaskItem.end).toISOString(),
              progress: subtaskItem.progress,
              dependencies: taskItem.id,
              custom_class: 'timeline-tasks sub-task-style',
              createdBy: subtaskItem.createdBy,
              assignedUsers: subtaskItem.assignedUsers,
            };

            allTasks.push(subtask);
          });
        }
      });
    });

    const numDaysToShow = 15;
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - Math.floor(numDaysToShow / 2));
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + numDaysToShow);

    const formattedToday = today.toISOString().split('T')[0];
    const filteredTasks = allTasks.filter(
      (task) => task.start <= formattedToday && task.end >= formattedToday
    );
    this.setState({ todayTasks: filteredTasks, tasks: allTasks });

    const gantt = new Gantt(
      '#gantt-containers',
      allTasks.length > 0 ? allTasks : placeholderTasks,
      {
        header_height: 50,
        column_width: 30,
        step: 24,
        view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month', 'Year'],
        bar_height: 31,
        bar_corner_radius: 4,
        arrow_curve: 5,
        padding: 18,
        view_mode: 'Day',
        date_format: 'YYYY-MM-DD',
        language: 'en',
        custom_popup_html: this.customeHtml,
      }
    );
    this.ganttInstance = gantt;
    const element = document.getElementById('gantt-containers');
    if (element) {
      element.style.display = 'block';
    }
    const arrowStyles = document.createElement('style');
    arrowStyles.textContent = `
        .arrow {
            display: none !important;
        }
    `;
    document.head.appendChild(arrowStyles);
    this.setState({ loading: false });
    setTimeout(() => {
      const todayElement = document.querySelector('rect.today-highlight');
      if (todayElement) {
        todayElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }, 100);
  }

  resetZoomLevel() {
    if (this.state.zoomLevel === 6) {
      this.handleZoomIn();
    }

    setTimeout(() => {
      this.setState({ zoomLevel: 3 }, () => {
        this.updateZoom();
      });
    }, 50);
  }

  handleChangeProject(e: any) {
    const element = document.getElementById('gantt-containers');
    if (element) {
      element.style.display = 'none';
    }

    this.resetZoomLevel();

    this.setState(
      {
        loading: true,
        selectedResearch: e,
      },
      () => {
        this.getTimelineData(this.state.selectedResearch.value);
      }
    );

    store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
  }

  handleZoomIn = () => {
    const { zoomLevel } = this.state;
    const newZoomLevel = zoomLevel - 1;

    if (newZoomLevel <= 0) {
      this.setState({ setZoominDisable: true, setZoomOutDisable: false });
      return;
    } else {
      this.setState({ setZoominDisable: false });
      const gridRowElements = document.querySelectorAll('.grid-row');
      gridRowElements.forEach((gridRowElement: Element) => {
        // (gridRowElement as HTMLElement).style.width = '11894';
        (gridRowElement as HTMLElement).style.width = '';
      });
      const gridHeaderElement = document.querySelector('.grid-header') as HTMLElement;
      const ganttElement = document.querySelector('.gantt') as HTMLElement;
      const gridBackgroundElement = document.querySelector('.grid-background') as HTMLElement;

      if (ganttElement && gridBackgroundElement && gridHeaderElement) {
        // gridHeaderElement.style.width = '11894';
        // ganttElement.style.width = '11894';
        // gridBackgroundElement.style.width = '11894';
        gridHeaderElement.style.width = '';
        ganttElement.style.width = '';
        gridBackgroundElement.style.width = '';
      }
    }

    this.setState({ zoomLevel: newZoomLevel }, () => {
      this.updateZoom();
    });
  };

  handleZoomOut = () => {
    const { zoomLevel } = this.state;
    const newZoomLevel = zoomLevel + 1;
    if (newZoomLevel > 6) {
      return;
    }

    this.setState({ zoomLevel: newZoomLevel }, () => {
      this.updateZoom();
    });
  };

  updateZoom() {
    const { ganttInstance, state } = this;
    if (ganttInstance && state) {
      const { zoomLevel } = state;

      if (zoomLevel === 1) {
        this.setState({ setZoominDisable: true });
      } else {
        this.setState({ setZoominDisable: false });
      }

      if (zoomLevel === 6) {
        this.setState({ setZoomOutDisable: true });
      } else {
        this.setState({ setZoomOutDisable: false });
      }

      const zoomLevelToViewMode: { [key: number]: GanttViewMode } = {
        1: GanttViewMode.QuarterDay,
        2: GanttViewMode.HalfDay,
        3: GanttViewMode.Day,
        4: GanttViewMode.Week,
        5: GanttViewMode.Month,
        6: GanttViewMode.Year,
      };

      if (zoomLevel in zoomLevelToViewMode) {
        const viewMode = zoomLevelToViewMode[zoomLevel];
        ganttInstance.change_view_mode(viewMode);
        if (viewMode === GanttViewMode.Year) {
          this.adjustYearRange();
        }
      } else {
        console.error(`Unsupported zoom level: ${zoomLevel}`);
      }
    }
  }

  adjustYearRange() {
    const existingTextElements = document.querySelectorAll('.lower-text, .upper-text');
    existingTextElements.forEach((textElement) => {
      textElement.remove();
    });
    const startYear = 2022;
    const endYear = 2032;
    const yearTextElements = [];
    const xIncrement = 120;

    for (let year = startYear; year <= endYear; year++) {
      const lowerText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      lowerText.setAttribute('x', (60 + (year - startYear) * xIncrement).toString());
      lowerText.setAttribute('y', '50');
      lowerText.setAttribute('class', 'lower-text');
      lowerText.textContent = year.toString();
      yearTextElements.push(lowerText);

      // const upperText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      // upperText.setAttribute('x', '1800');
      // upperText.setAttribute('y', '25');
      // upperText.setAttribute('class', 'upper-text');
      // upperText.textContent = year.toString();
      // yearTextElements.push(upperText);
    }
    const svgContainer = document.querySelector('.date');
    yearTextElements.forEach((textElement) => {
      svgContainer?.appendChild(textElement);
    });

    const gridRowElements = document.querySelectorAll('.grid-row');
    gridRowElements.forEach((gridRowElement: Element) => {
      (gridRowElement as HTMLElement).style.width = '100%';
      (gridRowElement as HTMLElement).style.minWidth = '1300px';
    });

    const gridHeaderElement = document.querySelector('.grid-header') as HTMLElement;
    const ganttElement = document.querySelector('.gantt') as HTMLElement;
    const gridBackgroundElement = document.querySelector('.grid-background') as HTMLElement;

    if (ganttElement && gridBackgroundElement && gridHeaderElement) {
      gridHeaderElement.style.width = '100%';
      ganttElement.style.width = '100%';
      gridBackgroundElement.style.width = '100%';
      gridHeaderElement.style.minWidth = '1300px';
      ganttElement.style.minWidth = '1300px';
      gridBackgroundElement.style.minWidth = '1300px';
    }
  }

  handleTodayTasks = () => {
    if (this.ganttInstance && this.state.todayTasks.length > 0) {
      this.ganttInstance.refresh(this.state.todayTasks);
      this.resetZoomLevel();

      setTimeout(() => {
        const todayElement = document.querySelector('rect.today-highlight');
        if (todayElement) {
          todayElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
      }, 100);
    }
  };

  customeHtml(task: any) {
    const timelineItems = this.convertToTimelineItem([task]);
    const timelineItem = timelineItems[0];
    const dependencies = task.dependencies;
    const end_date = timelineItem.endDate ? timelineItem.endDate.toDateString() : '';
    let assignedUsersText = 'None';
    let createdByImage = '';
    const _cookie = localStorage.getItem('USER_COOKIES');
    if (timelineItem.assignedUsers && timelineItem.assignedUsers.length > 0) {
      assignedUsersText = timelineItem.assignedUsers
        .map((user: { name: string; image: string; id: string }) => {
          if (timelineItem.createdBy === user.name) {
            createdByImage = ` <a href="/${this.state.companyName}/dashboard/Member/profile/${user.id}" class="user-container">
            <img src="${user.image}${_cookie}" class="user-image" /></a>`;
          }
          return `
          <a href="/${this.state.companyName}/dashboard/Member/profile/${user.id}" class="user-container">
          <img src="${user.image}${_cookie}" class="user-image" />
          <span class="user-name">${user.name}</span>
        </a>
          `;
        })
        .join('');
    }
const routePath = dependencies.length > 0 ? $ReplaceRoute(`${AppRoutes.task_profile.replace(':id',timelineItem.id.toString())}`)
: $ReplaceRoute(`${AppRoutes.profile_research.replace(':id',timelineItem.id.toString())}`)

    return `
    <div class="popup-wrapper" style="width:max-content;background-color:#Fff; border: 1px solid #DDDDE3; border-radius:6px">
    <div class="details-container" style="color:#1C2024;padding-left:12px;padding-right:12px;padding-top:12px; padding-bottom:12px">
      <div class="title" style="border-bottom: 2px solid #DDDDE3">
      <a href="${routePath}" class="title-link" style="color:inherit;text-decoration:none">
            ${timelineItem.title}
          </a>
          </div>
      <div class="subtitle" style="color:#1C2024">
      <p>Expected to finish by : ${end_date}</p>
      <p>Created by: ${createdByImage} ${timelineItem.createdBy}</p>
      <p>Assigned users: ${assignedUsersText}</p>
      </div
    </div>
  </div>
`;
  }

  convertToTimelineItem(tasks: any[]): TimelineItem[] {
    const timelineItems: TimelineItem[] = [];
    let task = tasks[0];
    if (task) {
      const assignedUsers: User[] = task.assignedUsers.map(
        (user: { name: string; image: string; id: string }) => ({
          name: user.name,
          image: user.image,
          id: user.id,
        })
      );

      const timelineItem: TimelineItem = {
        id: parseInt(task.id),
        title: task.name,
        startDate: new Date(task.start),
        endDate: new Date(task.end),
        name: task.name,
        progress: 0,
        appTasks: [],
        subtasks: [],
        createdBy: task.createdBy,
        assignedUsers: assignedUsers,
      };

      timelineItems.push(timelineItem);
    } else {
      console.error('Invalid data structure for task:', task);
      // Handle gracefully according to your application logic
    }

    return timelineItems;
  }

  render() {
    return (
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3'>
          <div className='d-flex align-items-center'>
            <BaseButton
              className='btn btn-outline-primary rounded fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none project-back-button'
              style={{ fontWeight: '400', height: '29px', border: '1px solid' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Timeline</h2>
          </div>

          <div className='d-flex flex-wrap gap-3'>
            <div className='d-flex flex-column flex-sm-row align-items-sm-center gap-px-10 '>
              <h2 className='fw-normal fs-15 mb-0 color-gray-600 flex-shrink-0'>
                Filter by project :
              </h2>

              <div className='position-relative w-100 timeline-dropdown'>
                <DashboardProjectDropdown
                  id={1}
                  items={this.state.researhList}
                  onSelect={(e) => {
                    this.handleChangeProject(e);
                  }}
                  selectedItems={this.state.selectedResearch}
                />
              </div>
            </div>

            <div className='d-flex aling-items-center flex-shrink-0'>
              <div className='d-flex justify-content-center'>
                <button
                  onClick={this.handleZoomIn}
                  className='secondary-btn me-px-12'
                  disabled={this.state.setZoominDisable}
                >
                  <BsZoomIn size={16} />
                </button>

                <button
                  onClick={this.handleZoomOut}
                  className='secondary-btn me-px-12'
                  disabled={this.state.setZoomOutDisable}
                >
                  <BsZoomOut size={16} />
                </button>
              </div>

              <button
                onClick={this.handleTodayTasks}
                disabled={this.state.todayTasks.length === 0}
                className='secondary-btn'
              >
                Today
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.loading
              ? 'd-flex align-items-center justify-content-center'
              : 'control-section position-relative'
          }
          style={{ minHeight: '360px' }}
        >
          {this.state.loading && (
            <div className='d-flex align-items-center justify-content-center'>
              <div className='d-flex flex-column align-items-center'>
                <SpinnerLoader />
                <span className='mt-3 textSecondary'>Loading...</span>
              </div>
            </div>
          )}

          {!this.state.loading &&
            (this.state.data.length === 0 || this.state.tasks.length === 0) && (
              <div
                className='position-absolute bottom-0 start-0 w-100 bg-white d-flex align-items-center justify-content-center'
                style={{ height: '300px', zIndex: 40 }}
              >
                <div className='no-data-found'>
                  <img
                    className='no-data-found-img'
                    src='/Images/images/Guy Standing And Finding Data.png'
                    alt='No record found'
                  />
                  No record found
                </div>
              </div>
            )}
          <div id='gantt-containers'></div>
        </div>
      </div>
    );
  }
}

export default withRouter(TimeLine);
