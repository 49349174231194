import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { TextArea, TextField } from '@radix-ui/themes/dist/cjs/components';

export enum InputType {
  text,
  textarea,
}

interface InputsProps {
  width?: string;
  height?: string;
  minWidth?: string;
  id?: string;
  minHeigth?: string;
  backgroundColor?: string;
  className?: string;
  borderRadius?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onEnter?: (e: any) => void;
  placeholder?: string;
  type: InputType;
  label?: ReactNode;
  popQuestion?: string;
  optional?: string;
  rows?: number;
  inValid?: string;
  value?: string;
  isPassword?: boolean;
  fontSize?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onFocus?: () => void;
  readOnly?: boolean;
  onClick?: () => void;
  autoComplete?: string;
  inputType?: string;
  labelStyle?: React.CSSProperties;
  defaultValue?: any;
  name?: any;
  counter?: boolean;
  maxLength?: number;
  rowWidth?: string;
  isExternalURL?: boolean;
  inputPadding?: string;
  marginBottom?: boolean | undefined;
  addIcon?: boolean | undefined;
  onShowModalChange?: (showModal: boolean) => void;
  selectedIcons?: IconEnum | null;
  onIconSelect?: (icon: IconEnum) => void;
  labelIcon?: boolean;
}

export enum IconEnum {
  Book,
  Calculator,
  Robot,
  Bulb,
  Microscope,
  Default,
}

const iconsList = [
  {
    id: IconEnum.Book,
    element: <img src='/images/icons/book.svg' alt='icon-1' width={15} height={15} />,
  },
  {
    id: IconEnum.Calculator,
    element: <img src='/images/icons/calculator.svg' alt='icon-2' width={15} height={15} />,
  },
  {
    id: IconEnum.Robot,
    element: <img src='/images/icons/robot.svg' alt='icon-3' width={15} height={15} />,
  },
  {
    id: IconEnum.Bulb,
    element: <img src='/images/icons/bulb.svg' alt='icon-4' width={15} height={15} />,
  },
  {
    id: IconEnum.Microscope,
    element: <img src='/images/icons/microscope.svg' alt='icon-5' width={15} height={15} />,
  },
  { id: IconEnum.Default, element: <i className='fa fa-grip-vertical color-gray-600 fs-13'></i> },
];

export const InputComponent: React.FC<InputsProps> = ({
  width,
  height,
  minWidth,
  id,
  minHeigth,
  backgroundColor,
  className,
  borderRadius,
  onChange,
  autoFocus,
  onBlur,
  onFocus,
  onEnter,
  placeholder,
  type,
  name,
  label,
  popQuestion,
  optional,
  rows = 4,
  inValid,
  value,
  isPassword,
  fontSize,
  disabled,
  readOnly,
  defaultValue,
  autoComplete,
  labelStyle = {},
  onClick,
  inputType = 'text',
  counter = true,
  maxLength,
  rowWidth,
  isExternalURL,
  inputPadding,
  marginBottom,
  addIcon,
  onShowModalChange,
  selectedIcons,
  onIconSelect,
  labelIcon,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<IconEnum | null>(
    selectedIcons ?? IconEnum.Default
  );
  const [isFocus, setIsFocus] = useState(false);
  const [currentLength, setCurrentLength] = useState<number>(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        onShowModalChange && onShowModalChange(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, onShowModalChange]);
  let maxLengthText = maxLength ? maxLength : 1500;
  let maxLengthTextarea = maxLength ? maxLength : 1500000;

  let styles = {
    width: width,
    height: height ? height : '35px',
    minWidth: minWidth,
    minHeigth: minHeigth,
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    fontSize: fontSize,
  };

  let templateLabel = label ? (
    <span className='color-gray-600 fs-15 me-2'>
      {label}{' '}
      {labelIcon && <img src='/images/icons/label-icon.svg' width={15} height={15} alt='icon' />}
    </span>
  ) : null;

  let templateValidation = inValid ? <div className='uppercaseFirstLetter'>{inValid}</div> : null;

  const keyPressHandler = (e: any) => {
    if (e.key === 'Enter' && onEnter) {
      onEnter(e);
    }
  };

  const handleIconSelect = (iconId: IconEnum) => {
    // Change parameter type to IconEnum
    setSelectedIcon(iconId);
    setShowDropdown(false);
    //onChange && onChange({ target: { value: iconId } });
    onIconSelect && onIconSelect(iconId);
  };

  const selectedIconElement = iconsList.find(
    (icon) => icon.id === selectedIcon || icon.id === selectedIcons
  )?.element;

  return type === InputType.text ? (
    <div className={`${isExternalURL ? '' : 'row mt-1'}`} style={{ width: `${rowWidth}` }}>
      <div className={`col-lg-12 ${isExternalURL || marginBottom ? '' : 'mb-3'}`}>
        <div className='text-primary cursor-pointer mb-1' title='Info'>
          <Fragment>
            <span className='label d-flex align-items-center mb-1'>{templateLabel}</span>
            {!isPassword ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                {addIcon && (
                  <div style={{ position: 'relative' }} ref={dropdownRef}>
                    <button
                      className='btn btn-icon-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                      disabled={disabled}
                      onClick={() => {
                        setShowDropdown((prev) => !prev);
                        onShowModalChange && onShowModalChange(true);
                      }}
                    >
                      {selectedIconElement ? selectedIconElement : <i className='fa fa-plus'></i>}
                    </button>
                    {showDropdown && (
                      <div
                        className='dropdown-menu show'
                        style={{ minWidth: 'auto', width: 'auto' }}
                      >
                        {iconsList.map((icon) => (
                          <button
                            key={icon.id}
                            className='dropdown-item'
                            style={{ padding: '0.5rem 0.75rem', width: 'auto' }}
                            onClick={() => handleIconSelect(icon.id)}
                          >
                            {icon.element}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <TextField.Root
                  size={'3'}
                  aria-readonly={readOnly}
                  id={id}
                  typeof={inputType}
                  autoFocus={autoFocus}
                  disabled={disabled}
                  aria-disabled={disabled}
                  style={{ ...styles, flexGrow: 1 }}
                  value={value}
                  aria-checked={counter}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  onClick={onClick}
                  defaultValue={defaultValue}
                  aria-label={name}
                  maxLength={maxLength}
                  onBlurCapture={() => setIsFocus(false)}
                  onFocus={() => setIsFocus(true)}
                  className={`form-control control-border-color ${inputPadding}`}
                  onKeyUp={keyPressHandler}
                  aria-valuemax={maxLengthText}
                  aria-autocomplete='none'
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/[<>]/g, '');
                    setCurrentLength(sanitizedValue.length);
                    onChange && onChange({ target: { ...e.target, value: sanitizedValue } });
                  }}
                />
              </div>
            ) : (
              <div>
                <TextArea
                  size={'3'}
                  aria-autocomplete='none'
                  aria-readonly={readOnly}
                  typeof={isPassword ? 'text' : 'password'}
                  style={styles}
                  aria-valuetext={value}
                  aria-valuenow={defaultValue}
                  onChange={(e: any) => {
                    const sanitizedValue = e.target.value.replace(/[<>]/g, '');
                    setCurrentLength(sanitizedValue.length);
                    onChange && onChange({ target: { ...e.target, value: sanitizedValue } });
                  }}
                  onFocus={onFocus}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  aria-disabled={disabled}
                  className='form-control control-border-color'
                />
              </div>
            )}
            {templateValidation}
          </Fragment>
        </div>
      </div>
    </div>
  ) : (
    <div className={`row ${isExternalURL ? '' : 'mt-1'}`}>
      <div className={`col-lg-12 ${isExternalURL ? '' : 'mb-3'}`}>
        <div className={`text-primary cursor-pointer ${isExternalURL ? '' : 'mb-1'}`} title='Info'>
          <Fragment>
            {templateLabel}
            <div style={{ position: 'relative' }}>
              <TextArea
                size={'3'}
                aria-autocomplete='none'
                aria-readonly={readOnly}
                typeof={isPassword ? 'text' : 'password'}
                style={styles}
                aria-valuetext={value}
                aria-valuenow={defaultValue}
                maxLength={maxLength}
                onChange={(e: any) => {
                  const sanitizedValue = e.target.value.replace(/[<>]/g, '');
                  setCurrentLength(sanitizedValue.length);
                  onChange && onChange({ target: { ...e.target, value: sanitizedValue } });
                }}
                onFocus={onFocus}
                placeholder={placeholder}
                value={value}
                onBlur={onBlur}
                aria-disabled={disabled}
                className='form-control control-border-color'
              />
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default InputComponent;
