import { BaseButton } from '@fluentui/react';
import * as Dialog from '@radix-ui/react-dialog';
import DOMPurify from 'dompurify';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropzone from 'react-dropzone';
import { useHistory, useParams } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import { CategoryController } from '../../../controllers/category/category_controller';
import { expensesController } from '../../../controllers/financials/expenses_controller';
import { ResearchController } from '../../../controllers/research/research_controller';
import { AppRoutes } from '../../../core/constants';
import { SortType } from '../../../core/number_extentions';
import { $ReplaceRoute, uploadAllFiles, uploadAllLinks } from '../../../core/utils';
import { CreateNewCategoryResult } from '../../../data/models/responses/category/create_new_category';
import { MainButton } from '../../components/button';
import { InputComponent, InputType } from '../../components/inputs';
import { SelectComponent } from '../../components/select_input';
import BackButtonTitle from './component/back_button_title';
import FormattedFiles from './component/formattedFiles';
import SubmitButton from './component/submit_button';
import { Media } from '../../../data/models/responses/expense/create_expense_res';
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";

import File from "../../components/File";
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import { store } from '../../../data/storeMain';

type Params = { company: string; id: string };

export default function ExpensesEdit() {
  const validator = useRef(
    new SimpleReactValidator({
      className: 'text-danger',
    })
  );

  const { id }: Params = useParams();
  const history = useHistory();
  const controller = new expensesController();
  const researchController = new ResearchController();
  const categoryController = new CategoryController();

  const [, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [externalUrl, setExternalUrl] = useState<string[]>([]);
  const [removedMedia, setRemovedMedia] = useState<any>(null);
  const [external, setExternal] = useState('');
  const [description, setDescripption] = useState('');
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState('');
  const [files, setFiles] = useState<File>();

  const [listCategory, setListCategory] = useState<any[]>([]);

  const [categoryName, setCategoryName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<{ label: string; value: number } | null>(
    null
  );
  const [medias, setMedias] = useState<any[]>([]);
  const [removedMediasId, setRemovedMediasId] = useState<number[]>([]);

  const [researchList, setResearchList] = useState<any[]>([]);

  const [selectedResearch, setSelectedResearch] = useState<{ label: string; value: number } | null>(
    null
  );

  useLayoutEffect(() => {
    document.title = `Edit Expense | Radvix`;
  }, []);

  function handelDeleteFile(arg: File) {
    //setFiles((prvState) => prvState.filter((file) => file.name !== arg.name));
  }

  const onDrop = (fileList: any) => {
    var file = fileList[0];
    setFiles(file);
  };

  function addExternalUrl() {
    if (validator.current.fields.input && !files) {      
      setExternalUrl([external]);
      setExternal('');
    } else {
      validator.current.showMessages();
    }
  }

  const handleBlur = () => {
    setAmount(formatAmount(amount));
  };

  function formatAmount(value: string){
    const numericValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
    const number = parseFloat(numericValue);

    if (!isNaN(number)) {
      return number.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return '';
  };
  
  function handelDeleteExternalLink(link: string) {
    (medias as Media[]).forEach((media) => {
      if (media.inputDataType === 4 && media.externalUrl === link) {       
          handelRemoveMedia(media && media.id ? media.id : 0);
      }
    });
    setExternalUrl((prevState) => prevState.filter((item) => item !== link));
  }

  function handleChangeLink(val: any) {
    const sanitizedValue = DOMPurify.sanitize(val);
    setExternal(sanitizedValue);
  }

  function loadResearches() {
    researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        let researches: { value: any; label: any }[] = [];
        if (res && res.researchesList && res.researchesList.length > 0) {
          researches = res.researchesList?.map(({ id, title }) => ({
            value: id,
            label: title,
          }));
        }
        setResearchList(researches);
      },
      (err) => {}
    );
  }

  function getCategory() {
    setIsLoading(true);
    categoryController.getAllCategories({ type: 3 }, (res) => {
      const categoryList = res.map((category) => ({
        label: category.title,
        value: category.id,
      }));
      setListCategory(categoryList);
      setIsLoading(false);
    });
  }

  function getExpeseById() {
    setIsLoading(true);
    controller.getExpenseById(
      { id: parseInt(id) },
      (res) => {
        if (res === null) {
          setIsLoading(false);
          return;
        }
        console.log(res, '================== getExpenseById');
        const fetchedFiles: File[] = [];
        const fetchedUrls: string[] = [];
        if(res.medias && res.medias.length > 0)
          setMedias(res.medias);
      
      //setFiles(fetchedFiles);
      setExternalUrl(fetchedUrls);
        setName(res.title || '');
        setAmount(formatAmount(res.amount?.toString() ?? '0'));
        setDescripption(res.description || '');
        // @ts-ignore
        setSelectedResearch({ label: res.researchTitle, value: res.researchId });
        // @ts-ignore
        setDate(new Date(res.date) || new Date());
        // @ts-ignore
        setSelectedCategory({ label: res.categoryTitle, value: res.categoryId });
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  }

  useEffect(() => {
    document.title = "Edit Expense | Radvix";
    getExpeseById();
    loadResearches();
    getCategory();
  }, []);

  function handleCreateNewCategory(categoryName: string) {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      categoryController.createNewCategory(
        {
          title: categoryName,
          type: 3,
        },
        (res) => {
          getCategory();
          setShowModal(false);
          resolve(res as CreateNewCategoryResult);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  function handelRemoveMedia(id: number) {
    if(id > 0){
      setRemovedMediasId((prevState) => [...prevState, id]);
      setMedias((prevState) => prevState.filter((item) => item.id !== id));
    }    
  }
  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';
  
    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;
  
    return `${sizeInMB.toFixed(dm)} MB`;
  }
  async function handelUpload(id: number) {
    try {
      if(externalUrl && externalUrl.length > 0){
        await uploadAllLinks(id, externalUrl, 4);
      }      
      else if(files){
        let uploadfiles = [];
        uploadfiles.push(files)
        await uploadAllFiles(id, [files], 4);
      }
      
      setIsLoading(false);
      history.push($ReplaceRoute(AppRoutes.expenses));
    } catch (e) {
      setIsLoading(false);
    }
  }

  function editExpense() {
    const body = {
      id: parseInt(id),
      title: name,
      researchId: selectedResearch?.value,
      description,
      amount: parseInt(amount),
      date: date,
      categoryId: selectedCategory?.value,
      removedMediaId: removedMediasId,
    };
    setIsLoading(true);
    controller.updateExpense(
      body,
      async (res) => {
        if(selectedResearch){
          store.dispatch(
            SetResearchId({
              label: selectedResearch.label,
              value: selectedResearch.value,
            })
          );
        }        
        if (files || externalUrl.length) {
          await handelUpload(parseInt(id));
        } else {
          setIsLoading(false);
          history.push($ReplaceRoute(AppRoutes.expenses));
        }
      },
      (err) => {
        setIsLoading(false);
      }
    );
  }

  function handelEditExpense() {
    setIsFormValid((prvState) => !prvState);
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      return;
    }

    editExpense();
  }

  return (
    <div className='card edit-expense-card'>
      <div
        className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400'
      >
        <BackButtonTitle title='Edit Expense' />
      </div>
      <div className='p-3 overflow-auto'>
        <div className='row'>
          <div className=''>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='mb-0'>
                  <InputComponent
                    className='form-control'
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label='Expense Name*'
                    placeholder='Expense name'
                    popQuestion='This will be the name of your expense. You can always edit this later.'
                    value={name}
                    maxLength={150}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    inValid={validator.current.message('Name', name, 'required')}
                  ></InputComponent>
                </div>
                <div className='item'>
                  <SelectComponent
                    disabled={isLoading}
                    items={researchList}
                    defaultValue={selectedResearch}
                    TextItem='title'
                    ValueItem='id'
                    labelClass='color-gray-600 fs-15 me-2 mb-1'
                    className='form-select control-border-color'
                    label='Select Project*'
                    placeholder='Select project'
                    onChange={(e) => {
                      setSelectedResearch(e);
                    }}
                    isMulti={false}
                  ></SelectComponent>
                  {validator.current.message('Project', selectedResearch, 'required')}
                </div>

                <div className='item mt-3'>
                  <div className='d-flex align-items-center mb-1'>
                    <label className='color-gray-600 fs-15 me-2'>Date*</label>
                  </div>
                  <div className='date-picker-wrapper'>
                    <DatePicker
                      disabled={isLoading}
                      selected={date}
                      placeholderText='Select Date'
                      className='control-border-color'
                      onChange={(e) => {
                        setDate(e!);
                        // this.handelChangeDate(
                        //   e as Date,
                        //   this.state.isLockEndTime &&
                        //     (e as Date)?.getTime() > this.state.endDate?.getTime()
                        //     ? new Date((e as Date)?.getTime() + ONE_DAY)
                        //     : undefined
                        // );
                      }}
                      // maxDate={
                      //   this.state.isLockEndTime
                      //     ? undefined
                      //     : new Date(this.state.endDate?.getTime() - ONE_DAY)
                      // }
                    />
                    {validator.current.message('Date', date, 'required')}
                  </div>
                </div>

                <div className='item mt-3'>
                  <InputComponent
                    className='form-control'
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label='Amount*'
                    placeholder='$10.00'
                    popQuestion='This will be the name of your expense. You can always edit this later.'
                    value={amount.toString()}
                    onBlur={handleBlur} 
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    inValid={validator.current.message('Grant Amount', amount, 'required|numeric')}
                  ></InputComponent>
                </div>

                <div className='item relative mt-3'>
                  <div className='position-absolute end-0 bottom-px-44'>
                    <button
                      className='btn btn-soft-primary btn-sm fs-13 px-3 py-1'
                      onClick={() => setShowModal(true)}
                    >
                      <i className='fa fa-plus'></i>
                      <span> Add Category</span>
                    </button>
                  </div>

                  <SelectComponent
                    disabled={isLoading}
                    items={listCategory}
                    TextItem='name'
                    ValueItem='id'
                    className='w-100'
                    placeholder='Select a category'
                    label='Select category'
                    isMulti={false}
                    onChange={(e) => setSelectedCategory(e)}
                    defaultValue={selectedCategory}
                  ></SelectComponent>
                </div>

                <div className='item mt-3'>
                  <div className='d-flex align-items-center mb-1'>
                    <label className='color-gray-600 fs-15 me-2'>Attachments</label>
                  </div>
                  <div className='p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft'>
                    <div className='justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8'>
                      {/* <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          // <section
                          //   className="container fileUploadBox"
                          //   style={{ marginTop: "0.3em" }}
                          // >
                          <div {...getRootProps({ className: '' })}>
                            <input {...getInputProps()} />

                            <div className='d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content'>
                              <button className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0' disabled={files.length > 0 || externalUrl.length > 0}>
                                <div className='d-flex align-items-center'>
                                  <div className='me-1 mb-1'>
                                    <svg
                                      width='15'
                                      height='15'
                                      viewBox='0 0 15 15'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        width='15'
                                        height='15'
                                        fill='white'
                                        fill-opacity='0.01'
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
            4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
            7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
            10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
            10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
            10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
            14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </div>
                                  Browse local file
                                </div>
                              </button>
                              <h3 className='fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center'>
                                {' '}
                                Or drag and drop files here - Max 128MB{' '}
                              </h3>
                            </div>
                            {files.length > 0 && (
                              <div style={{ paddingTop: '10px' }}>
                                <FormattedFiles
                                  files={files}
                                  deleteFile={handelDeleteFile}
                                  removeMedia={handelRemoveMedia}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </Dropzone> */}
                      <Dropzone onDrop={onDrop} disabled={files ? true : false}>
                      {({ getRootProps, getInputProps }) => (
                        // <section
                        //   className="container fileUploadBox"
                        //   style={{ marginTop: "0.3em" }}
                        // >
                        <div {...getRootProps({ className: "" })}>
                          <input {...getInputProps()} />

                          <div className="d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content">
                            <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                              <div className="d-flex align-items-center">
                                <div className="me-1 mb-1">
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="15"
                                      height="15"
                                      fill="white"
                                      fill-opacity="0.01"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </div>
                                Browse local file
                              </div>
                            </button>
                            <h3 className="fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center">
                              {" "}
                              Or drag and drop files here - Max 128MB{" "}
                            </h3>
                          </div>
                          {medias.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul
                                className="list-unstyled fw-normal"
                                
                              >
                                {medias
                                  .filter((item) => item.externalUrl === null)
                                  .map((item, index) => (
                                    <li
                                      key={index}
                                      className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team align-items-center"
        style={{marginBottom:'3px'}}
                                    >
                                      <span
                                        className="text-truncate col-11 mx-2 font-family-San"
                                        style={{ textAlign:'start' }}
                                      >
                                        <File file={item} />
                                      </span>
                                      <CircleIcon
                                        type={ThemeCircleIcon.dark}
                                        width="22px"
                                        height="22px"
                                        padding="0"
                                        //disabled={_disabledAll}
                                        onClick={() => {
                                          handelRemoveMedia(item.id!);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          display: "contents",
                                        }}
                                      >
                                        <img
                                          src="/images/icons/cross-1.svg"
                                          alt="radvix"
                                          width={15}
                                          height={15}
                                        />
                                      </CircleIcon>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                          {files && (
                            <div style={{ paddingTop: "10px" }}>
                              <ul className="list-unstyled fw-normal">
                              
      <li
        
        className="d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team"
        style={{ marginBottom: "3px" }}
      >
        <span
          className="text-truncate col-10 mx-2"
          style={{ fontFamily: "Sanfransisco", textAlign: "start" }}
        >
          {files.name} - {formatBytes(files.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width="22px"
          height="22px"
          onClick={() => {
            setFiles(undefined)
          }}
          style={{ cursor: "pointer", display: "contents" }}
          padding="0"
        >
          <img
            src="/images/icons/cross-1.svg"
            alt="radvix"
            width={15}
            height={15}
          />
        </CircleIcon>
      </li>
                              </ul>
                            </div>
                          )}
                          {/* <div style={{ paddingTop: "10px" }}>
                            <span
                              className="badge bg-danger text-white py-px-6 rounded-pill fw-normal px-3"
                              style={{ display: "flex", height: "22px" }}
                            >
                              {files}
                            </span>
                          </div> */}
                        </div>
                      )}
                    </Dropzone>
                    </div>
                  </div>
                </div>

                <div className='item'>
                  <ul
                    className='file-list mt-1'
                    style={{
                      marginBottom: externalUrl.length > 0 ? '0px' : '0px',
                    }}
                  >
                    {externalUrl.map((item) => (
                      <li className='d-flex align-items-center w-100'>
                        <InputComponent
                          type={InputType.text}
                          value={item}
                          className='form-control'
                          counter={false}
                          disabled={true}
                          readOnly={true}
                          rowWidth='98%'
                          isExternalURL={true}
                        ></InputComponent>
                        <div
                          style={{
                            paddingBottom: '0px',
                            paddingRight: '0px',
                            paddingTop: '0px',
                            paddingLeft: '5px',
                          }}
                        >
                          <button
                            className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                            disabled={isLoading }
                            style={{ background: '#F3AEAF' }}
                            onClick={() => handelDeleteExternalLink(item)}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='15'
                              height='15'
                              viewBox='0 0 15 15'
                              fill='none'
                            >
                              <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z'
                                fill='#D93D42'
                              />
                            </svg>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div>
                    <div className='d-flex'>
                      <InputComponent
                        type={InputType.text}
                        placeholder='https://'
                        disabled={isLoading || externalUrl.length > 0 || files != null}
                        className='control-border-color'
                        value={external}
                        counter={false}
                        onChange={(e) => handleChangeLink(e.target.value)}
                        rowWidth='98%'
                        onEnter={addExternalUrl}
                      ></InputComponent>
                      <div className='ps-px-5 pt-px-8'>
                        <button
                          className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                          disabled={isLoading || externalUrl.length > 0 || files != null}
                          onClick={addExternalUrl}
                        >
                          <i className='fa fa-plus'></i>
                        </button>
                      </div>
                    </div>
                    <p className='text-error mb-0'>
                      {validator.current.message('input', external, 'url')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <SubmitButton
              onClick={handelEditExpense}
              loading={isLoading}
              disabled={isLoading}
              isEdit={true}
            >
              Save Expense
            </SubmitButton>
          </div>
        </div>
      </div>
      <Dialog.Root open={showModal}>
        <Dialog.Portal>
          <Dialog.Overlay className='DialogOverlay' />
          <Dialog.Content className='DialogContent'>
            <div
              className='d-flex justify-content-between align-items-center  row-gap-3 flex-wrap column-gap-3 border-bottom
      border-gray-400'
              style={{ paddingBottom: '10px' }}
            >
              <div className='d-flex align-items-center'>
                <BaseButton
                  className='btn btn-outline-primary fs-15 py-px-4 me-px-12 px-3 d-sm-block d-none back-button'
                  style={{ fontWeight: '400', height: '19px' }}
                  onClick={() => setShowModal(false)}
                >
                  Close
                </BaseButton>
                <h2 className='fw-medium fs-15 mb-0 color-gray-1000 modalHeader'>
                  Expense Category
                </h2>
              </div>
            </div>
            <div className='modal-body' style={{ paddingTop: '15px' }}>
              <div className='d-flex align-items-center gap-px-12'>
                <div className='flex-grow-1'>
                  <InputComponent
                    type={InputType.text}
                    label='Add Category Name'
                    popQuestion='Add Category Name'
                    onChange={(e) => setCategoryName(e.target.value)}
                    placeholder='Type new category name'
                    className='border'
                    inputPadding='px-px-12'
                  ></InputComponent>
                </div>
                <MainButton
                  className='btn btn-primary resrvationButton mt-2'
                  loading={isLoading}
                  onClick={() => handleCreateNewCategory(categoryName)}
                >
                  Add
                </MainButton>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}
