import { useEffect, useState } from "react";
import { store } from "../../../data/storeMain";
// import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import "react-datepicker/dist/react-datepicker.css";
import { MainButton, MainButtonType } from "../../components/button";
// import ReactPaginate from "react-paginate";
import NotificationTable from "./component/new_notification_tbl";
// import { SelectComponent } from "../../components/select_input";
import { UserTypesTable } from "./component/user_types_tbl";
import { BaseButton } from "@fluentui/react";
import {
  $ReplaceRoute,
  AccessPermition,
  UserRoles,
  handleClearStorageAndTokens,
  handleLogoutApi,
} from "../../../core/utils";
// import ReactDatePicker from "react-datepicker";
import PaymentHistory from "./component/payment_History";
import { HTTP, handleUnauthorizedRequest } from "../../../core/http_common";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { RegisterController } from "../../../controllers/register/research_controller";
// import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import { AppRoutes } from "../../../core/constants";
import NewTable from "../../components/NewTable/NewTable";
import ConfirmationContent from "../../components/ConfirmationContent";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import SessionHistory from "./sessionHistory";
import { SetHeaderTitle } from "../../../data/storeMain/actions/header_title_action";

const SettingPage = () => {
  const [userRole, setUserRole] = useState(undefined);
  const [ isDataRemovalRequest, setIsDataRemovalRequest] = useState(false);
  const [listTimeZone, setListTimeZone] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [openAddRoleModal , setOpenAddRoleModal] = useState(false);
  const subController = new RegisterController();
  const history = useHistory();
  useEffect(() => {
    document.title = `Settings | Radvix`;
    store.dispatch(SetHeaderTitle('Settings'))
    if (store) {
      setUserRole(store.getState().userRole);
      const requestStatus = localStorage.getItem("isDeleteRequestSent");   
      console.log("requestStatus", requestStatus) 
      if(requestStatus == "null"){
        setIsDataRemovalRequest(false); 
      }
      else{
        setIsDataRemovalRequest(requestStatus); 
      }
      
      store.subscribe(() => {
        setUserRole(store.getState().userRole);
      });
    }
    HTTP.get("/AppSetting/TimeZoneList")
      .then((res) => {
        if (res.data.result) {
          const _data = res.data.result?.timeZones.map((_tm) => {
            return { label: _tm.title, value: _tm.id };
          });
          // console.log(_data);

          setListTimeZone(_data);
        }
      })
      .catch((err) => {
        // error(err);
      });
      // if (selectedTab === 0) {
      //   // Call the NotificationTable function here
      //   <NotificationTable listTimeZone={listTimeZone} role={userRole} selectedTab={selectedTab}></NotificationTable>
      // }
  }, []);

  function handleCancelSubscription() {
    const _userId = store.getState().userInfo.id;
    if (!_userId) return;
    subController.cancelSubscription(
      {
        userId: _userId,
      },
      (_res) => {
        console.log(_res);
        logout(); //remove the code
      },
      (_err) => { }
    );
  }
  const logout = () => {
    // clear all local storage except setting
    handleLogoutApi()
      .then((res) => {
        handleClearStorageAndTokens();
        history.push(AppRoutes.login);
      })
      .catch((err) => {
        if (err === "Device Id not found") {
          handleClearStorageAndTokens();
          window.location.replace(AppRoutes.login);
          return;
        }
        const _tokenInStorage = localStorage.getItem("token");
        if (!_tokenInStorage) {
          handleClearStorageAndTokens();
          window.location.replace(AppRoutes.login);
        } else {
          if (err.response && err.response.status === 401) {
            // Retry the request with refreshed token
            return handleUnauthorizedRequest(err.config).then((_res) => {
              handleClearStorageAndTokens();
              history.push(AppRoutes.login);
            });
            // .catch((e) => error(e));
          } else {
            handleClearStorageAndTokens();
            history.push(AppRoutes.login);
            // error(err);
          }
        }
      });
  };
  const handleSendNotificationManually = () => {
    HTTP.get("/Notification/SendEmailNotifications")
      .then((res) => {
        
        toast.success("Notification sent successfully");
      })
      .catch((err) => {
        
        toast.error("Somthing went wrong");
      });
  };

  function handleFeedSeed() {
    HTTP.post("/User/ds")
      .then((res) => {
        toast.success("Feed seed successfully");
      })
      .catch((err) => {
        toast.error("Somthing went wrong");
      });
  }
  const headerClick = (columnHeaderName, sortDirection) => {
  };
  return (
    <div className="card">
      {/* {AccessPermition(userRole, [UserRoles.L1Client, UserRoles.L1User]) ? ( */}        
      <div className="d-flex flex-column flex-sm-row  justify-content-between px-3 py-3 d-flex align-items-center">
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <div className="d-flex justify-content-between">
            {userRole !== UserRoles.L1Client ? (
            <BaseButton
              className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none project-back-button"
              style={{ fontWeight: "400", height: "19px" }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>):null}
            <h2 className="fw-medium fs-15 mb-0 color-gray-1000 py-1">
              Settings
            </h2>
            </div>
            {selectedTab == 0 && (
              <div>
              <MainButton                 
                  className="btn btn-danger text-white hover-fill  btn-sm fs-13 px-2 h-px-29 py-0"
                  disabled= {isDataRemovalRequest}
                  svg={<svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='21'
                    viewBox='0 0 20 21'
                    fill='none'
                  >
                    <rect
                      width='20'
                      height='20'
                      transform='translate(0 0.5)'
                      fill='white'
                      fill-opacity='0.01'
                    />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M7.33382 1.83331C6.96564 1.83331 6.66716 2.13179 6.66716 2.49998C6.66716 2.86817 6.96564 3.16665 7.33382 3.16665H12.6672C13.0353 3.16665 13.3338 2.86817 13.3338 2.49998C13.3338 2.13179 13.0353 1.83331 12.6672 1.83331H7.33382ZM4.00049 5.16665C4.00049 4.79846 4.29897 4.49998 4.66715 4.49998H6.66716H13.3338H15.3338C15.702 4.49998 16.0005 4.79846 16.0005 5.16665C16.0005 5.53483 15.702 5.83331 15.3338 5.83331H14.6672V16.5C14.6672 17.2364 14.0702 17.8333 13.3338 17.8333H6.66716C5.93078 17.8333 5.33382 17.2364 5.33382 16.5V5.83331H4.66715C4.29897 5.83331 4.00049 5.53483 4.00049 5.16665ZM6.66716 5.83331H13.3338V16.5H6.66716V5.83331Z'
                      fill='white'
                    />
                  </svg>}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push($ReplaceRoute(AppRoutes.delete_user_data));
                  } }
                >
                  
                  Request Data Removal & Account Deletion
                </MainButton>
              </div>
            )}
            {selectedTab == 1 && (
            <div>
                <MainButton
                    data-bs-toggle="modal"
                    data-bs-target="#modal-add-type"
                    className="btn hover-fill btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0"
                    svg={<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="15" height="15" fill="white" fill-opacity="0.01"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2.75012C8 2.47398 7.77614 2.25012 7.5 2.25012C7.22386 2.25012 7 2.47398 7 2.75012V7.00012H2.75C2.47386 7.00012 2.25 7.22398 2.25 7.50012C2.25 7.77626 2.47386 8.00012 2.75 8.00012H7V12.2501C7 12.5262 7.22386 12.7501 7.5 12.7501C7.77614 12.7501 8 12.5262 8 12.2501V8.00012H12.25C12.5261 8.00012 12.75 7.77626 12.75 7.50012C12.75 7.22398 12.5261 7.00012 12.25 7.00012H8V2.75012Z" fill="#5746AF"/>
                      </svg>}
                    onClick={() => {
                      setOpenAddRoleModal(true);
                    } }
                  >
                    
                    Add new role
                  </MainButton>
                </div>
                )}
          </div>
        <div className="TableBox mt-0"style={{background:"#fff", padding:"0"}}>
          <div className="responsive">
          <div className="row flex-nowrap scrollmenu">
            <div
              className="dashboardProjectDetail dashboard-section overviwe align-items-center"
              style={{ borderBottom: "none" }}
            >
              <div
                className={
                  selectedTab === 0
                    ? "overviwe-item"
                    : "overviwe-item button-dashboard-category"
                }
                onClick={() => setSelectedTab(0)}
              >
                <div className="dashboardProjectDetailItem">
                  <div
                    className="d-flex flex-column align-items-center dashboardProjectDetailItem"
                    id="pills-notes-tab"
                  >
                    <div
                      className={
                        selectedTab === 0
                          ? "dashboardSelectedCategory"
                          : "dashboardCategory"
                      }
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" color={selectedTab === 0 ? "white" : "black"} className={"dashboardCategoryIconAll"}  width="20" height="20" viewBox="0 0 20 20" fill="none">
  <rect width="20" height="20" fill="white" fillOpacity="0.01"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M11.4687 1.66775C11.4687 2.33939 11.0174 2.90563 10.4016 3.07979C13.5283 3.28621 16.0004 5.8877 16.0004 9.06659V13.6666C16.0004 14.7417 16.0443 15.6098 16.9652 16.0703C17.2417 16.2086 17.3869 16.5189 17.3159 16.8198C17.2448 17.1207 16.9763 17.3333 16.6671 17.3333H10.8823C11.1591 17.5775 11.3337 17.935 11.3337 18.3333C11.3337 19.0697 10.7368 19.6666 10.0004 19.6666C9.264 19.6666 8.66704 19.0697 8.66704 18.3333C8.66704 17.935 8.84162 17.5775 9.11842 17.3333H3.33373C3.02454 17.3333 2.75593 17.1207 2.68489 16.8198C2.61386 16.5189 2.75903 16.2086 3.03558 16.0703C3.95651 15.6098 4.00039 14.7417 4.00039 13.6666V9.06659C4.00039 5.8871 6.47349 3.28522 9.60097 3.07967C8.9853 2.90538 8.53422 2.33925 8.53422 1.66775C8.53422 0.857411 9.19113 0.2005 10.0015 0.2005C10.8118 0.2005 11.4687 0.857411 11.4687 1.66775ZM10.0004 4.39993C7.42306 4.39993 5.33373 6.48926 5.33373 9.06659V13.6666L5.33377 13.7345C5.33441 14.3283 5.33535 15.2111 4.93279 15.9999H15.068C14.6655 15.2111 14.6664 14.3283 14.6671 13.7345V13.6666V9.06659C14.6671 6.48926 12.5777 4.39993 10.0004 4.39993Z" fill="currentColor"/>
</svg>
                      
                      {/* <ViewGridIcon
                      color={
                        selectedTab === 0
                          ? "white"
                          : "black"
                      }
                      className={"dashboardCategoryIconAll"}
                    ></ViewGridIcon> */}
                      {/* <img
                      src={
                        this.state.notificationCategory === 0
                          ? "/images/icons/world.svg"
                          : "/images/icons/worldBlack.svg"
                      }
                      alt="Research"
                      className={"dashboardCategoryIconAll"}
                    /> */}
                      <span className="textWhite">Notifications</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  selectedTab === 1
                    ? "overviwe-item"
                    : "overviwe-item button-dashboard-category"
                }
                onClick={() => setSelectedTab(1)}
              >
                <div className=" dashboardProjectDetailItem">
                  <div
                    className="d-flex flex-column align-items-center dashboardProjectDetailItem"
                    id="pills-description-tab"
                  >
                    <div
                      className={
                        selectedTab === 1
                          ? "dashboardSelectedCategory"
                          : "dashboardCategory"
                      }
                    >
                      <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      color={selectedTab === 1 ? "white" : "black"}
                      className={"dashboardCategoryIconAll"}
                    >
                      <rect
                        width="15"
                        height="15"
                        fill="white"
                        fillOpacity="0.01"
                      ></rect>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.877014 7.49979C0.877014 3.8421 3.84216 0.876953 7.49985 0.876953C11.1575 0.876953 14.1227 3.8421 14.1227
                    7.49979C14.1227 11.1574 11.1575 14.1226 7.49985 14.1226C3.84216 14.1226 0.877014 11.1574 0.877014 7.49979ZM7.49985
                    1.82695C4.36683 1.82695 1.82701 4.36677 1.82701 7.49979C1.82701 8.97187 2.38774 10.313 3.30727 11.3212C4.19074 9.9411
                    5.73818 9.0249 7.50023 9.0249C9.26206 9.0249 10.8093 9.94088 11.6929 11.3207C12.6121 10.3126 13.1727 8.97163 13.1727
                    7.49979C13.1727 4.36677 10.6328 1.82695 7.49985 1.82695ZM10.9818 11.9786C10.2839 10.7794 8.9857 9.9749 7.50023
                    9.9749C6.01458 9.9749 4.71624 10.7796 4.01845 11.979C4.97952 12.7271 6.18765 13.1726 7.49985 13.1726C8.81227 13.1726
                    10.0206 12.7269 10.9818 11.9786ZM5.14999 6.50478C5.14999 5.20691 6.20212 4.15478 7.49999 4.15478C8.79786 4.15478 9.84999
                    5.20691 9.84999 6.50478C9.84999 7.80265 8.79786 8.85478 7.49999 8.85478C6.20212 8.85478 5.14999 7.80265 5.14999
                    6.50478ZM7.49999 5.10478C6.72679 5.10478 6.09999 5.73158 6.09999 6.50478C6.09999 7.27798 6.72679 7.90478 7.49999
                    7.90478C8.27319 7.90478 8.89999 7.27798 8.89999 6.50478C8.89999 5.73158 8.27319 5.10478 7.49999 5.10478Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                      {/* <TransformIcon
                      color={
                        selectedTab === 1
                          ? "white"
                          : "black"
                      }
                      className={"dashboardCategoryIconAll"}
                    ></TransformIcon> */}

                      <span className="textWhite">Role Name</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  selectedTab === 2
                    ? "overviwe-item"
                    : "overviwe-item button-dashboard-category"
                }
                onClick={() => setSelectedTab(2)}
              >
                <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                  <div
                    className={
                      selectedTab === 2
                        ? "dashboardSelectedCategory"
                        : "dashboardCategory"
                    }
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" color={selectedTab === 2 ? "white" : "black"}
                      className={"dashboardCategoryIconAll"} width="20" height="20" viewBox="0 0 20 20" fill="none">
  <rect width="20" height="20" fill="white" fillOpacity="0.01"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M9.99988 1.16931C5.12297 1.16931 1.16943 5.12284 1.16943 9.99975C1.16943 14.8766 5.12296 18.8302 9.99988 18.8302C14.8768 18.8302 18.8303 14.8766 18.8303 9.99975C18.8303 5.12284 14.8767 1.16931 9.99988 1.16931ZM2.4361 9.99975C2.4361 5.8224 5.82253 2.43597 9.99988 2.43597C14.1772 2.43597 17.5636 5.8224 17.5636 9.99975C17.5636 14.177 14.1772 17.5636 9.99988 17.5636C5.82253 17.5636 2.4361 14.177 2.4361 9.99975ZM10.6664 5.99997C10.6664 5.63179 10.3679 5.33331 9.99976 5.33331C9.63157 5.33331 9.33309 5.63179 9.33309 5.99997V9.99997C9.33309 10.1768 9.40333 10.3464 9.52836 10.4714L12.195 13.1381C12.4554 13.3984 12.8775 13.3984 13.1378 13.1381C13.3982 12.8777 13.3982 12.4556 13.1378 12.1952L10.6664 9.72384V5.99997Z" fill="currentColor"/>
</svg>
                    {/* <AvatarIcon
                      color={
                        selectedTab === 2
                          ? "white"
                          : "black"
                      }
                      className={"dashboardCategoryIconAll"}
                    ></AvatarIcon> */}

                    <span className="textWhite">Session History</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedTab == 0 && (
            <NotificationTable listTimeZone={listTimeZone} role={userRole} selectedTab={selectedTab}></NotificationTable>
          )}
          </div>
        </div>

      {selectedTab === 1  ? (
          // <div className="" style={{background:"#fff", marginTop: 0 , padding:"0"}}>
            <UserTypesTable role={userRole} openAddRoleModal={openAddRoleModal} setOpenAddRoleModal={setOpenAddRoleModal}></UserTypesTable>
          // </div>
      ) : null}

      {selectedTab === 2 && (<SessionHistory />)}
    </div>
  );
};
export default SettingPage;
