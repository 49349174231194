import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { InputComponent, InputType } from "../../components/inputs";
import NewTreeMenu from "../../components/NewTreeMenu";
import BackButtonTitle from "./component/back_button_title";
import SimpleReactValidator from "simple-react-validator";
import SubmitButton from "./component/submit_button";
import { grantController } from "../../../controllers/financials/grant_controller";
import { CreateGrantReq } from "../../../data/models/requests/financials/create_grant_req";
import { toast } from "react-toastify";
import { SortType } from "../../../core/number_extentions";
import { ResearchController } from "../../../controllers/research/research_controller";
import { useSelector } from "react-redux";
import { store } from "../../../data/storeMain";
import { GroupController } from "../../../controllers/group/group_controller";
import { useHistory } from "react-router";
import { $ReplaceRoute } from "../../../core/utils";
import { AppRoutes } from "../../../core/constants";
import { LocalDataSources } from "../../../data/local_datasources";
import { ResearchesList } from "../../../data/models/responses/research/researches_res";
import { SelectComponent } from "../../components/select_input";

export default function GrantNew() {
  const validator = useRef(
    new SimpleReactValidator({
      className: "text-danger",
    })
  );

  interface Manager {
    label: string;
    value: string;
    checked: boolean;
  }
  const controller = new grantController();
  const researchController = new ResearchController();
  const groupController = new GroupController();
  const local = new LocalDataSources();
  const history = useHistory();

  const [, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [formatedAmount, setFormattedAmount] = useState("");
  const [source, setSource] = useState("");
  const [description, setDescripption] = useState("");

  const [managerLoading, setManagerLoading] = useState(false);
  const [managers, setManagers] = useState<Manager[]>([]);
  const [defaultManager, setDefaultManager] = useState<string[]>([]);
  const [selectedManagers, setSelectedManagers] = useState<any[]>([]);
  const [excludedResearchList, setExcludedResearchList] = useState<any[]>([]);

  const researchId = useSelector(() => store.getState().ResearchId);
  const [researchLoading, setResearchLoading] = useState(false);
  const [researchList, setResearchList] = useState<any[]>([]);
  const [selectedResearch, setSelectedResearch] = useState<any[]>([]);
  const [projects, setProjects] = useState<ResearchesList[] | undefined>([]);


  useLayoutEffect(() => {
    document.title = `New Grant | Radvix`;
  }, []);
  function loadResearches() {
    setResearchLoading(true);
    controller.getGrantResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: "",
        columnHeaderName: "",
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        if (res) {
          // Retrieve the lists from the response
          const excludedResearches = res.excludedResearches || [];
          const researchList = res.researchesList || [];

          // Create the research list for the UI
          const researches = researchList.map(({ id, title, researchUsers }) => {
            let checked = false;

            // If the current research is selected, mark it as checked
            if (id === researchId.value) {
              checked = true;
              setSelectedResearch([{ id: Number(id), title }]);
            }

            // Return each research object with the enabled state
            return {
              value: Number(id),
              label: title,
              checked,
              researchUsers: researchUsers || [],
              disabled: false, // Active researches are enabled
            };
          });

          // Create the list for excluded researches (marked as disabled)
          const disabledResearches = excludedResearches.map(
            ({ id, title }) => ({
              value: Number(id),
              label: title,
              checked: false, // Excluded researches should never be checked
              disabled: true, // Excluded researches are disabled
            })
          );

          // Merge active researches with excluded (disabled) researches
          const mergedResearches = [...researches, ...disabledResearches];

          // Populate the projects list for display
          const projects = [...researchList, ...excludedResearches].map(
            ({ id, title }) => ({
              id,
              title,
            })
          );
          
          // Set the state for projects and the merged research list
          setProjects(res.researchesList);
          setResearchList(mergedResearches);
        } else {
          console.log("Response is empty or undefined");
        }
        setResearchLoading(false);
      },
      (err) => {
        setResearchLoading(false);
      }
    );
  }

  function getGroup() {
    setManagerLoading(true);
    controller.SearchGrant(
      (res) => {
        if (res && res.managers && res.managers.length > 0) {
          const managers = res.managers.map((item: any) => {
            return {
              label: item.firstName + " " + item.lastName,
              value: item.userId,
              checked: false,
            };
          });

          const _defaultManager = managers.find(
            (manager: any) => manager.value === local.getUserId()
          );
          if (_defaultManager) {
            _defaultManager.checked = true;
            setSelectedManagers([_defaultManager.value]);
          }
          //setManagers(managers);
        }
        setManagerLoading(false);
      },
      (err) => {
        setManagerLoading(false);
      }
    );
  }

  useEffect(() => {
    document.title = "New Grant | Radvix";
    getGroup();
    loadResearches();
  }, []);

  function createGrant(body: CreateGrantReq) {
    setIsLoading(true);
    ;
    controller.createGrant(
      body,
      (res) => {
        setIsLoading(false);
        history.push(
          $ReplaceRoute(
            `${AppRoutes.grant_detail.replace(":id", res.id?.toString() ?? "")}`
          )
        );
      },
      (err) => {
        //toast.error(err?.response?.data?.message);
        setIsLoading(false);
      }
    );
  }

  function formatAmount(value: string) {
    const numericValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
    const number = parseFloat(numericValue);

    if (!isNaN(number)) {
      return number.toLocaleString("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "";
  }

  const handleBlur = () => {
    setFormattedAmount(formatAmount(amount));
  };
  function handelCreateGrant() {
    setIsFormValid((prvState) => !prvState);
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      return;
    }

    console.log(selectedManagers, "Managers ------------");
    const selectedProject = selectedResearch
    .map(({ value, label }: any) => ({ id: value, title: label }));
    createGrant({
      title,
      description,
      amount: Number(amount),
      source,
      creatorUserId: local.getUserId(),
      managers: selectedManagers,
      researches: selectedProject,
    });
  }

  return (
    <div className="card grant-card">
      <div
        className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
      >
        <BackButtonTitle title="Add Grant" />
      </div>
      <div className="p-3 overflow-auto">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="mb-0">
                  <InputComponent
                    className="form-control"
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label="Grant Name*"
                    placeholder="Enter your grant name"
                    popQuestion="This will be the name of your grant. You can always edit this later."
                    value={title}
                    maxLength={150}
                    onChange={(e) => setTitle(e.target.value)}
                    inValid={validator.current.message(
                      "Grant Name",
                      title,
                      "required"
                    )}
                  ></InputComponent>
                </div>
                <div className="item">
                  <InputComponent
                    className="form-control"
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label="Grant Amount*"
                    placeholder="$100,000.00"
                    popQuestion="This will be the amount of your grant. You can always edit this later."
                    value={formatedAmount}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setFormattedAmount(e.target.value);
                    }}
                    inValid={validator.current.message(
                      "Grant Amount",
                      amount,
                      "required|numeric"
                    )}
                  ></InputComponent>
                </div>
                <div className="item">
                  <InputComponent
                    className="form-control"
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label="Grant Source"
                    placeholder="Enter grant source"
                    popQuestion="This will be the grant source. You can always edit this later."
                    value={source}
                    maxLength={150}
                    onChange={(e) => {
                      setSource(e.target.value);
                    }}
                  ></InputComponent>
                </div>

                <div className="item">
                  <div
                    className="text-primary cursor-pointer mb-1"
                    title="Info"
                  >
                    <label className="color-gray-600 fs-15 me-2">
                      Grant Description
                    </label>
                  </div>
                  <InputComponent
                    type={InputType.textarea}
                    disabled={isLoading}
                    placeholder="Description here"
                    height="132px"
                    popQuestion="You can write a description for your grant. For example, what is the main focus of this grant and what are the key objectives. You can always edit this later."
                    className="mt-1"
                    value={description}
                    maxLength={1500}
                    onChange={(e) => {
                      setDescripption(e.target.value);
                    }}
                  ></InputComponent>
                </div>                
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-lg-12 mb-3">
              <div className="d-flex align-items-center mb-1">
                <label className="color-gray-600 fs-15 me-2">
                  Select Project*
                </label>
              </div>
              <SelectComponent
                disabled={researchLoading}
                items={researchList.map((item) => ({
                  value: item.value,
                  label: item.label,
                  checked: item.checked,
                  researchUsers: item.researchUsers,
                  isDisabled: item.disabled, // Handle disabled state
                }))}

                TextItem="name"
                ValueItem="id"
                className="my-1"
                placeholder="Click to see the list…"
                //label="Assign managers"
                popQuestion="Team managers have the power to add more members to a team as well as updating team information on this page."
                onChange={(e: any) => {debugger;
                  setSelectedResearch(e);
                  if (!e || e.length === 0) {
                      setManagers([]);
                      setSelectedManagers([]);
                      return;
                  }
                  const allResearchIds = e.map((research: any) => research.value);
                  const selectedProjects = projects?.filter((project) => allResearchIds.includes(project.id));
                  if (selectedProjects?.length === 0) {
                      setManagers([]);
                      setSelectedManagers([]);
                      return;
                  }
                  const researchUsersArrays = selectedProjects?.map((project) => project.researchUsers);
                  
                  const commonUsers = researchUsersArrays?.reduce((acc, users) => {
                      return acc?.filter(user => users?.some(u => u.email === user.email));
                  });
                  const managers = commonUsers?.map((user: any) => ({
                      label: `${user.firstName} ${user.lastName}`,
                      value: user.id,
                      checked: false,
                  }));
                  const defaultManager = managers?.find(manager => manager.value === local.getUserId());
                  if (defaultManager) {
                      defaultManager.checked = true;
                      setSelectedManagers([defaultManager.value]);
                  }
                  setManagers(!managers ? [] : managers);
              }}
                isMulti
              ></SelectComponent>

              {validator.current.message(
                "Project",
                selectedResearch,
                "required"
              )}
            </div>
            <div className="item">
                  <div className="d-flex align-items-center mb-1">
                    <label className="color-gray-600 fs-15 me-2">
                      Grant Manager*
                    </label>
                  </div>
                  <NewTreeMenu
                    onChange={(manager) => {
                      setSelectedManagers([...manager]);
                    }}
                    popQuestion="If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification."
                    loading={managerLoading}
                    disabled={managerLoading}
                    options={managers}
                  />
                  {validator.current.message(
                    "Grant manager",
                    selectedManagers,
                    "required"
                  )}
                </div>
          </div>

          <div className="mt-2">
            <SubmitButton
              onClick={handelCreateGrant}
              loading={isLoading}
              disabled={isLoading}
            >
              Add grant
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
}
