import ReactLoading from "react-loading";
import { base_url_site } from "../../../../core/localVariables";
import { BaseButton } from "@fluentui/react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { HTTP } from "../../../../core/http_common";
import { handleClearStorageAndTokens } from "../../../../core/utils";
import { AppRoutes } from "../../../../core/constants";
import { useState } from "react";

export const MobileOauth = () => {
    const [loading, setLoading] = useState(false);
    const handleOAuthLogin = async () =>{
        setLoading(true);
        try{
        const _res = await HTTP.post("/User/oAuthCall");
        window.location.href = _res.data.result;
        }
        catch(e: any){
          if (e.response.data.status === 403) {
            handleClearStorageAndTokens();
            window.location.replace(AppRoutes.login);
            toast.error("Your session has expired. Please login again.");
            return;
          }
          toast.error(
            "Your session has expired. Please login again."
            // `StatusError:${error.response.status} : ${error.response.data.message}`,
          );
        }
    }
     
    const history = useHistory();
    return(
        <div className='container'>
        <div className='max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100'>
          <div className='text-center mb-3'>
            <img
              src='images/icons/new-radvix-logo.png'
              width={111}
              height={49}
              // src='images/icons/radvix-logo.svg'
              // srcSet='images/icons/radvix-logo.svg 111w'
              // sizes='(max-width: 768px) 20vw, 10vw'
              alt='Company logo'
            />
          </div>
          <div className='bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder'>
            <div className='d-flex w-100'>
              <BaseButton
                className='btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button'
                style={{ fontWeight: '400', height: '19px' }}
                onClick={() => {
                  history.push(AppRoutes.login);
                }}
              >
                Back
              </BaseButton>
              <div className='verifyingAccount'>
                <h1 className='fs-19 color-gray-1000 fw-bold text-center mb-0'>
                  oAuth Successful
                </h1>
              </div>
            </div>
            <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />

            <button
              className='d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton'
              type='submit'
              onClick={handleOAuthLogin}
            >
              {' '}
              {loading ? (
                <ReactLoading className='d-flex' type='spin' height='auto' width={20} />
              ) : (
                'Back to mobile view'
              )}{' '}
            </button>
            <hr className='border-gray-200 border w-100 opacity-100 my-px-20' />
            <div className='fs-14 fw-normal'>
              <a
                className='color-gray-600 cursor-pointer'
                href={`${base_url_site}/terms-of-services`}
                rel='noopener noreferrer'
                target='_blank'
              >
                Terms of Service |{' '}
              </a>
              <a
                className='color-gray-600 cursor-pointer'
                href={`${base_url_site}/privacy-policy`}
                rel='noopener noreferrer'
                target='_blank'
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    )
}