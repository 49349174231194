import { toast } from 'react-toastify';
import { HTTP, PUBLIC_HTTP } from '../../core/http_common';
import { ChangePasswordReq } from '../../data/models/requests/user/change_password_req';
import { UpdateMyProfileReq } from '../../data/models/requests/user/update_myprofile_req';
import { ChangePasswordResResult } from '../../data/models/responses/user/change_password_res';
import { DashboardMyReportResult } from '../../data/models/responses/user/dashboard_report';
import { UserSigninResult } from '../../data/models/responses/user/signin_res';
import { UserAccountResetResult } from '../../data/models/responses/user/account_reset_res';
import { UpdateMyProfileResResult } from '../../data/models/responses/user/update_myprofile_req';
import { UserTypeGetResult } from '../../data/models/responses/user/user_type_res';
import { RemoteUser } from '../../data/remotes/user/remote_user';
import { UserSigninReq } from './../../data/models/requests/user/signin_req';
import { UserAccountHandleReq } from './../../data/models/requests/user/account_req';
import { SortType } from '../../core/number_extentions';
import { signUpReq } from '../../data/models/requests/user/sign_up_req';
import { store } from '../../data/storeMain';
import { SetNotificationCount } from '../../data/storeMain/actions/notification_count_action';

export class UserController {
  remote = new RemoteUser();

  accountReset(
    body: UserAccountHandleReq,
    action: (res: UserAccountResetResult) => any,
    error: () => any
  ) {
    this.remote.accountReset(
      body,
      (res) => {
        if (res.data.result == false) {
          toast.error(res.data.message);
        }

        action(res.data);
      },
      (err) => {
        action(err);
        error();
      }
    );
  }
  resendMfaCode(
    body: {
      mfaPlatform?: number;
      Email?: string;
    },
    action: (res: any) => void,
    error: (err: any) => void
  ) {
    this.remote.resendMfaCode(
      body,
      (res) => {
        action(res);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  sendCodeBySms(
    body: {
      mobileNumber: string;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.sendCodeBySms(
      body,
      (res) => {
        action(res);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  verifyMobileNumber(
    body: {
      code: number;
    },
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.verifyMobileNumber(
      body,
      (res) => {
        action(res);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  resendMfaCodeForMobile(action: (res: any) => any, error: (err: any) => any) {
    this.remote.resendMfaCodeForMobile(
      (res) => {
        action(res);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  verifyMfaCode(
    body: {
      code: number;
      sessionDays?: number | null;
      Token?: string;
      email?: string;
    },
    action: (res: UserSigninResult) => any,
    error: () => any
  ) {
    this.remote.verifyMfaCode(
      body,
      (res) => {
        if (res.result) {
          localStorage.setItem('token', res.result.token || 'asd');
          localStorage.setItem('refreshToken', res.result.refreshToken || 'asd');
          localStorage.setItem('userId', res.result.id || 'asd');
          localStorage.setItem('deviceId', res.result.deviceId || 'asd');
          localStorage.setItem('logedin', 'true');
          localStorage.setItem('USER_COOKIES', res.result.cookieString || 'asd');
        }
        console.trace();
        // HTTP_IMG.interceptors.request.use((config) => {
        //   const cookies = res.result?.cookieString ?? "";
        //   if (cookies && config.headers) {
        //     console.log({config}, 'seting cookies')
        //     config.headers['x-Cookie'] = cookies;
        //   }
        //   return config;
        // });
        // HTTP_IMG.defaults.headers.common["Cookie"] = res.result?.cookieString || '';

        HTTP.defaults.headers.common['authorization'] = `Bearer ${res.result?.token}`;
        const UserInfo = {
          firstName: res.result?.firstName,
          lastName: res.result?.lastName,
          email: res.result?.email,
          image: res.result?.profileImage,
          role: res.result?.role,
          institution: res.result?.companyName,
        };
localStorage.setItem('isDeleteRequestSent', JSON.stringify(res.result?.isdataremovalrequest) ?? '');
        localStorage.setItem('userInfo', JSON.stringify(UserInfo) ?? '');
        toast.success(`${res.message}`);
        setTimeout(() => {
          action(res.result!);
        }, 200);
      },
      (err) => {
        // @ts-ignore
        error(err);
      }
    );
  }
  Signin(body: UserSigninReq, action: (res: UserSigninResult) => any, error: () => any) {
    this.remote.signIn(
      body,
      (res) => {
        if (res.result) {
          const _enumList = JSON.parse(localStorage.getItem('setting') || '{}');
          const _isMfaActive = _enumList.mfaConfig;
          if (_isMfaActive) {
            localStorage.setItem('tmpToken', res.result.token || 'asd');
            localStorage.setItem('tmpEmail', res.result.email || 'asd');
            if (res.result.mobileNumber && res.result.isMobileNumberVerified) {
              localStorage.setItem('tmpMobileNumber', res.result.mobileNumber || 'asd');
            }
          } else {
            if (res.result) {
              localStorage.setItem('token', res.result.token || 'asd');
              localStorage.setItem('refreshToken', res.result.refreshToken || 'asd');
              localStorage.setItem('deviceId', res.result.deviceId || 'asd');
              localStorage.setItem('userId', res.result.id || 'asd');
              localStorage.setItem('logedin', 'true');
              localStorage.setItem('USER_COOKIES', res.result.cookieString || 'asd');
            }
            console.trace();
            // HTTP_IMG.interceptors.request.use((config) => {
            //   const cookies = res.result?.cookieString ?? "";
            //   if (cookies && config.headers) {
            //     console.log({config}, 'seting cookies')
            //     config.headers['x-Cookie'] = cookies;
            //   }
            //   return config;
            // });
            // HTTP_IMG.defaults.headers.common["Cookie"] = res.result?.cookieString || '';

            HTTP.defaults.headers.common['authorization'] = `Bearer ${res.result?.token}`;
            const UserInfo = {
              firstName: res.result?.firstName,
              lastName: res.result?.lastName,
              email: res.result?.email,
              image: res.result?.profileImage,
              role: res.result?.role,
              institution: res.result?.companyName,
            };

            localStorage.setItem('userInfo', JSON.stringify(UserInfo) ?? '');
            toast.success(`${res.message}`);
            setTimeout(() => {
              action(res.result!);
            }, 200);
          }
        }
        action(res.result!);
      },
      (err) => {
        // @ts-ignore
        error(err);
      }
    );
  }

  Signup(body: signUpReq, action: (res: UserSigninResult) => any, error: () => any) {
    this.remote.signUp(
      body,
      (res) => {
        if (res.result) {
          action(res.result!);
        }
      },
      (err) => {
        // @ts-ignore
        error(err);
      }
    );
  }

  isUserExist(body: any, action: (res: boolean) => any, error: (err: any) => any) {
    this.remote.isUserExist(body, action, error);
  }

  async MicrosoftSignin(
    body: {
      token: string;
    },
    action: (res: UserSigninResult) => any,
    error: (err: any) => any
  ) {
    try {
      // PUBLIC_HTTP.defaults.headers.common[
      //   "authorization"
      // ] = `Bearer ${body.token}`;
      const response = await PUBLIC_HTTP.post('/User/MicrosoftSignIn', body.token);
      const res = response.data;
      if (res.result) {    
        toast.success(`${res.message}`);    
        setTimeout(() => {
          action(res.result!);
        }, 200);
      }
    } catch (e: any) {
      if (e.response.data.message) {
        toast.error(e.response.data.message);
      }
      error(e);
    }
  }
  async GoogleSignin(
    body: {
      token: string;
    },
    action: (res: UserSigninResult) => any,
    error: (err: any) => any
  ) {
    try {
      // PUBLIC_HTTP.defaults.headers.common[
      //   "authorization"
      // ] = `Bearer ${body.token}`;
      const response = await PUBLIC_HTTP.post('/User/GoogleSignIn', body.token);

      const res = response.data;
      if (res.result) {        
        toast.success(`${res.message}`);
        setTimeout(() => {
          action(res.result!);
        }, 200);
      }
    } catch (e: any) {
      if (e.response.data.message) {
        toast.error(e.response.data.message);
      }
      error(e);
    }
  }
  UpdateMyProfile(
    body: UpdateMyProfileReq,
    action: (res: UpdateMyProfileResResult) => any,
    error: (err: any) => any
  ) {
    this.remote.updateMyProfile(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        error(err);
      }
    );
  }
  changePassword(
    body: ChangePasswordReq,
    action: (res: ChangePasswordResResult) => any,
    error: () => any
  ) {
    this.remote.changePassword(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error();
      }
    );
  }
  dashboardReport(
    ResearchId: number,
    action: (res: DashboardMyReportResult) => any,
    error: () => any
  ) {
    this.remote.dshboardReport(
      ResearchId,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error();
      }
    );
  }
  lastSelectedResearch(
    ResearchId: number,
    action: (res: { timeLine: number; researchStatus: number; daysLeftDeadline: number }) => any,
    error: () => any
  ) {
    this.remote.lastSelectedResearch(
      ResearchId,
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error();
      }
    );
  }
  requestConfirmEmail(email: string, action: (res: any) => any, error: () => any) {
    this.remote.requestConfirmEmail(
      email,
      (res) => {
        toast.success('Email sent successfully');
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error();
      }
    );
  }
  createUserType(
    body: { userRoleEnum: number; title: string },
    action: (res: any) => any,
    error: () => any
  ) {
    this.remote.createUserType(
      body,
      (res) => {
        toast.success('create successfully');
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error();
      }
    );
  }
  updateUserType(body: { id: number; title: string }, action: (res: any) => any, error: () => any) {
    this.remote.editUserType(
      body,
      (res) => {
        toast.success('Update successfully');
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error();
      }
    );
  }
  getUserType(action: (res: UserTypeGetResult[]) => any, error: (res: any) => any) {
    this.remote.getUserTypes(
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  updateNotificationSettings(body: any, action: (res: any) => any, error: (res: any) => any) {
    this.remote.updateNotificationSettings(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getNotificationSettings(action: (res: any) => any, error: (res: any) => any) {
    this.remote.getNotificationSettings(
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getNotification(
    body: {
      researchId?: number;
      pageSize: number;
      pageNumber: number;
      searchParameter: string;
      columnName?: string;
      sortDirection?: any;
      notificationCategory?: number;
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.getNotification(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  readNotification(
    body: {
      notificationIds: number[];
    },
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.readNotification(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  summarizeNotifications(ResearchId: number, action: (res: any) => any, error: (res: any) => any) {
    this.remote.summarizeNotifications(
      ResearchId,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getDefaultNotificationSetting(action: (res: any) => any, error: (res: any) => any) {
    this.remote.getDefaultNotificationSetting(
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  async connectMicrosoftAccount(
    action: (res: any) => any,
    error: (res: any) => any,
    isConnect: boolean,
    token?: string,
    tokenFromOut?: string
  ) {
    if (tokenFromOut) {
      HTTP.defaults.headers.common['authorization'] = `Bearer ${tokenFromOut}`;
    }
    await HTTP.post('/User/MicrosoftAccountConnect', {
      isConnect,
      token,
    })
      .then((res) => action(res.data.result))
      .catch((err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      });
  }
  async connectGoogleAccount(isConnect: boolean, token?: string, tokenFromOut?: string) {
    if (tokenFromOut) {
      HTTP.defaults.headers.common['authorization'] = `Bearer ${tokenFromOut}`;
    }
    const _result = await HTTP.post('/User/GoogleAccountConnect', {
      isConnect,
      token,
    });
    return _result.data;
  }

  resendEmailVerfication(
    body: {
      email?: string;
    },
    action: (res: any) => void,
    error: (err: any) => void
  ) {
    this.remote.resendEmailVerfication(
      body,
      (res) => {
        action(res);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
  getUserSubscription(action: (res: any) => void, error: (err: any) => void) {
    this.remote.getUserSubscription(
      (res) => {
        action(res.result);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getEmailIntegrationStatus(action: (res: any) => void, error: (err: any) => void) {
    this.remote.getEmailIntegrationStatus(
      (res) => {
        action(res.result);
      },
      (err) => {
        error(err);
      }
    );
  }

  GetUserTrial(action: (res: any) => void, error: (err: any) => void) {
    this.remote.getEmailIntegrationStatus(
      (res) => {
        action(res.result);
      },
      (err) => {
        error(err);
      }
    );
  }
  sendDeleteRequest(
    body: any ,
    action: (res: any) => any,
    error: (err: any) => any
  ) {
    this.remote.sendDeleteRequest(
      body,
      (res) => {
        toast.success(res?.data?.message);
        action(res);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err.response?.data.message);
      }
    );
  }
}
