import { BaseButton } from '@fluentui/react';
import { TextField } from '@radix-ui/themes';
import Tippy from '@tippyjs/react';
import DOMPurify from 'dompurify';
import moment from 'moment';
import React, { Fragment } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Dropzone from 'react-dropzone';
import Skeleton from 'react-loading-skeleton';
import { RouteComponentProps, withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import { DiscusstionController } from '../../../controllers/discussion/discusstion_controller';
import { ResearchController } from '../../../controllers/research/research_controller';
import { UploadController } from '../../../controllers/upload_media/upload_media';
import { AppRoutes, LOADING_MODE, MOMENT_DATE_TIME_FORMAT } from '../../../core/constants';
import { HTTP } from '../../../core/http_common';
import { Priority, ProfileTypes, SortType, TopicType } from '../../../core/number_extentions';
import {
  $ReplaceRoute,
  DiscussionTopic,
  getUserTitle,
  isTokenInHttpAuth,
  removeDuplicateObj,
  uploadAllFiles,
  uploadAllLinks,
} from '../../../core/utils';
import { LocalDataSources } from '../../../data/local_datasources';
import { GetDiscusstionPanelResResult } from '../../../data/models/responses/discussion/get_discusstion_panel_res';
import { store } from '../../../data/storeMain';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import DashboardProjectDropdown from '../../components/ActionComponent/DashboardProjectDropdown';
import UserWithAvatar from '../../components/UserWithAvatar';
import { ImageWithToken } from '../../components/box_list_scroll';
import { MainButton, MainButtonType } from '../../components/button';
import { CircleIcon, ThemeCircleIcon } from '../../components/circle_icon';
import GroupedAvatars from '../../components/groupedAvatars';
import { InputComponent, InputType } from '../../components/inputs';
import AddMemberContent from './component/add_member_content';
import AddNewButton from './component/add_new_button';
import ChatEmailSlackTabs from './component/chat_email_slack_tabs';
import NotSelectedMessage from './component/not_selected_message';
import TabButtons from './component/tab_buttons';
interface RouteParams {
  id: string;
}
type StateType = {
  selectedResearchId: any;
  files: Array<File>;
  Discusstion: GetDiscusstionPanelResResult;
  myUserId: string;
  loading: boolean;
  chatLoading: boolean;
  ExternalUrl: Array<string>;
  External: string;
  massage: string;
  sectionDetail?: {
    title: string;
    id: string | number;
  } | null;

  showAddMember: boolean;
  showAddMemberPhone: boolean;
  getMembersLoading: boolean;
  updatingDiscuss: boolean;
  allMembers: Array<any>;
  loadingMode: string;
  selectedCommunicationTab: any;
  researhList: any[];
  projectSelected: any;
  notificationCategory: any;
  selectedMessage: any;
  showAttachments: boolean;
  Search: any;
  DiscussionList: any[];
  selectedDiscussion?: any;
  topicId?: any;
  sectionId?: any;
  topicName?: any;
  topicTitle?: any;
  showSearchInput: boolean;
  taskId?: any;
  toggleChat: boolean;
};
class DiscusstionPage extends React.Component<RouteComponentProps<RouteParams>> {
  // private getDiscustionPanelInterval: NodeJS.Timeout | undefined;
  RoleUser = store.getState().userRole;
  date = new Date();
  controller = new DiscusstionController();
  local = new LocalDataSources();
  UploadController = new UploadController();
  researchController = new ResearchController();
  validator = new SimpleReactValidator({
    className: 'text-danger',
  });
  handelChangeDate(params: any): void {}
  state: StateType = {
    selectedResearchId: store.getState().ResearchId,
    files: [],
    DiscussionList: [],
    showAttachments: false,
    loadingMode: '',
    selectedCommunicationTab: 0,
    notificationCategory: TopicType.All,
    selectedMessage: 0,
    Discusstion: {
      creatorUserFirstName: '',
      creatorUserId: '',
      creatorUserLastName: '',
      histories: [],
      id: 0,
      isTicket: false,
      priority: 0,
      subject: '',
      topic: 0,
      users: [],
      sectionId: 0,
      broadcastTypes: [],
    },
    myUserId: this.local.getUserId(),
    loading: false,
    chatLoading: false,
    ExternalUrl: [],
    External: '',
    massage: '',
    sectionDetail: null,
    getMembersLoading: false,
    showAddMember: false,
    showAddMemberPhone: false,
    updatingDiscuss: false,
    allMembers: [],
    researhList: [],
    projectSelected: store.getState().ResearchId?.value,
    Search: '',
    selectedDiscussion: null,
    topicName: '',
    topicTitle: '',
    showSearchInput: false,
    taskId: '',
    toggleChat: false,
  };
  componentDidMount() {
    document.title = "My Communications | Radvix";
    store.dispatch(SetHeaderTitle('Communications'));
    if (!isTokenInHttpAuth(HTTP)) return;
    const queryParams = new URLSearchParams(this.props.location.search);
    const sectionId = queryParams.get('sectionId');
    const topicEnum = queryParams.get('topicId');
    const isDashboard = queryParams.get('isDashboard');
    const topicTitle = queryParams.get('title');
    const disussionId = queryParams.get('discussionId');
    const taskId = queryParams.get('taskId');
    this.setState({
      topicTitle: topicTitle,
      taskId: taskId,
      selectedResearchId: topicTitle
        ? { label: topicTitle, value: parseInt(sectionId ?? '') }
        : store.getState().ResearchId,
    });

    if (sectionId && !isDashboard) {
      const topicId = topicEnum ? parseInt(topicEnum) : 0;
      this.setState({
        topicId: topicId,
        sectionId: sectionId,
      });

      if (topicId == DiscussionTopic.Project) {
        this.setState({
          topicName: 'Project',
          selectedResearchId: { label: topicTitle, value: parseInt(sectionId) },
        });
        if (topicTitle) {
          store.dispatch(
            SetResearchId({
              label: topicTitle ?? '',
              value: parseInt(sectionId),
            })
          );
        }
      } else if (topicId == DiscussionTopic.GeneralTopic) {
        this.setState({ topicName: 'General Topic' });
      } else if (topicId == DiscussionTopic.Task) {
        const _researchId = parseInt(queryParams.get('researchId') as string);
        const _researchTitle = queryParams.get('researchTitle');
        this.setState({
          topicName: 'Task',
          selectedResearchId: { label: _researchTitle, value: _researchId },
        });
        if (topicTitle) {
          store.dispatch(SetResearchId({ label: _researchTitle ?? '', value: _researchId }));
        }
      } else if (topicId == DiscussionTopic.Data) {
        const _researchId = parseInt(queryParams.get('researchId') as string);
        const _researchTitle = queryParams.get('researchTitle');
        this.setState({
          topicName: 'Data',
          selectedResearchId: { label: _researchTitle, value: _researchId },
        });
        if (topicTitle) {
          store.dispatch(SetResearchId({ label: _researchTitle ?? '', value: _researchId }));
        }
      } else if (topicId == DiscussionTopic.Equipment) {
        this.setState({ topicName: 'Equipment' });
      } else if (topicId == DiscussionTopic.Laboratory) {
        this.setState({ topicName: 'Laboratory' });
      } else if (topicId == DiscussionTopic.Team) {
        this.setState({ topicName: 'Team' });
      } else if (topicId == DiscussionTopic.Publication) {
        const _researchId = parseInt(queryParams.get('researchId') as string);
        const _researchTitle = queryParams.get('researchTitle');
        this.setState({
          topicName: 'Publication',
          selectedResearchId: { label: _researchTitle, value: _researchId },
        });
        if (topicTitle) {
          store.dispatch(SetResearchId({ label: _researchTitle ?? '', value: _researchId }));
        }
      } else if (topicId == DiscussionTopic.Expense) {
        this.setState({ topicName: 'Expense' });
      } else if (topicId == DiscussionTopic.Ticket) {
        this.setState({ topicName: 'Ticket' });
      } else if (topicId == DiscussionTopic.Broadcast) {
        this.setState({ topicName: 'Broadcast' });
      }
    }

    this.loadResearches();
    this.GetDiscusstion(
      '',
      this.state.projectSelected,
      TopicType.All,
      topicEnum,
      sectionId,
      disussionId
    );
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.delete('discussionId');
    window.history.replaceState({}, document.title, url.toString());
  }
  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === 'Enter' || event.key === 'Return') && !event.shiftKey) {
      event.preventDefault(); // Prevent default Enter key behavior (e.g., form submission)
      this.SendMassage();
    }
  };

  handleChangeDiscussionSearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetDiscusstion(sanitizedValue, this.state.selectedResearchId.value);
  }

  GetDiscusstion(
    Search?: string,
    projectId?: any,
    profileType?: number,
    topicId?: any,
    sectionId?: any,
    discussionId?: any
  ) {
    this.setState({
      loading: true,
    });
    this.controller.getAllDiscusstion(
      {
        PageNumber: 1,
        PageSize: 10000,
        ticket: false,
        SearchParameter: Search ? Search : this.state.Search,
        selectedProject: projectId,
        profileType: profileType,
        topicId: topicId,
        sectionId: sectionId,
        // UserId: store.getState().userInfo.id,
      },
      (res) => {
        if (!res) {
          this.setState({
            loading: false,
          });
          return;
        }

        const sortedDiscussions = (res.discussions || []).sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        this.setState({
          DiscussionList: sortedDiscussions.map((discussion) => ({
            ...discussion,
            isSelected: false,
          })),
          loading: false,
        });
        if (discussionId) {
          const Disc = this.state.DiscussionList.find((x) => x.id === parseInt(discussionId));
          this.handleLoadDiscussionPanel(Disc);
        }
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }
  getDiscussionTopicData(_sectionId: number, _topicId: number) {
    return new Promise((resolve, rejcet) => {
      this.controller.getDiscussionTopicData(
        {
          sectionId: _sectionId,
          topicId: _topicId,
        },
        (_res) => {
          resolve(_res);
        },
        (err) => {
          rejcet(err);
        }
      );
    });
  }
  getDiscustionPanel(id: any) {
    this.setState({ chatLoading: true, selectedDiscussion: null });
    this.controller.getDiscusstionPanel(
      {
        discussionId: id,
        ticket: false,
      },
      async (res) => {
        const _users = res.users.map((_u: any) => {
          const userTitleAst = getUserTitle(_u);
          return {
            ..._u,
            title: userTitleAst,
            secondary: _u.invitationStatus === 1 ? 'Pending' : null,
          };
        });
        let _discussionTopicData = null;
        if (res.topic !== 1) {
          _discussionTopicData = await this.getDiscussionTopicData(
            res.broadcastTypes != null ? res.id : res.sectionId,
            res.topic
          );
        }
        const updatedHistories = res.histories.map((history) => {
          return {
            ...history,
            sectionId: res.sectionId,
            attachments: history.attachments.map((attachment) => ({
              ...attachment,
              sectionId: res.id,
              profileType: ProfileTypes.DISCUSSIONPROFILE,
            })),
          };
        });

        this.setState({
          selectedDiscussion: {
            ...res,
            users: _users,
            histories: updatedHistories,
          },
          Discusstion: {
            ...res,
            users: _users,
          },

          sectionDetail: _discussionTopicData,
          chatLoading: false,
        });

        window.dispatchEvent(new CustomEvent('UPDATE_RECENT_DISCUSSION'));
      },
      (err) => {
        this.setState({ chatLoading: false });
      }
    );
  }
  onDrop = (fileList: any) => {
    fileList.map((item: any, index: number) => {
      const found = this.state.files.find((element: any) => element.name === item.name);
      if (found === undefined) {
        this.setState({ files: [fileList[index], ...this.state.files] });
      }
    });
  };
  handleChange(target: string, val: any) {
    this.setState({
      [target]: val,
    });
  }
  async handelUpload(id: number) {
    try {
      this.setState({
        loadingMode: LOADING_MODE.links,
      });
      await uploadAllLinks(id, this.state.ExternalUrl, 7);

      this.setState({
        loadingMode: LOADING_MODE.files,
      });
      await uploadAllFiles(id, this.state.files, 7);
      this.setState({
        loading: false,
        ExternalUrl: [],
        loadingMode: '',
        External: '',
        massage: '',
        files: [],
      });
      this.getDiscustionPanel(this.state.selectedDiscussion.id);
    } catch (e) {
      this.setState({
        loading: false,
        loadingMode: '',
        ExternalUrl: [],
        External: '',
        massage: '',
        files: [],
      });
    }

    // const formData = new FormData();
    // for (let i = 0; i < this.state.files.length; i++) {
    //   const file = this.state.files[i];
    //   formData.append("Files", file);
    // }
    // for (let i = 0; i < this.state.ExternalUrl.length; i++) {
    //   const file = this.state.ExternalUrl[i];
    //   formData.append("ExternalUrls", file);
    // }
    // formData.append("UseCase", "7");
    // formData.append("SectionId", id.toString());

    // await this.UploadController.UloadMedia(
    //   formData,
    //   (res) => {

    //   },
    //   () => {
    //     this.setState({
    //       loading: false,
    //       ExternalUrl: [],
    //       External: "",
    //       massage: "",
    //       files: [],
    //     });
    //   }
    // );
  }
  addExternalUrl() {
    if (this.validator.fields.input) {
      let Url = [...this.state.ExternalUrl];
      if (this.state.External.length > 2) {
        Url.push(this.state.External);
      }
      this.setState({
        ExternalUrl: Url,
        External: '',
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handelDeleteFile(arg: File) {
    this.setState({
      files: this.state.files.filter((file) => file.name !== arg.name),
    });
  }
  handelDeleteExternalLink(link: string) {
    this.setState({
      ExternalUrl: this.state.ExternalUrl.filter((item) => item !== link),
    });
  }
  SendMassage() {
    this.addExternalUrl();

    if (this.validator.allValid()) {
      const body = {
        discussionId: this.state.selectedDiscussion.id,
        message: this.state.massage,
      };
      this.setState({
        loading: true,
        loadingMode: 'Sending..',
      });
      this.controller.createMassage(
        body,
        async (res) => {
          if (this.state.files.length || this.state.ExternalUrl.length > 0) {
            await this.handelUpload(res.result);
          } else {
            this.setState({
              loading: false,
              loadingMode: '',
              ExternalUrl: [],
              External: '',
              massage: '',
              files: [],
            });
            this.getDiscustionPanel(this.state.selectedDiscussion.id);
          }
        },
        (err) => {
          this.setState({
            massage: '',
            files: [],
            loading: false,
            loadingMode: '',
          });
          this.getDiscustionPanel(this.state.selectedDiscussion.id);
        }
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleGetSectionUsers = (discussionTopic: number, sectionId: number) => {
    if (sectionId === -1) return Promise.resolve({ members: [] });
    this.setState({
      getMembersLoading: true,
    });
    return new Promise((resolve, reject) => {
      this.controller.discusstionSectionUser(
        {
          discussionTopic,
          sectionId,
        },
        (_res) => {
          let _allTeamUsers: any[] = [..._res?.members];
          _res.teams?.forEach((_team) => {
            _allTeamUsers.push(...(_team?.users || []));
          });
          const _uniqueMembers: any = removeDuplicateObj(_allTeamUsers || []);
          this.setState({
            getMembersLoading: false,
          });
          resolve({
            members: _uniqueMembers,
          });
        },
        (err) => {
          this.setState({
            getMembersLoading: false,
          });
          reject(err);
        }
      );
    });
  };

  handleUpdateDiscussion(_addedMembers: any[], _removedMembers: any[]) {
    this.setState({
      updatingDiscuss: true,
    });
    return new Promise((resolve, reject) => {
      this.controller.addUsers(
        {
          id: this.state.selectedDiscussion.id,
          sectionId: this.state.selectedDiscussion.sectionId,
          topic: this.state.selectedDiscussion.topic,
          usersId: _addedMembers,
        },
        (res) => {
          this.setState({
            updatingDiscuss: false,
          });
          resolve(res);
        },
        (err) => {
          this.setState({
            updatingDiscuss: false,
          });
          reject(err);
        }
      );
    });
  }
  loadResearches() {
    this.researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        let researches: { value: any; label: any }[] = [];
        if (res && res.researchesList && res.researchesList.length > 0) {
          researches = res.researchesList?.map(({ id, title }) => ({
            value: id,
            label: title,
          }));
        }
        this.setState({ researhList: researches });
      },
      (err) => {}
    );
  }
  handleChangeProject(e: any) {
    this.setState({
      projectSelected: e,
    });
    store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    // if (e && e.value != 0) {
    //   store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    // }
    this.GetDiscusstion(
      this.state.Search,
      e.value,
      this.state.notificationCategory,
      this.state.topicId,
      this.state.sectionId,
      ''
    );
  }
  formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 MB';

    const m = 1024 * 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizeInMB = bytes / m;

    return `${sizeInMB.toFixed(dm)} MB`;
  }
  handleCategoryNotificationSearch(e: number) {
    this.setState({ notificationCategory: e });
    this.GetDiscusstion('', this.state.projectSelected.value == undefined ? this.state.projectSelected : this.state.projectSelected.value, e);
  }
  handleLoadDiscussionPanel(x: any) {
    this.state.DiscussionList.forEach((element) => {
      if (x.id === element.id) {
        element.isSelected = true;
        element.unreadMessageCount = 0;
      } else {
        element.isSelected = false;
      }
    });
    this.getDiscustionPanel(x.id);
    this.setState({ toggleChat: true });
  }
  renderPrioirty(status: any) {
    if (status == Priority.High) {
      return (
        <span className='badge bg-danger text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          High
        </span>
      );
    }
    if (status == Priority.Medium) {
      return (
        <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Medium
        </span>
      );
    }
    if (status == Priority.Low) {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Low
        </span>
      );
    }
  }
  downloadFile(props: any) {
    if (props.externalUrl) {
      const aElement = document.createElement('a');
      aElement.href = props.externalUrl;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.externalUrl);
    } else if (props?.name) {
      const aElement = document.createElement('a');
      aElement.href = props.name;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(props.name);
    }
  }

  handlerToggleSearch = () => {
    this.setState({ showSearchInput: !this.state.showSearchInput });
  };

  render() {
    const files = this.state.files.map((file: any) => (
      <li
        key={file.name}
        className='d-flex justify-content-between badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team'
        style={{ marginBottom: '3px' }}
      >
        <span
          className='text-truncate col-11 mx-2'
          style={{ fontFamily: 'Sanfransisco', textAlign: 'start' }}
        >
          {file.name} - {this.formatBytes(file.size)}
        </span>
        <CircleIcon
          type={ThemeCircleIcon.dark}
          width='22px'
          height='22px'
          padding='0'
          onClick={() => {
            this.handelDeleteFile(file);
          }}
          style={{ cursor: 'pointer', display: 'contents' }}
        >
          <img src='/images/icons/cross-1.svg' alt='radvix' width={15} height={15} />
        </CircleIcon>
      </li>
    ));
    const selectedDiscussion = this.state.selectedDiscussion?.histories?.map((x: any) =>
      x.userId !== store.getState().userInfo.id ? (
        <div className='mb-3'>
          <div className='p-3 bg-gray-200 rounded-px-6 my-px-6 max-w-rem-32'>
            <div className='d-flex align-items-center justify-content-between mb-3 flex-wrap gap-2'>
              <div className='d-flex align-items-center text-nowrap fs-15'>
                <div className='d-flex align-items-center text-nowrap'>
                  <ImageWithToken
                    originalImage={x.userProfilePicture}
                    hasImage={x.userProfilePicture ? true : false}
                    alt='Avatar'
                    className='rounded-circle avatar rounded-avatar-dashboard'
                    src={
                      x.userProfilePicturee === null ||
                      x.userProfilePicture === '' ||
                      x.userProfilePicture === undefined
                        ? '/images/images/img_avatar.png'
                        : x.userProfilePicture
                    }
                    textInAvatar={`${x.userFirstName} ${x.userLastName}`}
                  />
                  {x.userFirstName} {x.userLastName}
                </div>
              </div>
              <h6 className='fs-13 color-gray-500 fw-normal'>
                {moment(x.createDate).format(MOMENT_DATE_TIME_FORMAT)}{' '}
              </h6>
            </div>
            <p className='color-gray-600 fw-medium fs-15'> {x.message}</p>
            {x.attachments?.map((attachment: any) => (
              <div
                className='bg-white rounded-px-6 d-flex border border-gray-300'
                style={{ marginBottom: '4px' }}
              >
                <div className='d-flex align-items-center gap-3 p-2 justify-content-between w-100'>
                  <div className='d-flex align-items-center discussion-chat-text'>
                    <h6
                      className='fs-13 fw-medium color-gray-1000 mb-0 text-truncate'
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.downloadFile(attachment)}
                    >
                      {attachment.inputDataType === 4 ? attachment.externalUrl : attachment.title}
                    </h6>
                  </div>
                  <div className={`btn-group ${attachment.inputDataType === 4 ? 'w-30' : 'w-24'}`}>
                    <button
                      type='button'
                      className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                      onClick={() => this.downloadFile(attachment)}
                    >
                      {attachment.inputDataType == 4 ? (
                        <>
                          <svg
                            className='me-px-6'
                            xmlns='http://www.w3.org/2000/svg'
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                          >
                            <rect
                              width='15'
                              height='15'
                              transform='translate(0 0.500534)'
                              fill='white'
                              fill-opacity='0.01'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.50039 2.30055C4.35237 2.30055 1.80039 4.85253 1.80039 8.00055C1.80039 11.1485 4.35237 13.7005 7.50039 13.7005C10.6484 13.7005 13.2004 11.1485 13.2004 8.00055C13.2004 4.85253 10.6484 2.30055 7.50039 2.30055ZM0.900391 8.00055C0.900391 4.35547 3.85531 1.40056 7.50039 1.40056C11.1454 1.40056 14.1004 4.35547 14.1004 8.00055C14.1004 11.6456 11.1454 14.6005 7.50039 14.6005C3.85531 14.6005 0.900391 11.6456 0.900391 8.00055Z'
                              fill='#5746AF'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M13.5 8.40051H1.5V7.60051H13.5V8.40051Z'
                              fill='#5746AF'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.10022 14.0005V2.00053H7.90022V14.0005H7.10022ZM10.3753 8.00051C10.3753 5.82777 9.59395 3.67831 8.06214 2.25709L8.53824 1.74394C10.2399 3.32271 11.0753 5.67326 11.0753 8.00051C11.0753 10.3278 10.2399 12.6783 8.53824 14.2571L8.06214 13.7439C9.59395 12.3227 10.3753 10.1733 10.3753 8.00051ZM4 8.00053C4 5.67664 4.80817 3.32731 6.45799 1.74772L6.94208 2.25334C5.45851 3.67376 4.7 5.82442 4.7 8.00053C4.70001 10.1766 5.45853 12.3273 6.9421 13.7477L6.458 14.2533C4.80819 12.6737 4.00001 10.3244 4 8.00053Z'
                              fill='#5746AF'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.49929 4.45854C9.66861 4.45854 11.8746 4.85968 13.3699 5.69501C13.5387 5.78928 13.5991 6.0025 13.5048 6.17126C13.4106 6.34001 13.1973 6.4004 13.0286 6.30613C11.6787 5.55208 9.60732 5.15854 7.49929 5.15854C5.39125 5.15854 3.31985 5.55208 1.96997 6.30613C1.80121 6.4004 1.58799 6.34001 1.49372 6.17126C1.39946 6.0025 1.45984 5.78928 1.6286 5.69501C3.12399 4.85968 5.32996 4.45854 7.49929 4.45854ZM7.49929 11.3505C9.66861 11.3505 11.8746 10.9493 13.3699 10.114C13.5387 10.0198 13.5991 9.80654 13.5048 9.63779C13.4106 9.46903 13.1973 9.40865 13.0286 9.50291C11.6787 10.257 9.60732 10.6505 7.49929 10.6505C5.39125 10.6505 3.31985 10.257 1.96997 9.50292C1.80121 9.40865 1.58799 9.46903 1.49372 9.63779C1.39946 9.80654 1.45984 10.0198 1.6286 10.114C3.12399 10.9493 5.32996 11.3505 7.49929 11.3505Z'
                              fill='#5746AF'
                            />
                          </svg>
                          {`Open link`}
                        </>
                      ) : (
                        <>
                          <svg
                            className='me-px-6'
                            xmlns='http://www.w3.org/2000/svg'
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                          >
                            <rect
                              width='15'
                              height='15'
                              transform='translate(0 0.500427)'
                              fill='white'
                              fill-opacity='0.01'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.5 11.5004C4.80285 11.5004 2.52952 10.1223 1.09622 8.00044C2.52952 5.87859 4.80285 4.50043 7.5 4.50043C10.1971 4.50043 12.4705 5.87859 13.9038 8.00044C12.4705 10.1223 10.1971 11.5004 7.5 11.5004ZM7.5 3.50043C4.30786 3.50043 1.65639 5.20681 0.0760002 7.73544C-0.0253338 7.89758 -0.0253334 8.10331 0.0760014 8.26544C1.65639 10.794 4.30786 12.5004 7.5 12.5004C10.6921 12.5004 13.3436 10.794 14.924 8.26544C15.0253 8.10331 15.0253 7.89758 14.924 7.73544C13.3436 5.20681 10.6921 3.50043 7.5 3.50043ZM7.5 10.0004C8.60457 10.0004 9.5 9.105 9.5 8.00043C9.5 6.89586 8.60457 6.00043 7.5 6.00043C6.39543 6.00043 5.5 6.89586 5.5 8.00043C5.5 9.105 6.39543 10.0004 7.5 10.0004Z'
                              fill='#5746AF'
                            />
                          </svg>
                          {`Open file`}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='mb-3'>
          <div className='p-3 bg-primary-3 rounded-px-6 my-px-6 max-w-rem-32 ms-auto discussion-send-message'>
            <div className='d-flex align-items-center justify-content-between mb-3 flex-wrap gap-2'>
              <div className='d-flex align-items-center text-nowrap fs-15'>
                <div className='d-flex align-items-center text-nowrap'>
                  <ImageWithToken
                    originalImage={x.userProfilePicture}
                    hasImage={x.userProfilePicture ? true : false}
                    alt='Avatar'
                    className='rounded-circle avatar rounded-avatar-dashboard'
                    src={
                      x.userProfilePicturee === null ||
                      x.userProfilePicture === '' ||
                      x.userProfilePicture === undefined
                        ? '/images/images/img_avatar.png'
                        : x.userProfilePicture
                    }
                    textInAvatar={`${x.userFirstName} ${x.userLastName}`}
                  />
                  {x.userFirstName} {x.userLastName}
                </div>
              </div>
              <h6 className='fs-13 color-gray-500 fw-normal'>
                {moment(x.createDate).format(MOMENT_DATE_TIME_FORMAT)}{' '}
              </h6>
            </div>
            <p className='color-gray-600 fw-medium fs-15'> {x.message}</p>
            {x.attachments?.map((attachment: any) => (
              <div
                className='bg-white rounded-px-6 d-flex border border-gray-300'
                style={{ marginBottom: '4px' }}
              >
                <div className='d-flex align-items-center gap-3 p-2 justify-content-between w-100'>
                  <div className='d-flex align-items-center discussion-chat-text w-70'>
                    <h6
                      className='fs-13 fw-medium color-gray-1000 mb-0 text-truncate'
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.downloadFile(attachment)}
                    >
                      {attachment.inputDataType === 4 ? attachment.externalUrl : attachment.title}
                    </h6>
                  </div>
                  <div className={'btn-group w-24'}>
                    <button
                      type='button'
                      className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                      onClick={() => this.downloadFile(attachment)}
                    >
                      {attachment.inputDataType == 4 ? (
                        <>
                          <svg
                            className='me-px-6 hover-fill'
                            xmlns='http://www.w3.org/2000/svg'
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                          >
                            <rect
                              width='15'
                              height='15'
                              transform='translate(0 0.500534)'
                              fill='white'
                              fill-opacity='0.01'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.50039 2.30055C4.35237 2.30055 1.80039 4.85253 1.80039 8.00055C1.80039 11.1485 4.35237 13.7005 7.50039 13.7005C10.6484 13.7005 13.2004 11.1485 13.2004 8.00055C13.2004 4.85253 10.6484 2.30055 7.50039 2.30055ZM0.900391 8.00055C0.900391 4.35547 3.85531 1.40056 7.50039 1.40056C11.1454 1.40056 14.1004 4.35547 14.1004 8.00055C14.1004 11.6456 11.1454 14.6005 7.50039 14.6005C3.85531 14.6005 0.900391 11.6456 0.900391 8.00055Z'
                              fill='#5746AF'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M13.5 8.40051H1.5V7.60051H13.5V8.40051Z'
                              fill='#5746AF'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.10022 14.0005V2.00053H7.90022V14.0005H7.10022ZM10.3753 8.00051C10.3753 5.82777 9.59395 3.67831 8.06214 2.25709L8.53824 1.74394C10.2399 3.32271 11.0753 5.67326 11.0753 8.00051C11.0753 10.3278 10.2399 12.6783 8.53824 14.2571L8.06214 13.7439C9.59395 12.3227 10.3753 10.1733 10.3753 8.00051ZM4 8.00053C4 5.67664 4.80817 3.32731 6.45799 1.74772L6.94208 2.25334C5.45851 3.67376 4.7 5.82442 4.7 8.00053C4.70001 10.1766 5.45853 12.3273 6.9421 13.7477L6.458 14.2533C4.80819 12.6737 4.00001 10.3244 4 8.00053Z'
                              fill='#5746AF'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.49929 4.45854C9.66861 4.45854 11.8746 4.85968 13.3699 5.69501C13.5387 5.78928 13.5991 6.0025 13.5048 6.17126C13.4106 6.34001 13.1973 6.4004 13.0286 6.30613C11.6787 5.55208 9.60732 5.15854 7.49929 5.15854C5.39125 5.15854 3.31985 5.55208 1.96997 6.30613C1.80121 6.4004 1.58799 6.34001 1.49372 6.17126C1.39946 6.0025 1.45984 5.78928 1.6286 5.69501C3.12399 4.85968 5.32996 4.45854 7.49929 4.45854ZM7.49929 11.3505C9.66861 11.3505 11.8746 10.9493 13.3699 10.114C13.5387 10.0198 13.5991 9.80654 13.5048 9.63779C13.4106 9.46903 13.1973 9.40865 13.0286 9.50291C11.6787 10.257 9.60732 10.6505 7.49929 10.6505C5.39125 10.6505 3.31985 10.257 1.96997 9.50292C1.80121 9.40865 1.58799 9.46903 1.49372 9.63779C1.39946 9.80654 1.45984 10.0198 1.6286 10.114C3.12399 10.9493 5.32996 11.3505 7.49929 11.3505Z'
                              fill='#5746AF'
                            />
                          </svg>
                          {`Open link`}
                        </>
                      ) : (
                        <>
                          <svg
                            className='me-px-6'
                            xmlns='http://www.w3.org/2000/svg'
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                          >
                            <rect
                              width='15'
                              height='15'
                              transform='translate(0 0.500427)'
                              fill='white'
                              fill-opacity='0.01'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M7.5 11.5004C4.80285 11.5004 2.52952 10.1223 1.09622 8.00044C2.52952 5.87859 4.80285 4.50043 7.5 4.50043C10.1971 4.50043 12.4705 5.87859 13.9038 8.00044C12.4705 10.1223 10.1971 11.5004 7.5 11.5004ZM7.5 3.50043C4.30786 3.50043 1.65639 5.20681 0.0760002 7.73544C-0.0253338 7.89758 -0.0253334 8.10331 0.0760014 8.26544C1.65639 10.794 4.30786 12.5004 7.5 12.5004C10.6921 12.5004 13.3436 10.794 14.924 8.26544C15.0253 8.10331 15.0253 7.89758 14.924 7.73544C13.3436 5.20681 10.6921 3.50043 7.5 3.50043ZM7.5 10.0004C8.60457 10.0004 9.5 9.105 9.5 8.00043C9.5 6.89586 8.60457 6.00043 7.5 6.00043C6.39543 6.00043 5.5 6.89586 5.5 8.00043C5.5 9.105 6.39543 10.0004 7.5 10.0004Z'
                              fill='#5746AF'
                            />
                          </svg>
                          {`Open file`}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    );

    const discussionList = this.state.DiscussionList.map((x: any) =>
      x.unreadMessageCount && x.unreadMessageCount > 0 ? (
        <div
          className='d-flex justify-content-between flex-sm-row flex-column align-items-sm-center p-3 rounded-px-4 cursor-pointer mb-2 chat'
          style={{
            background: this.state.selectedMessage === 0 ? '#F5F2FF' : '#fff',
            overflow: 'hidden',
          }}
          onClick={() => this.handleLoadDiscussionPanel(x)}
        >
          <div className='d-flex align-items-center gap-2'>
            {x.unreadMessageCount && x.unreadMessageCount > 0 && (
              <div className='avatar w-px-20 h-px-20 flex-shrink-0 rounded-circle bg-danger text-white fs-10'>
                {x.unreadMessageCount}
              </div>
            )}
            <div>
              <h6 className='fs-14 fw-semibold color-gray-1000 mb-1'> {x.subject}</h6>
              <h6 className='fs-14 color-gray-500 mb-0'>
                <span style={{ color: 'black', fontWeight: '300' }}>{x.lastMessageSender}: </span>{' '}
                {x.lastMessage.split(' ').slice(0, 4).join(' ')}
              </h6>
            </div>
          </div>
          <div className='d-flex align-items-end flex-column mt-sm-0 mt-3'>
            <h6 className='fs-14 fw-semibold text-primary mb-2'>
              {' '}
              {moment(x.lastMessageDate).format(MOMENT_DATE_TIME_FORMAT)}{' '}
            </h6>
          </div>
        </div>
      ) : (
        <div
          className='d-flex flex-column p-3 rounded-px-4 cursor-pointer mb-2 chat'
          style={{
            overflow: 'hidden',
            background: x.isSelected === true ? 'var(--Primary-3, #F5F2FF)' : '#fff',
            borderRadius: x.isSelected === true ? 'var(--1, 4px)' : undefined,
          }}
          onClick={() => this.handleLoadDiscussionPanel(x)}
        >
          <div className='d-flex align-items-center gap-2'>
            <div>
              <h6 className='fs-13 fw-bold color-gray-1000 mb-1'>{x.subject}</h6>
              <h6 className='fs-13 fw-bold color-gray-500 mb-0'>
                <span>{x.lastMessageSender}: </span>{' '}
                {x.lastMessage.split(' ').slice(0, 4).join(' ')}
              </h6>
            </div>
          </div>
          <div className='d-flexflex-column mt-1'>
            <h6
              className='fs-10 fw-bold mb-0'
              style={{
                color: x.isSelected === true ? '#5746AF' : '#8B8D98',
              }}
            >
              {moment(x.lastMessageDate).format(MOMENT_DATE_TIME_FORMAT)}
            </h6>
          </div>
        </div>
      )
    );

    return (
      <div className='card'>
        {/* ============= Desktop Header ============= */}
        <div className='d-none d-xl-block'>
          <div className='p-3 border-bottom border-gray-400'>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Communications</h2>
            <div className='mt-px-20'>
              <ChatEmailSlackTabs
                selectedCommunicationTab={this.state.selectedCommunicationTab}
                setSelectedTab={(selectedTab: number) =>
                  this.setState({
                    selectedTab: selectedTab,
                  })
                }
              />
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-between gap-4 p-3 pb-0'>
            <div
              className='d-flex flex-column gap-3 flex-grow-1 overflow-auto'
              style={{ maxWidth: '880px' }}
            >
              <div className='w-100'>
                <TextField.Root
                  size={'3'}
                  placeholder={'Search Chat'}
                  className={`form-control h-px-40 w-100 control-border-color `}
                  onChange={(e: any) => {
                    this.setState({ Search: e.target.value });
                    this.handleChangeDiscussionSearch(e);
                  }}
                ></TextField.Root>
              </div>

              <div className='d-flex gap-4 dashboard-section overviwe align-items-center ps-0 overflow-auto'>
                <TabButtons
                  notificationCategory={this.state.notificationCategory}
                  handleCategoryNotificationSearch={(e: any) =>
                    this.handleCategoryNotificationSearch(e)
                  }
                />
              </div>
            </div>

            <div className='flex-shrink-0 d-flex flex-column gap-3' style={{ width: '556px' }}>
              <div className='d-flex justify-content-end'>
                <AddNewButton
                  hasIcon={true}
                  onClick={() => {
                    if (this.state.sectionId) {
                      this.props.history.push(
                        $ReplaceRoute(
                          `${AppRoutes.discussion_new}?topicId=${this.state.topicId}&sectionId=${this.state.sectionId}&taskId=${this.state.taskId}&researchId=${this.state.selectedResearchId.value}`
                        )
                      );
                    } else {
                      this.props.history.push($ReplaceRoute(`${AppRoutes.discussion_new}`));
                    }
                  }}
                >
                  Add New
                </AddNewButton>
              </div>
              <div className='d-flex flex-column gap-2 flex-sm-row align-items-sm-center'>
                <h2 className='fw-normal flex-shrink-0 fs-15 mb-0 color-gray-600'>
                  Filter by project :
                </h2>

                <div className='position-relative flex-1 discussion-filter-by-project'>
                  <DashboardProjectDropdown
                    id={1}
                    items={this.state.researhList}
                    onSelect={(e) => {
                      this.handleChangeProject(e);
                    }}
                    selectedItems={this.state.selectedResearchId}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='p-3 pt-0 '>
            {this.state.topicId || this.state.sectionId ? (
              <div className='d-flex mt-px-10'>
                <ul className='d-flex align-items-center gap-3 list-unstyled fw-normal'>
                  <li
                    className='d-flex justify-content-between align-items-center badge text-white py-px-2 rounded-pill fs-13 fw-normal pe-3 ps-1 bg-team w-100 mb-0'
                    style={{
                      marginBottom: '3px',
                      borderRadius: 'var(--round, 999px) !important',
                      border: '1px solid var(--Neutral-6, #DDDDE3)',
                      background: 'var(--Neutral-3, #F2F2F5) !important',
                    }}
                  >
                    <span
                      className='text-truncate col-11 ps-xxl-2'
                      style={{
                        fontFamily: 'Sanfransisco',
                        textAlign: 'start',
                        color: 'var(--Neutral-11, #60646C)',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: 510,
                        lineHeight: '160%',
                      }}
                    >
                      {this.state.topicName} : {this.state.topicTitle}
                    </span>
                    <button
                      className='darkCircleIcon CircleIcon'
                      style={{
                        padding: '0px',
                        cursor: 'pointer',
                        display: 'contents',
                      }}
                      onClick={() => {
                        this.setState({
                          topicId: '',
                          sectionId: '',
                          notificationCategory: TopicType.All,
                        });
                        const currentUrl = window.location.href;
                        const url = new URL(currentUrl);
                        url.searchParams.delete('sectionId');
                        url.searchParams.delete('topicId');
                        url.searchParams.delete('title');
                        window.history.pushState({}, '', url.toString());
                        this.GetDiscusstion('', this.state.projectSelected, TopicType.All, '', '');
                      }}
                    >
                      <img src='/images/icons/cross-1.svg' alt='radvix' width='15' height='15' />
                    </button>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>

        <div className='d-flex position-relative border-top border-gray-400'>
          <div className={`chate__history ${this.state.toggleChat ? 'chate__history-hide' : ''}`}>
            <div className='d-xl-none'>
              <div className='p-3 border-bottom border-gray-400'>
                <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Communications</h2>
              </div>

              <div className='p-3 pb-0'>
                <ChatEmailSlackTabs
                  selectedCommunicationTab={this.state.selectedCommunicationTab}
                  setSelectedTab={(selectedTab: number) =>
                    this.setState({
                      selectedTab: selectedTab,
                    })
                  }
                />
              </div>
            </div>

            <div className='tab-content' id='pills-tabContent'>
              <div
                className='tab-pane fade show active'
                id='pills-notes'
                role='tabpanel'
                aria-labelledby='pills-notes-tab'
              >
                <div className='tab-content' id='pills-tabContent'>
                  {this.state.selectedCommunicationTab === 0 && (
                    <div
                      className='tab-pane fade show active'
                      id='pills-notes'
                      role='tabpanel'
                      aria-labelledby='pills-notes-tab'
                    >
                      <div className='p-3 pb-0 d-xl-none'>
                        <div className='align-items-center gap-px-10 flex-wrap width-100-small'>
                          <h2
                            className='fw-normal fs-15 mb-0 color-gray-600 '
                            style={{ paddingBottom: '9px' }}
                          >
                            Filter by project :
                          </h2>
                          <div className='d-flex flex-wrap align-items-center gap-px-10'>
                            <div className='position-relative flex-grow-1 discussion-filter-by-project'>
                              <DashboardProjectDropdown
                                id={1}
                                items={this.state.researhList}
                                onSelect={(e) => {
                                  this.handleChangeProject(e);
                                }}
                                selectedItems={this.state.selectedResearchId}
                              />
                            </div>
                            <div className='d-flex gap-px-10'>
                              <MainButton
                                className='btn btn-primary btn-sm fs-13 px-2 h-px-40 w-px-40 min-w-px-40 py30'
                                svg={
                                  this.state.showSearchInput == false ? (
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='15'
                                      height='16'
                                      viewBox='0 0 15 16'
                                      fill='none'
                                    >
                                      <rect
                                        width='15'
                                        height='15'
                                        transform='translate(0 0.5)'
                                        fill='white'
                                        fill-opacity='0.01'
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M10 7C10 8.933 8.433 10.5 6.5 10.5C4.567 10.5 3 8.933 3 7C3 5.067 4.567 3.5 6.5 3.5C8.433 3.5 10 5.067 10 7ZM9.30884 10.5159C8.53901 11.1318 7.56251 11.5 6.5 11.5C4.01472 11.5 2 9.48528 2 7C2 4.51472 4.01472 2.5 6.5 2.5C8.98528 2.5 11 4.51472 11 7C11 8.06251 10.6318 9.03901 10.0159 9.80884L12.8536 12.6464C13.0488 12.8417 13.0488 13.1583 12.8536 13.3536C12.6583 13.5488 12.3417 13.5488 12.1464 13.3536L9.30884 10.5159Z'
                                        fill='white'
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='30'
                                      height='31'
                                      viewBox='0 0 30 31'
                                      fill='none'
                                    >
                                      <rect
                                        width='30'
                                        height='30'
                                        transform='translate(0 0.5)'
                                        fill='white'
                                        fill-opacity='0.01'
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M23.5632 8.56277C24.0124 8.11367 24.0124 7.38553 23.5632 6.93643C23.1142 6.48733 22.386 6.48733 21.937 6.93643L15.0001 13.8733L8.06328 6.93643C7.61416 6.48733 6.88602 6.48733 6.43692 6.93643C5.98782 7.38553 5.98782 8.11367 6.43692 8.56277L13.3738 15.4996L6.43692 22.4364C5.98782 22.8856 5.98782 23.6136 6.43692 24.0628C6.88602 24.5118 7.61416 24.5118 8.06328 24.0628L15.0001 17.126L21.937 24.0628C22.386 24.5118 23.1142 24.5118 23.5632 24.0628C24.0124 23.6136 24.0124 22.8856 23.5632 22.4364L16.6264 15.4996L23.5632 8.56277Z'
                                        fill='white'
                                      />
                                    </svg>
                                  )
                                }
                                onClick={this.handlerToggleSearch}
                                // loading={this.state.loading || this.state.searchLoading}
                                // disabled={this.state.loading || this.state.searchLoading}
                              ></MainButton>

                              <AddNewButton
                                onClick={() => {
                                  if (this.state.sectionId) {
                                    this.props.history.push(
                                      $ReplaceRoute(
                                        `${AppRoutes.discussion_new}?topicId=${this.state.topicId}&sectionId=${this.state.sectionId}&taskId=${this.state.taskId}&researchId=${this.state.selectedResearchId.value}`
                                      )
                                    );
                                  } else {
                                    this.props.history.push(
                                      $ReplaceRoute(`${AppRoutes.discussion_new}`)
                                    );
                                  }
                                }}
                                hasIcon={true}
                              >
                                Add New
                              </AddNewButton>
                            </div>
                          </div>
                        </div>
                        {this.state.showSearchInput && (
                          <div className='mt-3'>
                            <TextField.Root
                              size={'3'}
                              placeholder={'Search Chat'}
                              className={`form-control h-px-40 w-100 control-border-color `}
                              onChange={(e: any) => {
                                this.setState({ Search: e.target.value });
                                this.handleChangeDiscussionSearch(e);
                              }}
                            ></TextField.Root>
                          </div>
                        )}

                        {this.state.topicId || this.state.sectionId ? (
                          <div style={{ paddingTop: '10px' }}>
                            <ul className='d-flex list-unstyled fw-normal'>
                              <li
                                className='d-flex justify-content-between align-items-center badge text-white py-px-6 rounded-pill fs-13 fw-normal px-3 bg-team w-100'
                                style={{
                                  marginBottom: '3px',
                                  borderRadius: 'var(--round, 999px) !important',
                                  border: '1px solid var(--Neutral-6, #DDDDE3)',
                                  background: 'var(--Neutral-3, #F2F2F5) !important',
                                }}
                              >
                                <span
                                  className='text-truncate col-11 ps-xxl-2'
                                  style={{
                                    fontFamily: 'Sanfransisco',
                                    textAlign: 'start',
                                    color: 'var(--Neutral-11, #60646C)',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: 510,
                                    lineHeight: '160%',
                                  }}
                                >
                                  {this.state.topicName} : {this.state.topicTitle}
                                </span>
                                <button
                                  className='darkCircleIcon CircleIcon'
                                  style={{
                                    padding: '0px',
                                    cursor: 'pointer',
                                    display: 'contents',
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      topicId: '',
                                      sectionId: '',
                                      notificationCategory: TopicType.All,
                                    });
                                    const currentUrl = window.location.href;
                                    const url = new URL(currentUrl);
                                    url.searchParams.delete('sectionId');
                                    url.searchParams.delete('topicId');
                                    url.searchParams.delete('title');
                                    window.history.pushState({}, '', url.toString());
                                    this.GetDiscusstion(
                                      '',
                                      this.state.projectSelected,
                                      TopicType.All,
                                      '',
                                      ''
                                    );
                                  }}
                                >
                                  <img
                                    src='/images/icons/cross-1.svg'
                                    alt='radvix'
                                    width='15'
                                    height='15'
                                  />
                                </button>
                              </li>
                            </ul>
                          </div>
                        ) : null}

                        <div className='row flex-nowrap scrollmenu my-3'>
                          <div className='dashboardProjectDetail dashboard-section overviwe align-items-center ps-0'>
                            <TabButtons
                              notificationCategory={this.state.notificationCategory}
                              handleCategoryNotificationSearch={(e: any) =>
                                this.handleCategoryNotificationSearch(e)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.selectedCommunicationTab === 1 && <div>Email</div>}
                  {this.state.selectedCommunicationTab === 2 && <div>Slack</div>}
                </div>
              </div>

              <div className='ps-3 overflow-auto vh-calc-lg-392'>
                <div className='tab-content' id='pills-tabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='pills-all'
                    role='tabpanel'
                    aria-labelledby='pills-all-tab'
                  >
                    <div>
                      <div className='table-responsive pe-3 table-sticky-header vh-calc-lg-270 vh-calc-sm-336 vh-calc-415'>
                        {this.state.loading ? (
                          <>
                            <Skeleton width='100%' height='1.25em' style={{ marginTop: '30px' }} />
                            <>
                              <Skeleton
                                width='100%'
                                height='1.25em'
                                style={{ marginTop: '30px' }}
                              />
                            </>
                            <Skeleton width='100%' height='1.25em' style={{ marginTop: '30px' }} />
                          </>
                        ) : (
                          <span>
                            {this.state.DiscussionList && this.state.DiscussionList.length > 0 ? (
                              discussionList
                            ) : (
                              <div></div>
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`chate__area ${this.state.toggleChat ? '' : 'chate__area-hide'}`}>
            {this.state.selectedDiscussion != null || this.state.chatLoading == true ? (
              <>
                <div className='px-3 py-4 border-bottom border-gray-400 position-relative'>
                  <div className='d-flex flex-wrap gap-3'>
                    <div className='d-sm-flex align-items-center gap-2'>
                      <div className='d-flex justify-content-between align-items-center row-gap-3 flex-wrap column-gap-3 position-relative'>
                        <BaseButton
                          className='d-flex align-items-center btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-xl-none'
                          style={{ fontWeight: '400', height: '19px' }}
                          onClick={() => {
                            this.setState({ toggleChat: false });
                          }}
                        >
                          Back
                        </BaseButton>
                        <h2 className='fw-medium fs-15 mb-0 color-gray-1000 d-none d-xl-block'>
                          {this.state.chatLoading == true ? (
                            <Skeleton width='100%' height='1.25em' style={{ marginTop: '30px' }} />
                          ) : (
                            <span>Chat Topic: {this.state.selectedDiscussion.subject}</span>
                          )}
                        </h2>
                      </div>
                    </div>

                    <div className='flex-grow-1  text-end d-flex justify-content-end'>
                      <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                        <label
                          className='fw-normal fs-15 mb-0 color-gray-600 me-2'
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          Priority:
                        </label>
                        <div className='d-flex flex-wrap align-items-center row-gap-sm-px-0 row-gap-px-12'>
                          <div className='pe-3 min-h-px-25'>
                            <div className='d-flex align-items-center'>
                              {this.state.chatLoading == true ? (
                                <Skeleton
                                  width='100%'
                                  height='1.25em'
                                  style={{ marginTop: '30px' }}
                                />
                              ) : (
                                this.renderPrioirty(this.state.selectedDiscussion.priority)
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='d-flex align-items-center justify-content-end'
                        style={{ paddingRight: '15px' }}
                      >
                        <label className='fw-normal fs-15 mb-0 color-gray-600 me-2'>User:</label>
                        <span className='px-1'>
                          {this.state.chatLoading == true ? (
                            <Skeleton width='100%' height='1.25em' style={{ marginTop: '30px' }} />
                          ) : (
                            <GroupedAvatars
                              containerWidth={10}
                              maxAvatars={3}
                              tippyContent={
                                <div className='d-flex flex-column py-2 pb-0'>
                                  {this.state.selectedDiscussion.users.map((_us: any) => {
                                    return (
                                      <UserWithAvatar
                                        parentStyle={{
                                          marginBottom: '0.25em',
                                        }}
                                        key={_us.id}
                                        user={_us}
                                      />
                                    );
                                  })}
                                </div>
                              }
                              users={this.state.selectedDiscussion.users}
                            />
                          )}
                        </span>
                      </div>
                      <span className='d-flex align-items-center me-1'>
                        {' '}
                        {/* Changed dNone dFlexSm to d-flex to ensure visibility */}
                        <Tippy
                          maxWidth={320}
                          content={
                            <AddMemberContent
                              currentMemebers={this.state.Discusstion.users}
                              onSave={(_selectedMembers) => {
                                if (_selectedMembers.length === 0) return;
                                const _removedMembers = this.state.Discusstion.users.filter(
                                  (_mem) => {
                                    return (
                                      _selectedMembers.findIndex(
                                        (s_mem) => s_mem.id === _mem.id
                                      ) === -1
                                    );
                                  }
                                );

                                const _addedMembers = _selectedMembers.filter((s_mem) => {
                                  return (
                                    this.state.Discusstion.users.findIndex(
                                      (_mem) => _mem.id === s_mem.id
                                    ) === -1
                                  );
                                });
                                this.handleUpdateDiscussion(
                                  _addedMembers.map((_u) => _u.id),
                                  _removedMembers.map((_u) => _u.id)
                                ).then((_res) => {
                                  this.getDiscustionPanel(this.state.selectedDiscussion.id);
                                  this.setState({
                                    showAddMemberPhone: false,
                                  });
                                });
                              }}
                              allMembers={this.state.allMembers}
                              onClose={() => {
                                this.setState({
                                  showAddMemberPhone: false,
                                });
                              }}
                              updatingDiscuss={this.state.updatingDiscuss}
                              creatorId={this.state.Discusstion.creatorUserId}
                            />
                          }
                          interactive={true}
                          arrow={false}
                          placement='bottom'
                          visible={this.state.showAddMemberPhone}
                          onClickOutside={() => {
                            this.setState({
                              showAddMemberPhone: false,
                            });
                          }}
                        >
                          <span>
                            <MainButton
                              loading={this.state.getMembersLoading || this.state.chatLoading}
                              disabled={this.state.getMembersLoading || this.state.chatLoading}
                              children='+'
                              type={MainButtonType.dark}
                              fontSize='14px'
                              minHeight='2em'
                              className='btn btn-primary fs-15 py-px-7'
                              minWidth='100px'
                              onClick={() => {
                                if (this.state.allMembers.length > 0) {
                                  this.setState({
                                    showAddMemberPhone: true,
                                  });
                                  return;
                                }
                                this.handleGetSectionUsers(
                                  this.state.selectedDiscussion.topic,
                                  this.state.selectedDiscussion.id
                                ).then((_res: any) => {
                                  this.setState({
                                    showAddMemberPhone: true,
                                    allMembers: _res?.members,
                                  });
                                });
                              }}
                            />
                          </span>
                        </Tippy>
                      </span>
                    </div>
                  </div>

                  <h2 className='fw-medium mt-3 fs-15 mb-0 color-gray-1000 d-xl-none'>
                    {this.state.chatLoading == true ? (
                      <Skeleton width='100%' height='1.25em' style={{ marginTop: '30px' }} />
                    ) : (
                      <span>Chat Topic: {this.state.selectedDiscussion.subject}</span>
                    )}
                  </h2>
                </div>

                <div
                  className={`p-3 overflow-auto flex-grow-1 ${
                    this.state.showAttachments ? 'vh-calc-lg-415-attachment' : 'vh-calc-lg-415'
                  }`}
                >
                  {/* <div className="mb-3">
                  <div className="p-3 bg-gray-200 rounded-px-6 my-px-6 max-w-rem-32">
                    <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-2">
                      <div className="d-flex align-items-center text-nowrap fs-15">
                        <div className="avatar w-px-25 h-px-25 rounded-circle bg-primary">
                          <label className="fw-medium text-white fs-10">
                            NH
                          </label>
                        </div>{" "}
                        Nima Hosseinzadeh
                      </div>
                      <h6 className="fs-13 color-gray-500 fw-normal">
                        27 May, 2024 | 08:32 AM
                      </h6>
                    </div>
                    <p className="color-gray-600 fw-medium fs-15">
                      {" "}
                      A short introductory chapter describes the progress in
                      this field in general and stresses the importance of
                      chirality in natural products chemistry{" "}
                    </p>
                    <div className="bg-white rounded-px-6 d-flex border border-gray-300">
                      <div className="d-flex align-items-center gap-3 p-2 justify-content-between w-100">
                        <div className="d-flex align-items-center">
                          <h6 className="fs-13 fw-medium color-gray-1000 mb-0 text-truncate">
                            Data_Set_234.xls
                          </h6>
                          <h6 className="fs-13 fw-medium color-gray-1000 mb-0 ms-1">
                            23MB
                          </h6>
                        </div>
                        <button className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0">
                          <div className="d-flex align-items-center">
                            <i className="fa fa-eye me-px-6 mt-px-1"></i> Open
                            file{" "}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
                  {selectedDiscussion}
                </div>
                <div className='py-3 px-3 border-top border-gray-400'>
                  <div className='flex-sm-row flex-column align-items-center gap-3'>
                    <div className='d-flex align-items-center gap-3 w-100'>
                      <button
                        className='btn btn-secondary btn-sm fs-13 h-px-35 min-w-px-35 p-0 d-flex align-items-center justify-content-center'
                        onClick={() => {
                          this.setState({
                            showAttachments: !this.state.showAttachments,
                          });
                        }}
                      >
                        {!this.state.showAttachments ? (
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 11 13'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M1.5 1.00006C1.22386 1.00006 1 1.22392 1 1.50006V11.5001C1 11.7762 1.22386 12.0001 1.5 12.0001H9.5C9.7761 12.0001 10
                                        11.7762 10 11.5001V3.70717L7.29289 1.00006H1.5ZM0 1.50006C0 0.671631 0.67157 6.10352e-05 1.5 6.10352e-05H7.5C7.63261
                                        6.10352e-05 7.75979 0.0527411 7.85355 0.146511L10.7803 3.07328C10.921 3.21394 11 3.4047 11 3.60361V11.5001C11 12.3285
                                        10.3284 13.0001 9.5 13.0001H1.5C0.67157 13.0001 0 12.3285 0 11.5001V1.50006ZM2.75 6.50006C2.75 6.22392 2.97386 6.00006
                                        3.25 6.00006H5V4.25006C5 3.97392 5.22386 3.75006 5.5 3.75006C5.77614 3.75006 6 3.97392 6 4.25006V6.00006H7.75C8.0261
                                        6.00006 8.25 6.22392 8.25 6.50006C8.25 6.7762 8.0261 7.00006 7.75 7.00006H6V8.75006C6 9.02616 5.77614 9.25006 5.5
                                        9.25006C5.22386 9.25006 5 9.02616 5 8.75006V7.00006H3.25C2.97386 7.00006 2.75 6.7762 2.75 6.50006Z'
                              fill='black'
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='19'
                            height='19'
                            viewBox='0 0 19 19'
                            fill='none'
                          >
                            <rect
                              width='18.2'
                              height='18.2'
                              transform='translate(0.400002 0.400024)'
                              fill='white'
                              fill-opacity='0.01'
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M14.6951 5.29169C14.9676 5.01924 14.9676 4.5775 14.6951 4.30505C14.4227 4.03259 13.9809 4.03259 13.7085 4.30505L9.50014 8.5134L5.29181 4.30505C5.01934 4.03259 4.5776 4.03259 4.30515 4.30505C4.03269 4.5775 4.03269 5.01924 4.30515 5.29169L8.5135 9.50004L4.30515 13.7084C4.03269 13.9809 4.03269 14.4226 4.30515 14.6951C4.5776 14.9675 5.01934 14.9675 5.29181 14.6951L9.50014 10.4867L13.7085 14.6951C13.9809 14.9675 14.4227 14.9675 14.6951 14.6951C14.9676 14.4226 14.9676 13.9809 14.6951 13.7084L10.4868 9.50004L14.6951 5.29169Z'
                              fill='#60646C'
                            />
                          </svg>
                        )}
                      </button>
                      <div className='row mt-1' style={{ width: `100%` }}>
                        <div className={`col-lg-12'}`}>
                          <div className='text-primary cursor-pointer mb-1' title='Info'>
                            <Fragment>
                              <div>
                                <TextField.Root
                                  size={'3'}
                                  value={this.state.massage}
                                  placeholder={'Type your message here'}
                                  className={`form-control h-px-35 w-100 control-border-color`}
                                  //className={`${IsclassName} InputComponentStyle form-control ${IsinValid}`}
                                  onKeyDown={this.handleKeyDown}
                                  onChange={(e: any) => {
                                    const sanitizedValue = e.target.value.replace(/[<>]/g, '');
                                    this.handleChange('massage', sanitizedValue);
                                  }}
                                ></TextField.Root>
                              </div>
                            </Fragment>
                          </div>
                        </div>
                      </div>
                      <MainButton
                        icon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='15'
                            height='15'
                            viewBox='0 0 15 15'
                            fill='none'
                          >
                            <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M1.20308 1.04312C1.00481 0.954998 0.772341 1.0048 0.627577 1.16641C0.482813 1.32802 0.458794 1.56455 0.568117 1.75196L3.92115 7.50002L0.568117 13.2481C0.458794 13.4355 0.482813 13.672 0.627577 13.8336C0.772341 13.9952 1.00481 14.045 1.20308 13.9569L14.7031 7.95693C14.8836 7.87668 15 7.69762 15 7.50002C15 7.30243 14.8836 7.12337 14.7031 7.04312L1.20308 1.04312ZM4.84553 7.10002L2.21234 2.586L13.2689 7.50002L2.21234 12.414L4.84552 7.90002H9C9.22092 7.90002 9.4 7.72094 9.4 7.50002C9.4 7.27911 9.22092 7.10002 9 7.10002H4.84553Z'
                              fill='white'
                            />
                          </svg>
                        }
                        children='Send'
                        type={MainButtonType.dark}
                        //borderRadius="24px"
                        fontSize='14px'
                        // className="my-2 mx-2"
                        minHeight='2em'
                        className='btn btn-primary fs-15 py-px-7'
                        minWidth='100px'
                        onClick={() => {
                          this.SendMassage();
                        }}
                      ></MainButton>
                    </div>
                    {this.state.showAttachments === true && (
                      <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center mb-1'>
                          <label className='color-gray-600 fs-15 me-2'>Attach files or links</label>
                          <img
                            className='text-primary cursor-pointer'
                            src='/Images/icons/info-circled.svg'
                            alt='info'
                            title='Research attachments are generic documents that you would like anyone who has access to this research project read and have access to them. You can either upload them or Insert the link.'
                          />
                        </div>
                        <div className='p-px-12 bg-gray-200 border-gray-400 border rounded-px-4 gap-2 attachment-paddingleft'>
                          <div className='justify-content-sm-between justify-content-center align-items-center flex-wrap row-gap-px-8'>
                            <Dropzone onDrop={this.onDrop}>
                              {({ getRootProps, getInputProps }) => (
                                // <section
                                //   className="container fileUploadBox"
                                //   style={{ marginTop: "0.3em" }}
                                // >
                                <div {...getRootProps({ className: '' })}>
                                  <input {...getInputProps()} />

                                  <div className='d-flex justify-content-between align-items-center flex-wrap row-gap-px-8 responsive-justify-content'>
                                    <button className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'>
                                      <div className='d-flex align-items-center'>
                                        <div className='me-1 mb-1'>
                                          <svg
                                            width='15'
                                            height='15'
                                            viewBox='0 0 15 15'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                          >
                                            <rect
                                              width='15'
                                              height='15'
                                              fill='white'
                                              fill-opacity='0.01'
                                            />
                                            <path
                                              fill-rule='evenodd'
                                              clip-rule='evenodd'
                                              d='M7.81825 1.18197C7.64251 1.00624 7.35759 1.00624 7.18185 1.18197L4.18185 4.18197C4.00611 4.35771 4.00611 4.64263
              4.18185 4.81837C4.35759 4.9941 4.64251 4.9941 4.81825 4.81837L7.05005 2.58657V9.50005C7.05005 9.74858 7.25152 9.95005
              7.50005 9.95005C7.74858 9.95005 7.95005 9.74858 7.95005 9.50005V2.58657L10.1819 4.81837C10.3576 4.9941 10.6425 4.9941
              10.8182 4.81837C10.994 4.64263 10.994 4.35771 10.8182 4.18197L7.81825 1.18197ZM2.5 10.0001C2.77614 10.0001 3 10.2239 3
              10.5001V12.0001C3 12.5539 3.44565 13.0001 3.99635 13.0001H11.0012C11.5529 13.0001 12 12.5529 12 12.0001V10.5001C12
              10.2239 12.2239 10.0001 12.5 10.0001C12.7761 10.0001 13 10.2239 13 10.5001V12.0001C13 13.1041 12.1062 14.0001 11.0012
              14.0001H3.99635C2.89019 14.0001 2 13.1031 2 12.0001V10.5001C2 10.2239 2.22386 10.0001 2.5 10.0001Z'
                                              fill='currentColor'
                                            />
                                          </svg>
                                        </div>
                                        Browse local file
                                      </div>
                                    </button>
                                    <h3 className='fw-normal color-gray-700 fs-13 mb-0 flex-grow-1 text-center'>
                                      {' '}
                                      Or drag and drop files here - Max 128MB{' '}
                                    </h3>
                                  </div>
                                  {files.length > 0 && (
                                    <div style={{ paddingTop: '10px' }}>
                                      <ul className='list-unstyled fw-normal'>{files}</ul>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>

                        <ul
                          className='file-list mt-1'
                          style={{
                            marginBottom: this.state.ExternalUrl.length > 0 ? '0px' : '0px',
                          }}
                        >
                          {this.state.ExternalUrl.map((item) => (
                            <li className='d-flex align-items-center w-100'>
                              <InputComponent
                                type={InputType.text}
                                value={item}
                                className='mx-2'
                                counter={false}
                                disabled={true}
                                readOnly={true}
                                rowWidth='98%'
                                isExternalURL={true}
                              ></InputComponent>
                              <div
                                style={{
                                  paddingBottom: '0px',
                                  paddingRight: '0px',
                                  paddingTop: '0px',
                                  paddingLeft: '5px',
                                }}
                              >
                                <button
                                  className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                                  style={{ background: '#F3AEAF' }}
                                  onClick={() => {
                                    this.handelDeleteExternalLink(item);
                                  }}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='15'
                                    height='15'
                                    viewBox='0 0 15 15'
                                    fill='none'
                                  >
                                    <rect width='15' height='15' fill='white' fill-opacity='0.01' />
                                    <path
                                      fill-rule='evenodd'
                                      clip-rule='evenodd'
                                      d='M5.5 1.00009C5.22386 1.00009 5 1.22395 5 1.50009C5 1.77623 5.22386 2.00009 5.5 2.00009H9.5C9.77614 2.00009 10 1.77623 10 1.50009C10 1.22395 9.77614 1.00009 9.5 1.00009H5.5ZM3 3.50009C3 3.22395 3.22386 3.00009 3.5 3.00009H5H10H11.5C11.7761 3.00009 12 3.22395 12 3.50009C12 3.77623 11.7761 4.00009 11.5 4.00009H11V12.0001C11 12.5524 10.5523 13.0001 10 13.0001H5C4.44772 13.0001 4 12.5524 4 12.0001V4.00009H3.5C3.22386 4.00009 3 3.77623 3 3.50009ZM5 4.00009H10V12.0001H5V4.00009Z'
                                      fill='#D93D42'
                                    />
                                  </svg>
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className='d-flex'>
                          <InputComponent
                            type={InputType.text}
                            placeholder='https://'
                            className='control-border-color'
                            value={this.state.External}
                            counter={false}
                            onChange={(e) => {
                              this.handleChange('External', e.target.value);
                            }}
                            rowWidth='98%'
                            onEnter={(e) => {
                              this.addExternalUrl();
                            }}
                          ></InputComponent>
                          <div style={{ padding: '5px', paddingRight: '0px' }}>
                            <button
                              className='btn btn-soft-primary btn-sm fs-13 h-px-35 min-w-px-35 p-0'
                              onClick={() => {
                                this.addExternalUrl();
                              }}
                            >
                              <i className='fa fa-plus'></i>
                            </button>
                          </div>
                        </div>
                        <p className='text-error mb-0'>
                          {this.validator.message('input', this.state.External, 'url')}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <NotSelectedMessage loading={this.state.chatLoading} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DiscusstionPage);
