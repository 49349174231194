import { HTTP, handleUnauthorizedRequest } from '../../../core/http_common';
import { SortType } from '../../../core/number_extentions';
import { ExpenseStatus } from '../../../core/utils';
import { CreateExpensesReq } from '../../models/requests/financials/create_expense_req';
import { EditExpensesReq } from '../../models/requests/financials/update_expense_req';
import { CreateExpensesRes } from '../../models/responses/financials/expenses/create_expenses_res';
import { CreateStateExpensesRes } from '../../models/responses/financials/expenses/create_state_expenses_res';
import { GetExpensesByID } from '../../models/responses/financials/expenses/expenses_by_id_res';
import { GetAllExpensesResult } from '../../models/responses/financials/expenses/expenses_res';
import { SearchExpensesRes } from '../../models/responses/financials/expenses/search_expenses_res';
import { EditExpensesRes } from '../../models/responses/financials/expenses/update_publish_res';

export class RemoteExpenses {
  createExpense(
    body: CreateExpensesReq,
    action: (res: CreateExpensesRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post('/Expense', body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  SearchExpense(
    body: { researchId: number },
    action: (res: SearchExpensesRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Expense/Search?researchId=${body.researchId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getExpenses(
    body: {
      grantId: number;
      PageNumber: number;
      PageSize: number;
      ResearchId: number;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
      statusOption?: any[] | null;
      startExpense?: Date | null;
      endExpense?:Date | null;
    },
    action: (res: GetAllExpensesResult) => any,
    error: (res: any) => any
  ) {
    const ColumnName = body.columnHeaderName ? body.columnHeaderName : '';
    const sort = body.sortDirection ? body.sortDirection : SortType.AutoSelect;
    const status = Array.isArray(body?.statusOption) ? body.statusOption : [];
    const StatusId = status.map((obj: { value: any }) => obj?.value || null);
    const statusFilter = StatusId.length > 1 ? null : StatusId[0];
    const FromDate = body?.startExpense    
    ? body.startExpense.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    : "";
  const ToDate = body?.endExpense
    ? body.endExpense.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    : "";
    return HTTP.get(
      `/Expense?PageNumber=${body.PageNumber}&PageSize=${body.PageSize}&GrantId=${body.grantId}&ResearchId=${
        body.ResearchId
      }&SearchParameter=${body.SearchParameter}&ColumnName=${ColumnName}
      &SortDirection=${sort}
      ${
        FromDate !== null ? `&SelectedFromDate=${FromDate}` : ''
      }
      ${
        ToDate !== null ? `&SelectedToDate=${ToDate}` : ''
      }
      ${
        statusFilter !== null ? `&Status=${statusFilter}` : ''
      }`
    )
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  createState(
    body: { expenseId: number; expenseStatus: ExpenseStatus },
    action: (res: CreateStateExpensesRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.post(`/Expense/State/?expenseId=${body.expenseId}&ExpenseStatus=${body.expenseStatus}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  getExpenseById(
    body: { id: number },
    action: (res: GetExpensesByID) => any,
    error: (res: any) => any
  ) {
    return HTTP.get(`/Expense/${body.id}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
  updateExpense(
    body: EditExpensesReq,
    action: (res: EditExpensesRes) => any,
    error: (res: any) => any
  ) {
    return HTTP.put('/Expense', body)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }

  deleteExpense(body: { expenseId: number }, action: (res: any) => any, error: (res: any) => any) {
    return HTTP.delete(`/Expense?expenseId=${body.expenseId}`)
      .then((res) => action(res.data))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Retry the request with refreshed token
          return handleUnauthorizedRequest(err.config)
            .then((_res) => {
              action(_res?.data);
            })
            .catch((e) => error(e));
        } else {
          error(err);
        }
      });
  }
}
