import React from 'react';
import { store } from '../../../data/storeMain';
import { InputComponent, InputType } from '../../components/inputs';
import 'react-datepicker/dist/react-datepicker.css';
import { MainButton } from '../../components/button';
import SimpleReactValidator from 'simple-react-validator';
import { MemberController } from '../../../controllers/member/member_controller';
import { RouteComponentProps, withRouter } from 'react-router';
import { LocalDataSources } from '../../../data/local_datasources';
import {
  UserRoles,
} from '../../../core/utils';
import { UserController } from '../../../controllers/user/user_controller';
import { Checkbox } from '../setting/component/new_notification_tbl';
import DOMPurify from 'dompurify';
import { BaseButton } from '@fluentui/react';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
import { AppRoutes } from '../../../core/constants';
type StateType = {
  firstName: string;
  lastName: string;
  userEmail: string;
  reason: string;
  confirmation: boolean;
  searchLoading: boolean;
};
class DeleteUserAndData extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userInfo?.role as UserRoles;
  UserFirstName = store.getState().userInfo?.firstName;
  UserLastName = store.getState().userInfo?.lastName;
  validator = new SimpleReactValidator({
    className: 'text-danger',
    messages: {
      min: 'This field is required.',
    },
  });
  userController = new UserController();
  local = new LocalDataSources();
  state: StateType = {
    firstName: '',
  lastName: '',
  userEmail: '',
  reason: '',
  confirmation: false,
  searchLoading: false
  };
  componentDidMount() {
    document.title = `Invite User | Radvix`;
    store.dispatch(SetHeaderTitle('Setting'));
    //if (!isTokenInHttpAuth(HTTP)) return;
    // this.setState({
    //   searchLoading: true,
    // });
    
  }
  handleChange(target: string, val: any) {

      const sanitizedValue = DOMPurify.sanitize(val);
      this.setState({
        [target]: sanitizedValue,
      });
  }
  
  handleSendRequest() {
    
      if (this.validator.fieldValid('User Email')) {
        this.SendRequest();
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
    SendRequest() {
        this.setState({
          searchLoading: true,
        });
        const body = {
          reason: this.state.reason,
          confirmation: this.state.confirmation,
          userEmail: this.state.userEmail,
        };
    
        this.userController.sendDeleteRequest(
          body,
          (res) => {debugger;
            this.setState({
              
                searchLoading: false,
            });
            this.props.history.push(AppRoutes.setting)
          },
          () => {debugger;
             this.setState({
              
            searchLoading: false,
        });}
        );
      }

  render() {

    return (
      <div className='card w-100 new-data-card'>
        <div
          className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400'
        >
          <div className='d-flex flex-wrap align-items-center gap-px-12'>
            <BaseButton
              className='btn btn-outline-primary fs-15 py-px-4 px-3 d-sm-block d-none back-button'
              style={{ fontWeight: '400', height: '19px' }}
              onClick={() => {
                window.history.back();
              }}
            >
              Back
            </BaseButton>
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Delete User & Data</h2>
          </div>
        </div>
        <div className='p-3 overflow-auto'>
          <div className='row'>
            <div className=''>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='item'>
                  <InputComponent
                    className='form-control'
                    type={InputType.text}
                    disabled={true}
                    value={this.UserFirstName +" "+ this.UserLastName}
                    label='Full Name'
                    popQuestion='This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page.'
                    
                  ></InputComponent>
                  </div>
                </div>
                <div className='item'>
                  <InputComponent
                    className='form-control'
                    type={InputType.text}
                    disabled={this.state.searchLoading}
                    placeholder='Enter user’s email address'
                    label='Email*'
                    popQuestion='This email will be used to send a secured invitation link to the user. User will be update to change their emails on the registration page.'
                    onChange={(e) => {
                      this.handleChange('userEmail', e.target.value);
                    }}
                    inValid={this.validator.message(
                      'User Email',
                      this.state.userEmail,
                      'required|email'
                    )}
                  ></InputComponent>
                </div>
                <div className='item'>
                  <div className='col-lg-12'>
                    <InputComponent
                      type={InputType.textarea}
                      disabled={this.state.searchLoading}
                      label='Please provide us a brief description of your data removal request*'
                      placeholder='Enter reason of deleting profile'
                      //optional="optional"
                      height='132px'
                      className='mt-1'
                      onChange={(e) => {
                        this.handleChange('reason', e.target.value);
                      }}
                      inValid={this.validator.message(
                        'Reason',
                        this.state.reason,
                        'required|reason'
                      )}
                    ></InputComponent>
                  </div>
                </div>
                <div className='item mb-2' style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={this.state.confirmation}
                    onChange={(e) => {
                      this.setState({ confirmation: !this.state.confirmation });
                    }}
                  />
                  <div style={{ marginLeft: '8px' }}>I confirm to submit my account data removal request for review</div>
                </div>
                
                
              </div>
              {/* <div className="p-3"> */}
              <div style={{ paddingTop: '5px' }}>
                <MainButton
                  className='btn btn-primary fs-15 py-px-7 d-flex align-items-center gap-2 responsive-Button-width'
                  icon={
                    <img
                      src='/images/icons/paper-plane.svg'
                      className='w-px-20'
                      style={{ paddingBottom: '2px' }}
                    />
                  }
                  onClick={() => {
                    this.handleSendRequest();
                  }}
                  loading={this.state.searchLoading}
                  disabled={this.state.searchLoading || !this.state.confirmation}
                >
                  Submit Request
                </MainButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(DeleteUserAndData);
