import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TaskController } from '../../../controllers/task/task_controller';
import { AppTask } from '../../../data/models/responses/task/get_all_tasks_res';
import { store } from '../../../data/storeMain';
import { MainButton } from '../../components/button';
import { InputIcon } from '../../components/search_box';
// import AcordienTable from "./component/recent_tasks"; old
import { RouteComponentProps, withRouter } from 'react-router';
import { AppRoutes, MOMENT_DATE_FORMAT } from '../../../core/constants';
// import { EmptyState } from "../../components/empty_state";
import debounce from 'lodash.debounce';
import { HTTP } from '../../../core/http_common';
import {
  $ReplaceRoute,
  getUserInitails,
  getUserRole,
  isTokenInHttpAuth,
  removeDuplicateObj,
} from '../../../core/utils';
// import NewTable from "../../components/NewTable/NewTable";
import { DropdownMenu, Table } from '@radix-ui/themes';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { ResearchController } from '../../../controllers/research/research_controller';
import { ResearchStatus, SortType } from '../../../core/number_extentions';
import { SetHeaderTitle } from '../../../data/storeMain/actions/header_title_action';
import { SetResearchId } from '../../../data/storeMain/actions/research_action';
import DashboardProjectDropdown from '../../components/ActionComponent/DashboardProjectDropdown';
import { ImageWithToken } from '../../components/box_list_scroll';
import { IFilterOptions } from '../../components/FilterSection/IFilterOptions';
import GroupedAvatars from '../../components/groupedAvatars';
import { IconEnum } from '../../components/inputs';
import Pagination from '../../components/NewRadixTable/RadixPagination';
import RadixTableTasks from '../../components/NewRadixTable/RadixTableTasks';
import MultiSelect from '../../components/SelectRadix/radixMultiSelect';
import UserWithAvatar from '../../components/UserWithAvatar';
import { CheckIcon } from '@radix-ui/react-icons';
import { TaskStatusUpdate } from '../../../data/models/requests/task/update_task_req';
import { toast } from 'react-toastify';
import { UserController } from '../../../controllers/user/user_controller';
type StateType = {
  Tasks: AppTask[];
  PageNumber: number;
  PageSize: { value: number; label: string };
  PageCount: number;
  TotalCount: number;
  Search: string;
  loading: boolean;
  HeaderName: string;
  SortDirection: SortType;
  filterOptions: IFilterOptions;
  showFilterOptions: boolean;
  PageNumberInput: string;
  researhList: any[];
  selectedProject: any;
  ExpandedRowsState: { [key: string]: boolean };
};
let unsubscribeMeData: any = undefined;
class TasksPage extends React.Component<RouteComponentProps> {
  RoleUser = store.getState().userRole;
  controller = new TaskController();
  userController = new UserController();
  researchController = new ResearchController();
  state: StateType = {
    Tasks: [],
    PageNumber: 1,
    PageSize: { value: 10, label: '10' },
    PageCount: 0,
    TotalCount: 0,
    Search: '',
    loading: true,
    HeaderName: '',
    SortDirection: SortType.ASC,
    showFilterOptions: false,
    filterOptions: {},
    PageNumberInput: '',
    researhList: [],
    selectedProject: store.getState().ResearchId,
    ExpandedRowsState: {},
  };

  constructor(props: any) {
    super(props);
    this.handleChangeTaskSearch = debounce(this.handleChangeTaskSearch.bind(this), 500);
    this.handlePageNumberInput = this.handlePageNumberInput.bind(this);
    this.handleGoToPage = this.handleGoToPage.bind(this);
  }

  loadResearches() {
    this.researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: '',
        columnHeaderName: '',
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        let researches: { value: any; label: any }[] = [];
        if (res && res.researchesList && res.researchesList.length > 0) {
          researches = res.researchesList.map(({ id, title }) => ({
            value: id,
            label: title,
          }));
        }

        this.setState({ researhList: researches });
      },
      (err) => {}
    );
  }

  handlePageNumberInput(event: { target: { value: any } }) {
    if (event.target.value > 0) {
      this.setState({ PageNumberInput: event.target.value });
    } else {
      this.setState({ PageNumberInput: '' });
    }
  }
  handleGoToPage() {
    const pageNumber = parseInt(this.state.PageNumberInput, 10);
    if (pageNumber > 0 && pageNumber <= this.state.PageCount) {
      this.handelChangePageNumber({ selected: pageNumber - 1 });
    } else {
      console.log('Invalid page number');
    }
  }
  componentDidMount() {
    document.title = `Task List | Radvix`;
    store.dispatch(SetHeaderTitle('Task'));
    if (!isTokenInHttpAuth(HTTP)) return;
    this.loadResearches();

    // this.GetTasks(
    //   this.state.selectedProject.value,
    //   this.state.PageNumber,
    //   this.state.PageSize.value
    // );

    // unsubscribeMeData = store.subscribe(() => {
    //   this.GetTasks(
    //     this.state.selectedProject.value,
    //     this.state.PageNumber,
    //     this.state.PageSize.value
    //   );
    // });
  }
  // clean up event listener
  componentWillUnmount() {
    // console.log('called removeEvent')
    if (unsubscribeMeData) unsubscribeMeData();
  }
  GetTasks(
    researchId: number,
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      loading: true,
    });

    this.controller.getTasks(
      {
        researchId: researchId,
        PageNumber,
        PageSize,
        SearchParameter: Search ? Search : this.state.Search,
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        if (res === null) {
          this.setState({
            Tasks: [],
            PageCount: 0,
            TotalCount: 0,
            loading: false,
          });
          return;
        }
        const _tasks = res.appTasks.map((_task) => {
          let _taskTeamUsers = _task.appTask.teams.map((_t) => _t?.users).flat();

          const _usersWithTeamUsers = [..._task.appTask.users, ..._taskTeamUsers];

          return {
            appTask: {
              ..._task.appTask,
              users: removeDuplicateObj(_usersWithTeamUsers),
            },
            subAppTasks: _task.subAppTasks.map((_subTask) => {
              let _subtaskTeamUsers = _subTask.teams.map((_t) => _t?.users).flat();

              const _usersWithTeamUsers = [..._subTask.users, ..._subtaskTeamUsers];
              return {
                ..._subTask,
                users: removeDuplicateObj(_usersWithTeamUsers),
                researchId: _subTask.researchId,
              };
            }),
          };
        });

        this.setState({
          Tasks: _tasks,
          PageCount: Math.ceil(res.count! / this.state.PageSize.value),
          TotalCount: res.count,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  handleExpandedRowsChange = (updatedExpandedRows: { [key: string]: boolean }) => {
    this.setState({ ExpandedRowsState: updatedExpandedRows });
    console.log('Expanded Rows State:', updatedExpandedRows);
  };

  handelChangePageNumber(e: { selected: number }) {
    const newPageNumber = e.selected + 1;
    this.setState(
      {
        PageNumber: newPageNumber,
      },
      () => {
        this.GetTasks(
          this.state.selectedProject.value,
          this.state.PageNumber,
          this.state.PageSize.value,
          this.state.Search,
          this.state.HeaderName,
          this.state.SortDirection,
          this.state.filterOptions
        );
      }
    );
  }

  handelChangePageSize(e: { label: string; value: number }) {
    this.setState({
      PageSize: e,
    });
    this.GetTasks(
      this.state.selectedProject.value,
      this.state.PageNumber,
      e.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      this.state.filterOptions
    );
  }
  handleChangeTaskSearch(e: any) {
    const inputValue = e.target.value;
    const sanitizedValue = DOMPurify.sanitize(inputValue);
    this.GetTasks(
      this.state.selectedProject.value,
      this.state.PageNumber,
      this.state.PageSize.value,
      sanitizedValue
    );
  }
  headerClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.setState({ HeaderName: columnHeaderName });
    this.setState({ SortDirection: sortDirection });
    this.GetTasks(
      this.state.selectedProject.value,
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      columnHeaderName,
      sortDirection,
      this.state.filterOptions
    );
  };
  filterClick = (
    selectedFromDate?: Date | null,
    selectedToDate?: Date | null,
    status?: any[] | null,
    teamType?: number[]
  ) => {
    var filterOptions = {
      selectedFromDate: selectedFromDate,
      selectedToDate: selectedToDate,
      status: status,
      teamType: teamType,
    };
    this.setState({ filterOptions: filterOptions });
    this.GetTasks(
      this.state.selectedProject.value,
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      this.state.HeaderName,
      this.state.SortDirection,
      filterOptions
    );
  };
  handleToggle = () => {
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };

  markTaskAsCompleted = (taskId: number, researchId: number) => {
    const body: TaskStatusUpdate = {
      id: taskId,
      researchId: researchId,
    };
    this.controller.markAsCompleted(
      body,
      (res) => {
        if (res) {
          toast.success(res.message);
          this.GetTasks(
            this.state.selectedProject.value,
            this.state.PageNumber,
            this.state.PageSize.value
          );
        } else {
          // Handle the case where the update was not successful
          console.error('Failed to update task status');
        }
      },
      (err) => {
        // Handle errors if the API call fails
        console.error('Error updating task status:', err);
      }
    );
  };

  renderStatus(status: any) {
    if (status == ResearchStatus.OnGoing) {
      return (
        <span className='badge bg-info text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          On Going
        </span>
      );
    }
    if (status == ResearchStatus.Completed) {
      return (
        <span className='badge bg-success text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Completed
        </span>
      );
    }
    if (status == ResearchStatus.Delayed) {
      return (
        <span className='badge bg-warning text-white py-px-6 rounded-pill fs-13 fw-normal px-3'>
          Delayed
        </span>
      );
    }

    if (status == ResearchStatus.OnHold) {
      return (
        <span
          className='badge bg-default text-white py-px-6 rounded-pill fs-13 fw-normal px-3'
          style={{ background: '#7E808A' }}
        >
          On Hold
        </span>
      );
    }
  }
  filterToggle = () => {
    const filteroggleButton = document.getElementById('filterToggler');
    const showFilter = document.getElementById('showFilter');
    const isFilterShown = document.getElementById('isFilterShown');
    showFilter?.classList.toggle('show-filters');
    filteroggleButton?.classList.toggle('filters-hide');
    isFilterShown?.classList.toggle('filters-height');
  };
  statusOptions: any[] = [
    { label: 'All Status', value: -1, svg: '' },
    {
      label: 'Completed',
      value: ResearchStatus.Completed,
      backgroundColor: 'bg-success',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#299764"></circle></svg>',
    },
    {
      label: 'Delayed',
      value: ResearchStatus.Delayed,
      backgroundColor: 'bg-warning',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#ED5F00"></circle></svg>',
    },
    {
      label: 'On Going',
      value: ResearchStatus.OnGoing,
      backgroundColor: 'bg-info',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#0880EA"></circle></svg>',
    },
    {
      label: 'On Hold',
      value: ResearchStatus.OnHold,
      backgroundColor: 'bg-OnHold',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none"><circle cx="5" cy="5.5" r="5" fill="#7E808A"></circle></svg>',
    },
  ];
  handleStatusChange = (selectedStatus: any[]) => {
    const allValue = selectedStatus.find((x) => x.value == -1);
    const value = allValue ? null : selectedStatus;
    this.filterClick(
      this.state.filterOptions.selectedFromDate,
      this.state.filterOptions.selectedToDate,
      value
    );
  };
  handleChangeProject(e: any) {
    this.setState({
      selectedProject: e,
    });
    store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    window.dispatchEvent(
      new CustomEvent('HANDLE_RESEARCH_CHANGED', {
        detail: {
          name: e?.label,
          id: e?.value,
        },
      })
    );
    // if (e && e.value != 0) {
    //   store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    //   window.dispatchEvent(
    //     new CustomEvent('HANDLE_RESEARCH_CHANGED', {
    //       detail: {
    //         name: e?.label,
    //         id: e?.value,
    //       },
    //     })
    //   );
    // }
    this.userController.lastSelectedResearch(
      e.value,
      (res) => {},
      () => {
        // setTimelineLoading(false);
      }
    );
    this.GetTasks(
      e.value,
      this.state.PageNumber,
      this.state.PageSize.value,
      this.state.Search,
      '',
      SortType.AutoSelect
    );
  }
  renderIcon = (value: any) => {
    if (value === IconEnum.Book) {
      return <img src='/images/icons/book.svg' alt='Book Icon' width={20} height={20} />;
    }
    if (value === IconEnum.Calculator) {
      return (
        <img src='/images/icons/calculator.svg' alt='Calculator Icon' width={20} height={20} />
      );
    }
    if (value === IconEnum.Robot) {
      return <img src='/images/icons/robot.svg' alt='Robot Icon' width={20} height={20} />;
    }
    if (value === IconEnum.Bulb) {
      return <img src='/images/icons/bulb.svg' alt='Bulb Icon' width={20} height={20} />;
    }
    if (value === IconEnum.Microscope) {
      return <img src='/images/icons/microscope.svg' alt='Book Icon' width={20} height={20} />;
    } else {
      return <i className='me-2 color-gray-600 fs-13'></i>;
    }
  };
  render() {
    const _userRole = getUserRole();
    return (
      <div className='card'>
        <div className='d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3'>
          <div className='d-flex align-items-center'>
            {/* <button className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none"> Back</button> */}
            <h2 className='fw-medium fs-15 mb-0 color-gray-1000'>Task List</h2>
          </div>
          <MainButton
            className='btn btn-primary fs-15 py-px-7 new-project-button'
            svg={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='15'
                viewBox='0 0 15 15'
                fill='none'
              >
                <rect width='15' height='15' fill='white' fill-opacity='0.01'></rect>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z'
                  fill='white'
                ></path>
              </svg>
            }
            onClick={() => {
              this.props.history.push($ReplaceRoute(`${AppRoutes.task_new}`));
            }}
            // style={{ height: "35px" }}
          >
            Add new task
          </MainButton>
        </div>
        <div className='border-top border-gray-400 border-bottom'>
          <div className='d-flex flex-column gap-2 flex-sm-row align-items-sm-center p-3'>
            <span className='text-nowrap'>Active Projects:</span>
            <DashboardProjectDropdown
              id={1}
              items={this.state.researhList}
              onSelect={(e) => {
                this.handleChangeProject(e);
              }}
              selectedItems={store.getState().ResearchId}
            />
          </div>
        </div>
        <div
          className='d-flex flex-column row-gap-2 p-3 flex-xl-row align-items-xl-center row-gap-xl-0 filters'
          id='showFilter'
        >
          <div className='pe-xl-3 border-end-xl-1 border-gray-400 d-sm-block d-flex gap-3 flex-grow-1 filters-search-wrapper'>
            <InputIcon
              className='form-control h-px-35'
              placeholder='Search task'
              chilren={undefined}
              onChange={(e) => {
                this.setState({
                  Search: e.target.value,
                });
                this.handleChangeTaskSearch(e);
              }}
            />
            <button
              className='btn btn-secondary d-sm-none h-px-35 w-px-35 p-0'
              id='filterToggler'
              onClick={this.filterToggle}
            >
              <img src='/images/icons/mixer-horizontal.svg' />
              <i className='fa fa-times d-none color-gray-1000'></i>
            </button>
          </div>

          <div className='d-sm-flex flex-wrap row-gap-2 sub-filters ps-xl-3'>
            <div className='w-100 w-sm-50 pe-sm-3 border-end-sm-1 border-gray-400 w-xl-auto multi-select-wrapper'>
              <MultiSelect options={this.statusOptions} onChange={this.handleStatusChange} />
            </div>

            <div className='d-flex align-items-center w-100 w-sm-50 w-xl-auto ps-sm-3'>
              <div className='w-50 w-xl-auto date-pickers-wrapper'>
                <div className='date-picker-wrapper'>
                  <DatePicker
                    selected={this.state.filterOptions.selectedFromDate}
                    onChange={(e) => {
                      this.filterClick(
                        e,
                        this.state.filterOptions.selectedToDate,
                        this.state.filterOptions.status
                      );
                    }}
                    dateFormat='MM/dd/yyyy'
                    placeholderText='Start Date'
                    // maxDate={new Date()}
                    className='form-control h-px-35 customBorders date-picker'
                  />
                </div>
              </div>
              <div className='px-2 flex-shrink-0 color-gray-400'>&ndash;</div>
              <div className='w-50 w-xl-auto date-pickers-wrapper'>
                <div className='date-picker-wrapper'>
                  <DatePicker
                    selected={this.state.filterOptions.selectedToDate}
                    onChange={(e) =>
                      this.filterClick(
                        this.state.filterOptions.selectedFromDate,
                        e,
                        this.state.filterOptions.status
                      )
                    }
                    dateFormat='MM/dd/yyyy'
                    placeholderText='End Date'
                    //maxDate={new Date()}
                    className='form-control h-px-35 customBorders date-picker'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <RadixTableTasks
          data={this.state.Tasks}
          loading={this.state.loading}
          markComplete={this.markTaskAsCompleted}
          onHeaderClick={(columnHeaderName: string, sortDirection: SortType) =>
            this.headerClick(columnHeaderName, sortDirection)
          }
          onExpandedRowsChange={this.handleExpandedRowsChange}
          isChecked={true}
          columns={[
            {
              Header: 'Task name',
              accessor: 'title',
              Cell: (_props: any) => {
                return (
                  <Table.Cell
                    className='p-3  parent-row-background'
                    style={{ minWidth: '294px', width: '294px' }}
                  >
                    <div className='line-clamp-2'>
                      {_props.subAppTasks.length > 0 && (
                        <i
                          className={`fa ${
                            this.state.ExpandedRowsState[_props.appTask.id]
                              ? 'fa-chevron-down'
                              : 'fa-chevron-right'
                          } color-gray-600 fs-13`}
                        />
                      )}
                      <span
                        style={{
                          paddingLeft: `${_props.subAppTasks.length > 0 ? '10px' : '0px'}`,
                          display: 'inline-block',
                          whiteSpace: 'nowrap',       
                          overflow: 'hidden',          
                          textOverflow: 'ellipsis',
                          maxWidth: '260px',  
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.task_profile.replace(
                                ':id',
                                _props.appTask.id.toString() ?? ''
                              )}?researchId=${_props.appTask.researchId}`
                            )
                          );
                        }}
                        title={_props.appTask.title}
                      >
                        {' '}
                        {_props.appTask.title}

                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Creator',
              accessor: 'CreatorUser',
              Cell: (props: any) => {
                return (
                  <Table.Cell
                    className='p-3 parent-row-background'
                    style={{ minWidth: '339', width: '339px' }}
                  >
                    <div className='d-flex align-items-center text-nowrap'>
                      {props.appTask.creatorUser.image === null ||
                      props.appTask.creatorUser.image === '' ||
                      props.appTask.creatorUser.image === undefined ? (
                        <div className='avatar w-px-25 h-px-25 rounded-circle bg-primary'>
                          <label className='fw-medium text-white fs-10'>
                            {getUserInitails(
                              `${props.appTask.creatorUser.firstName} ${props.appTask.creatorUser.lastName}`
                            )}
                          </label>
                        </div>
                      ) : (
                        <ImageWithToken
                          originalImage={props.appTask.creatorUser.image}
                          hasImage={props.appTask.creatorUser.image ? true : false}
                          alt='Avatar'
                          className='rounded-circle avatar rounded-avatar-dashboard'
                          src={
                            props.appTask.creatorUser.image === null ||
                            props.appTask.creatorUser.image === '' ||
                            props.appTask.creatorUser.image === undefined
                              ? '/images/images/img_avatar.png'
                              : props.appTask.creatorUser.image
                          }
                        />
                      )}
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.props.history.push(
                            $ReplaceRoute(
                              `${AppRoutes.member_profile.replace(
                                ':id',
                                props.appTask.creatorUser.id?.toString() ?? ''
                              )}`
                            )
                          );
                        }}
                      >
                        {' '}
                        {props.appTask.creatorUser.firstName} {props.appTask.creatorUser.lastName}
                      </span>
                    </div>
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Users',
              accessor: '',
              Cell: (props: any) => {
                return (
                  <Table.Cell
                    className='p-3 parent-row-background'
                    style={{ minWidth: '200px', width: '200px' }}
                  >
                    <GroupedAvatars
                      containerWidth={100}
                      maxAvatars={2}
                      tippyContent={
                        <div className='d-flex flex-column py-2 pb-0'>
                          {props.appTask.users.map((_us: any) => (
                            <UserWithAvatar
                              parentStyle={{
                                marginBottom: '0.25em',
                              }}
                              key={_us.id}
                              user={_us}
                            />
                          ))}
                        </div>
                      }
                      users={props.appTask.users}
                    />
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Deadline',
              accessor: 'endDate',
              Cell: (props: any) => {
                return (
                  <Table.Cell
                    className='p-3 text-nowrap parent-row-background'
                    style={{ minWidth: '150px', width: '150px' }}
                  >
                    {moment(props.appTask.endDate).format(MOMENT_DATE_FORMAT)}
                  </Table.Cell>
                );
              },
            },
            {
              Header: 'Status',
              accessor: 'status',
              Cell: (props: any) => {
                return (
                  <Table.Cell
                    className='p-3 parent-row-background'
                    style={{ minWidth: '150px', width: '150px' }}
                  >
                    {this.renderStatus(props.appTask.status)}
                  </Table.Cell>
                );
              },
            },
            {
              Header: '',
              Cell: (props: any) => {
                return (
                  <Table.Cell className='p-3 text-nowrap parent-row-background'>
                    <div className='d-flex align-items-center gap-2'>
                      <div className='btn-group'>
                        <button
                          type='button'
                          className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0'
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(
                              $ReplaceRoute(
                                `${AppRoutes.task_profile.replace(
                                  ':id',
                                  props.appTask.id.toString() ?? ''
                                )}`
                              )
                            );
                          }}
                        >
                          <i className='fa fa-eye me-px-6'></i>View Details
                        </button>

                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger>
                            <button
                              type='button'
                              className='btn btn-soft-primary btn-sm fs-13 px-2 h-px-29 py-0 dropdown-toggle dropdown-toggle-split'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              <span className='visually-hidden'>Toggle Dropdown</span>
                            </button>
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.task_profile.replace(
                                        ':id',
                                        props.appTask.id.toString() ?? ''
                                      )}`
                                    )
                                  );
                                }}
                              >
                                View Details
                              </button>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={(e) => {
                                  e.preventDefault();
                                  store.dispatch(SetResearchId({ label: props.appTask.researchName ?? '', value: props.appTask.researchId }));
                                  this.props.history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.task_edit.replace(
                                        ':id',
                                        props.appTask.id.toString() ?? ''
                                      )}`
                                    )
                                  );
                                }}
                              >
                                Edit Task
                              </button>
                            </DropdownMenu.Item>
                            <DropdownMenu.Item className='px-0'>
                              <button
                                className='dropdown-item fs-14'
                                onClick={() => {
                                  this.props.history.push(
                                    $ReplaceRoute(
                                      `${AppRoutes.data_new}?taskId=${props.appTask.id}`
                                    )
                                  );
                                }}
                              >
                                Add Dataset
                              </button>
                            </DropdownMenu.Item>
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </div>
                      {props.appTask.status != ResearchStatus.Completed && (
                        <div
                          className='d-flex h-px-29 w-px-25 p-0 align-items-center justify-content-center
                                   cursor-pointer  markAsChecked'
                        >
                          <CheckIcon
                            style={{
                              height: '15px',
                              width: '15px',
                              color: '#299764',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.markTaskAsCompleted(props.appTask.id, props.appTask.researchId);
                            }}
                          ></CheckIcon>
                        </div>
                      )}
                    </div>
                  </Table.Cell>
                );
              },
            },
          ]}
        />
        {this.state.PageCount > 0 && (
          <Pagination
            pageCount={this.state.PageCount}
            onPageChange={this.handelChangePageNumber.bind(this)}
            onPageSizeChange={(e: any) => {
              this.handelChangePageSize(e);
            }}
            pageInput={this.state.PageNumberInput}
            handlePageNumberInput={this.handlePageNumberInput}
          />
        )}
      </div>
    );
  }
}
export default withRouter(TasksPage);
