import {
  ChangeEvent,
  FormEvent,
  SVGProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactLoading from "react-loading";
import AppleLogin from "react-apple-login";
import { FaGoogle } from "react-icons/fa";
import { ToastContentProps, toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import { useMsal } from "@azure/msal-react";
import { worSpaceController } from "../../../controllers/workSpace/workSpace";
import { UserController } from "../../../controllers/user/user_controller";
import {
  SetUserInfo,
  SetUserRole,
} from "../../../data/storeMain/actions/user_action";
import { store } from "../../../data/storeMain";
import { AppRoutes, clientId } from "../../../core/constants";
import { SignupBackground } from "../signup/component/signup_background";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import {
  $ReplaceRoute,
  UserRoles,
  verifyUserSubscription,
} from "../../../core/utils";
import { UserSigninReq } from "../../../data/models/requests/user/signin_req";
import { BASE_URL_REDIRECT, HTTP } from "../../../core/http_common";
// import { LogosMicrosoftIcon } from "./login_backup";
import { Link } from "react-router-dom";
import { SignUpCheckMail } from "../signup/component/signup_checkmail";
import { BaseButton } from "@fluentui/react";
import { base_url_site } from "../../../core/localVariables";
interface SignupPageProps {
  history?: any;
}
export const LoginPage: React.FC<RouteComponentProps> = (props) => {
  const [password, setpassword] = useState("");
  // const [uniqPassword, setUniqPassword] = useState("");
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const [oAuth, setOauth] = useState(false);
  const [tokenId, setToken] = useState("");
  const [microsoftLogin, setIsMicrosoft] = useState(false);
  const [radvixCheckLogin, setRadvixCheckLogin] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [loginUserErrorStatus, setloginUserErrorStatus] = useState<
    null | number
  >(null);
  const [loginUserError, setloginUserError] = useState("");

  const [userId, UserId] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showAdditionalForm, setShowAdditionalForm] = useState(false);
  const [isMobileLogin, setIsMobileLogin] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  const controller: UserController = new UserController();

  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [errors, setErrors] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
  });
  const initialState = {
    cvc: "",
    expiry: "",
    name: "",
    number: "",
  };
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const token = queryParams.get("token");
    const userId = queryParams.get("userId");
    const isMobileLogin = queryParams.get("mobileLogin");

    if (isMobileLogin) {
      localStorage.setItem("isMobileLogin", isMobileLogin);
      setIsMobileLogin(true);
    }
    if (token && userId) {
      setemail(userId);
      const body: UserSigninReq = {
        email: userId,
        token: token,
      };
      handleSignIn(body);
    }
  }, []);
  const handleInputChange = (name: string, value: string) => {
    let error = "";
    if (name === "cvc") {
      if (!/^[0-9]{3,4}$/.test(value)) {
        error = "Invalid CVC";
      }
      setCvc(value);
    } else if (name === "number") {
      if (!/^[0-9]{16}$/.test(value)) {
        error = "Invalid Card Number";
      }
      setNumber(value);
    } else if (name === "name") {
      if (!/^[a-zA-Z ]+$/.test(value)) {
        error = "Invalid Name";
      }
      setName(value);
    }
    setErrors({ ...errors, [name]: error });
  };
  const handleSave = () => {
    setloading(true);
    const usersId = userId;
    const paymentData = { usersId, cvc, expiry, name, number };

    HTTP.post(`/User/SubscriptionPaymentMethod`, paymentData)
      .then((res) => {
        toast.success("Payment Added Successfully, Now you can login");
        setloading(false);
        resetInputs();
        if (closeButtonRef.current) {
          closeButtonRef.current.click();
        }
      })
      .catch((err) => {
        setloading(false);
        // if (err.response?.data.message)
        //toast.error(`${err.response?.data.message}`);
      });
  };

  const handleCardExpirationDateChange = (e: { target: { value: any } }) => {
    const input = e.target.value;
    const formattedInput = input.replace(/\D/g, "");

    if (formattedInput.length > 4) {
      return;
    }

    let expiry = "";
    if (formattedInput.length > 2) {
      const month = formattedInput.substring(0, 2);
      const year = formattedInput.substring(2, 4);
      expiry = `${month}/${year}`;
    } else if (formattedInput.length > 0) {
      expiry = formattedInput;
    }

    setExpiry(expiry);
  };
  const resetInputs = () => {
    setShowPopup(false);
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
    setCvc(initialState.cvc);
    setExpiry(initialState.expiry);
    setName(initialState.name);
    setNumber(initialState.number);
    setErrors({
      cvc: "",
      expiry: "",
      name: "",
      number: "",
    });
  };
  const ValidityCheck = () => {
    const hasError = Object.values(errors).some((error) => error !== "");
    const isAnyFieldEmpty = !(cvc && expiry && name && number);
    return hasError || isAnyFieldEmpty;
  };
  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        closePopup();
      }
    };

    if (showPopup) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showPopup]);
  function SignIn() {
    const formValid = Validator.current.allValid();

    if (!formValid) {
      Validator.current.showMessages();
      forceUpdate(1);
    } else {
      const body: UserSigninReq = {
        password: password,
        email: email,
      };
      handleSignIn(body);
    }
  }
  function verifyMfaCode(_code?: string) {
    setloading(true);
    let _body: {
      code: number;
      sessionDays?: number | null;
    } = {
      code: Number(_code ? _code : 111111),
    };
    _body["sessionDays"] = 30;
    controller.verifyMfaCode(
      _body,
      (res) => {
        localStorage.setItem("token", res.token || "asd");
        localStorage.setItem("refreshToken", res.refreshToken || "asd");
        localStorage.setItem("userId", res.id || "asd");
        localStorage.setItem("deviceId", res.deviceId || "asd");
        localStorage.setItem("logedin", "true");
        localStorage.setItem("USER_COOKIES", res.cookieString || "asd");

        console.trace();
        //toast.info(localStorage.getItem("token"));

        HTTP.defaults.headers.common["authorization"] = `Bearer ${res?.token}`;
        const UserInfo = {
          firstName: res?.firstName,
          lastName: res?.lastName,
          email: res?.email,
          image: res?.profileImage,
          role: res?.role,
          institution: res?.companyName,
        };

        localStorage.setItem("userInfo", JSON.stringify(UserInfo) ?? "");
        store.dispatch(SetUserRole(res.role ?? 0));
        store.dispatch(SetUserInfo(res));
        window.dispatchEvent(
          new CustomEvent("loggedIn", {
            detail: {
              token: res.token,
              userId: res.id,
            },
          })
        );
        history.push(`/${res.companyName}/dashboard/home`);
      },
      //@ts-ignore
      (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
          //setloginUserError(err.response.data.message);
          setTimeout(() => {
            setemail(email);
          }, 500);
          // history.push(AppRoutes.register_page);
        } else {
          //setloginUserError("Something went wrong");
        }
        setloading(false);
      }
    );
  }

  // const emailValidation = () => {
  //   controller.requestConfirmEmail(
  //     email,
  //     () => { },
  //     () => { }
  //   );
  // };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemail(event.target.value);
    // FormData.name = email;
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setpassword(event.target.value);
    // FormData.name = email;
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const [IsEmailConfomred, setIsEmailConfomred] = useState(false);
  const handleSignIn = (body: UserSigninReq) => {
    setloading(true);
    controller.Signin(
      body,
      (res) => {
        setloading(false);
        if (!res) return;
        const _enumList = JSON.parse(localStorage.getItem("setting") || "{}");
        if (res.emailConfirmed == false) {
          store.dispatch(SetUserInfo(res));
          setIsEmailConfomred(true);
        } else {
          setIsEmailConfomred(false);
          if (res.subscriptionEnded == true && res.paymentSuccessful == false) {
            UserId(res.id ?? "");
            localStorage.setItem("token", res.token ?? "");
          } else if (
            res.subscriptionEnded == false &&
            res.paymentSuccessful == true
          ) {
            UserId(res.id ?? "");
            toast.success("Payment has been successful");
            localStorage.setItem("token", res.token ?? "");
            const isMfaActive = _enumList.mfaConfig;
            const _mobileLogin = localStorage.getItem("isMobileLogin");
            if (_mobileLogin == "1") {
              if (body.email.toLowerCase().includes("radvix.check")) {
                setRadvixCheckLogin(true);
                setIsEmailConfomred(false);
                setShowAdditionalForm(false);
                setOauth(true);
                return;
              }
            } else {
              if (body.email.toLowerCase().includes("radvix.check")) {
                verifyMfaCode("111111");
                return;
              }
            }
            if (isMfaActive && !body.token) {
              // Redirect to MFA (Multi-Factor Authentication) if active
              history.push(AppRoutes.mfa);
            } else {
              // Handle the regular user login
              store.dispatch(SetUserRole(res.role ?? 0)); // Set user role
              store.dispatch(SetUserInfo(res)); // Set user information

              if (res.role === UserRoles.Admin) {
                // Handle admin login
                history.push(`/controlPanel/dashboard/Admin/home`); // Redirect to admin dashboard
                window.dispatchEvent(
                  new CustomEvent("loggedIn", {
                    detail: {
                      token: res.token,
                      userId: res.id,
                    },
                  })
                ); // Dispatch a custom event for logging in
              } else {
                // Handle regular user login
                if (body.token) {
                  controller.verifyMfaCode(
                    { Token: body.token, code: 1111 },
                    (res) => {
                      setloading(false);
                      if (res) {
                        store.dispatch(SetUserRole(res.role ?? 0));
                        store.dispatch(SetUserInfo(res));
                        props.history.push(AppRoutes.workSpace);
                        window.dispatchEvent(
                          new CustomEvent("loggedIn", {
                            detail: {
                              token: res.token,
                              userId: res.id,
                            },
                          })
                        );
                      }
                    },
                    //@ts-ignore
                    (err) => {
                      if (err?.response?.data?.message) {
                        toast.error(err?.response?.data?.message);
                      }
                      setloading(false);
                    }
                  );
                } else {
                  history.push(`/${res.companyName}/dashboard/home`); // Redirect to the user's dashboard
                  window.dispatchEvent(
                    new CustomEvent("loggedIn", {
                      detail: {
                        token: res.token,
                        userId: res.id,
                      },
                    })
                  ); // Dispatch a custom event for logging in
                }
              }
            }
          } else {
            const _isMfaActive = _enumList.mfaConfig;
            const _mobileLogin = localStorage.getItem("isMobileLogin");
            if (_mobileLogin == "1") {
              if (body.email.toLowerCase().includes("radvix.check")) {
                setRadvixCheckLogin(true);
                setIsEmailConfomred(false);
                setShowAdditionalForm(false);
                setOauth(true);
                return;
              }
            } else {
              if (body.email.toLowerCase().includes("radvix.check")) {
                verifyMfaCode("111111");
                return;
              }
            }
            if (_isMfaActive && !body.token) {
              history.push(AppRoutes.mfa);
            } else {
              store.dispatch(SetUserRole(res.role ?? 0));
              store.dispatch(SetUserInfo(res));
              //
              if (res.role === UserRoles.Admin) {
                history.push(`/controlPanel/dashboard/Admin/clients`);
                // props.history.replace(AppRoutes.admin_dashboard);
                // window.location.replace(AppRoutes.admin_dashboard);
                window.dispatchEvent(
                  new CustomEvent("loggedIn", {
                    detail: {
                      token: res.token,
                      userId: res.id,
                    },
                  })
                );
              } else if (body.token) {
                controller.verifyMfaCode(
                  { Token: body.token, code: 1111 },
                  (res) => {
                    setloading(false);
                    if (res) {
                      store.dispatch(SetUserRole(res.role ?? 0));
                      store.dispatch(SetUserInfo(res));
                      props.history.push(AppRoutes.workSpace);
                      window.dispatchEvent(
                        new CustomEvent("loggedIn", {
                          detail: {
                            token: res.token,
                            userId: res.id,
                          },
                        })
                      );
                    }
                  },
                  //@ts-ignore
                  (err) => {
                    if (err?.response?.data?.message) {
                      toast.error(err?.response?.data?.message);
                    }
                    setloading(false);
                  }
                );
              } else {
                // props.history.replace(`/${res.companyName}/dashboard`);
                // window.location.replace(`/${res.companyName}/dashboard`);

                history.push(`/${res.companyName}/dashboard/home`);
                window.dispatchEvent(
                  new CustomEvent("loggedIn", {
                    detail: {
                      token: res.token,
                      userId: res.id,
                    },
                  })
                );
                // new CustomEvent('loggedIn')
              }
            }
          }
        }
      },
      //@ts-ignore
      (err) => {
        if (err?.response?.data?.message) {
          setloginUserErrorStatus(err.response.data.status);
          setloginUserError(err.response.data.message);
          setTimeout(() => {
            setemail(email);
          }, 500);
          //toast.error(err?.response?.data?.message);
          // history.push(AppRoutes.register_page);
        } else {
          setloginUserError("Something went wrong");
        }
        setloading(false);
      }
    );
  };

  const handleSignInMicrosoft = (microsoftRes: any) => {
    // toast.info(
    //   "handleSignInMicrosoft first line" + JSON.stringify(microsoftRes)
    // );
    if (microsoftRes.tokenId) {
      setloading(true);
      const _mobileLogin = localStorage.getItem("isMobileLogin");
      if (_mobileLogin == "1") {
        setIsMicrosoft(true);
        setRadvixCheckLogin(false);
        setToken(microsoftRes.tokenId);
        setloading(false);
        setOauth(true);
      } else {
        //toast.info(microsoftRes.tokenId);
        controller.MicrosoftSignin(
          {
            token: microsoftRes.tokenId,
          },
          (res) => {
            setloading(false);
            if (!res) return;

            if (_mobileLogin == "1") {
              setloading(false);
              setOauth(true);
              return;
            }
            const _enumList = JSON.parse(
              localStorage.getItem("setting") || "{}"
            );
            const _isMfaActive = _enumList.mfaConfig;
            if (res) {
              localStorage.setItem("token", res.token || "asd");
              localStorage.setItem("refreshToken", res.refreshToken || "asd");
              localStorage.setItem("userId", res.id || "asd");
              localStorage.setItem("deviceId", res.deviceId || "asd");
              localStorage.setItem("logedin", "true");
              localStorage.setItem("USER_COOKIES", res.cookieString || "asd");
            }
            console.trace();
            //toast.info(localStorage.getItem("token"));

            HTTP.defaults.headers.common[
              "authorization"
            ] = `Bearer ${res?.token}`;
            const UserInfo = {
              firstName: res?.firstName,
              lastName: res?.lastName,
              email: res?.email,
              image: res?.profileImage,
              role: res?.role,
              institution: res?.companyName,
            };

            localStorage.setItem("userInfo", JSON.stringify(UserInfo) ?? "");
            store.dispatch(SetUserRole(res.role ?? 0));
            store.dispatch(SetUserInfo(res));
            window.dispatchEvent(
              new CustomEvent("loggedIn", {
                detail: {
                  token: res.token,
                  userId: res.id,
                },
              })
            );
            verifyUserSubscription(props, res);
          },
          (_err) => {
            setloading(false);
          }
        );
      }
    }
  };

  const handleOAuthLogin = () => {
    if (microsoftLogin) {
      setloading(true);
      controller.MicrosoftSignin(
        {
          token: tokenId,
        },
        (res) => {
          setloading(false);
          if (!res) return;
          const _mobileLogin = localStorage.getItem("isMobileLogin");
          if (_mobileLogin == "1") {
            setloading(false);
            window.location.href = res.redirectUrl;
            setOauth(false);
            return;
          }
        },
        (_err) => {
          setloading(false);
        }
      );
    } else if (radvixCheckLogin) {
      setloading(true);
      let _body: {
        code: number;
        sessionDays?: number | null;
      } = {
        code: Number(111111),
      };
      _body["sessionDays"] = 30;
      controller.verifyMfaCode(
        _body,
        (res) => {
          const _mobileLogin = localStorage.getItem("isMobileLogin");
          if (_mobileLogin == "1") {
            setloading(false);
            window.location.href = res.redirectUrl;
            return;
          }
        },
        //@ts-ignore
        (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
            //setloginUserError(err.response.data.message);
            setTimeout(() => {
              setemail(email);
            }, 500);
            // history.push(AppRoutes.register_page);
          } else {
            //setloginUserError("Something went wrong");
          }
          setloading(false);
        }
      );
    } else {
      setloading(true);
      controller.GoogleSignin(
        {
          token: tokenId,
        },
        (res) => {
          setloading(false);
          if (!res) return;
          setemail(res.email != undefined ? res.email : "");
          const _mobileLogin = localStorage.getItem("isMobileLogin");
          if (_mobileLogin == "1") {
            setloading(false);
            window.location.href = res.redirectUrl;
            return;
          }
        },
        (_err) => {
          setloading(false);
        }
      );
    }
  };
  const handleSignInGoogle = (googleRes: any) => {
    if (googleRes.tokenId) {
      setloading(true);
      const _mobileLogin = localStorage.getItem("isMobileLogin");
      if (_mobileLogin == "1") {
        setIsMicrosoft(false);
        setRadvixCheckLogin(false);
        setToken(googleRes.tokenId);
        setloading(false);
        setOauth(true);
      } else {
        controller.GoogleSignin(
          {
            token: googleRes.tokenId,
          },
          (res) => {
            setloading(false);
            if (!res) return;
            setemail(res.email != undefined ? res.email : "");
            const _mobileLogin = localStorage.getItem("isMobileLogin");
            if (_mobileLogin == "1") {
              setloading(false);
              setOauth(true);
              return;
            }
            const _enumList = JSON.parse(
              localStorage.getItem("setting") || "{}"
            );
            if (res) {
              localStorage.setItem("token", res.token || "asd");
              localStorage.setItem("refreshToken", res.refreshToken || "asd");
              localStorage.setItem("userId", res.id || "asd");
              localStorage.setItem("deviceId", res.deviceId || "asd");
              localStorage.setItem("logedin", "true");
              localStorage.setItem("USER_COOKIES", res.cookieString || "asd");
            }
            console.trace();
            // HTTP_IMG.interceptors.request.use((config) => {
            //   const cookies = res.result?.cookieString ?? "";
            //   if (cookies && config.headers) {
            //     console.log({config}, 'seting cookies')
            //     config.headers['x-Cookie'] = cookies;
            //   }
            //   return config;
            // });
            // HTTP_IMG.defaults.headers.common["Cookie"] = res.result?.cookieString || '';

            HTTP.defaults.headers.common[
              "authorization"
            ] = `Bearer ${res?.token}`;
            const UserInfo = {
              firstName: res?.firstName,
              lastName: res?.lastName,
              email: res?.email,
              image: res?.profileImage,
              role: res?.role,
              institution: res?.companyName,
            };

            localStorage.setItem("userInfo", JSON.stringify(UserInfo) ?? "");
            store.dispatch(SetUserRole(res.role ?? 0));
            store.dispatch(SetUserInfo(res));
            window.dispatchEvent(
              new CustomEvent("loggedIn", {
                detail: {
                  token: res.token,
                  userId: res.id,
                },
              })
            );

            verifyUserSubscription(props, res);
          },
          (_err) => {
            // if (_err?.response?.data?.message) {
            //   setloginUserError(_err.response.data.message);
            //   setTimeout(() => {
            //     setemail(email);
            //   }, 500);
            //   // history.push(AppRoutes.register_page);
            // } else {
            //   setloginUserError("Something went wrong");
            // }
            setloading(false);
          }
        );
      }
    }
  };
  const Validator = useRef(
    new SimpleReactValidator({
      className: "text-danger",
      messages: {
        required: ":attribute field is required.",
        // email: "Email is not valid",
        // min: "Password must be at least 6 characters",
      },
    })
  );
  useEffect(() => {
    document.title = `Login | Radvix`;
    const _userToken = localStorage.getItem("token");
    if (_userToken) {
      history.push($ReplaceRoute(AppRoutes.dashboard));
    }
  }, []);
  const [, forceUpdate] = useState(0);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleEmailSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please select a login method.");
      return;
    }
    if (!emailRegex.test(email)) {
      toast.error("Please enter a vaild email address.");
      return;
    }
    localStorage.setItem("email", email);
    setShowAdditionalForm(true);
    setValidationErrors({ Email: false });
  };
  const { instance } = useMsal();
  const microsftLogin = localStorage.getItem("MicrosoftLogin");
  const microsftSignup = localStorage.getItem("MicrosoftSignup");
  useEffect(() => {
    if (microsftSignup) {
      props.history.push(AppRoutes.signup);
    } else {
      if (microsftLogin) {
        setloading(true);
        const authResult = JSON.parse(microsftLogin);
        const username = authResult.account.username;
        const tokenId = authResult.idToken;
        localStorage.removeItem("MicrosoftLogin");
        if (tokenId) {
          handleSignInMicrosoft({
            email: username,
            familyName: "",
            givenName: authResult.account.name.split(" ")[0],
            tokenId: tokenId,
          });
        }
      }
    }
  }, [instance, microsftLogin, microsftSignup]);
  const onAppleSuccess = (response: any) => {
    console.log("Apple login success", response);
    console.log("Apple login success", JSON.parse(response));
  };

  const onAppleFailure = (error: any) => {
    console.error("Apple login error", error);
  };
  const initializeSignIn = () => {
    localStorage.setItem('clickedOn', 'login');
    instance.loginRedirect({
      scopes: ['user.read'],
      redirectUri: BASE_URL_REDIRECT + '/login',
    });
  };

  useEffect(() => {
    window.AppleID.auth.init({
      clientId: "io.radvix.app.service",
      scope: "name email",
      redirectURI: "https://www.app.radvix.io",
      usePopup: true,
    });
  }, []);
  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const body: UserSigninReq = {
      password: password,
      email: email,
    };
    handleSignIn(body);
  };
  const handleEmail = () => {
    setIsEmailConfomred(false);
    setShowAdditionalForm(false);
  };
  return (
    <>
      {/* <SignupBackground> */}
      <div>
        {IsEmailConfomred && (
          <SignUpCheckMail Email={email} onClick={handleEmail} />
        )}
        {!showAdditionalForm && !IsEmailConfomred && !oAuth && (
          <>
            <div className="container">
              <div className="max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100">
                <div className="text-center mb-3">
                  <img
                    src="images/icons/new-radvix-logo.png"
                    width={111}
                    height={49}
                    // src='images/icons/radvix-logo.svg'
                    // srcSet="images/icons/radvix-logo.svg 111w"
                    // sizes="(max-width: 768px) 20vw, 10vw"
                    alt="Company logo"
                  />
                </div>
                <div className="bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder">
                  <h1 className="fs-19 color-gray-1000 fw-bold text-center mb-0">
                    Login
                  </h1>
                  <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                  {/* {isMobileLogin == true && (
                    <AppleLogin
                      clientId="io.radvix.app.service" // Your Apple Service ID
                      redirectURI="https://app.radvix.io"
                      responseType="code id_token name email"
                      usePopup={true}
                      callback={onAppleSuccess}
                      render={(renderProps: any) => (
                        <button onClick={renderProps.onClick}>
                          Sign in with Apple
                        </button>
                      )}
                    />
                  )} */}
                  <button
                    className="btn btn-transparent d-flex align-items-center gap-2 w-100 microsoftButtonPadding justify-content-center mb-px-20"
                    onClick={initializeSignIn}
                    disabled={loading}
                  >
                    {/* <img src="../assets/img/microsoft.png" className="w-px-16 h-px-16" />
                <span className="fs-15 color-gray-1000 fw-medium">Login with Microsoft</span> */}
                    <LogosMicrosoftIcon style={{ marginRight: "10px" }} />
                    <span className="fs-15 color-gray-1000 fw-medium">
                      Login with Microsoft
                    </span>
                  </button>
                  <button className="btn btn-transparent d-flex align-items-center gap-2 w-100 justify-content-center googleButtonPadding">
                    {/* <img src="../assets/img/google.png" className="w-px-18 h-px-18" /> */}
                    {/* <span className="fs-15 color-gray-1000 fw-medium">Login with Google</span> */}
                    <GoogleLogin
                      disabled={false}
                      clientId={clientId}
                      buttonText="Google"
                      onSuccess={(res: any) => {
                        const { email, familyName, givenName } = res.profileObj;
                        handleSignInGoogle({
                          email,
                          familyName,
                          givenName,
                          tokenId: res.tokenId,
                        });
                      }}
                      onFailure={(err) => {}}
                      cookiePolicy={"single_host_origin"}
                      className="GoogleButton"
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled || loading}
                          className="SignupButton"
                          style={{ height: "38px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            style={{ marginBottom: "3px" }}
                          >
                            <path
                              d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                              fill="#FFC107"
                            />
                            <path
                              d="M3.15308 7.3455L6.43858 9.755C7.32758 7.554 9.48058 6 12.0001 6C13.5296 6 14.9211 6.577 15.9806 7.5195L18.8091 4.691C17.0231 3.0265 14.6341 2 12.0001 2C8.15908 2 4.82808 4.1685 3.15308 7.3455Z"
                              fill="#FF3D00"
                            />
                            <path
                              d="M11.9999 22C14.5829 22 16.9299 21.0115 18.7044 19.404L15.6094 16.785C14.5717 17.5742 13.3036 18.001 11.9999 18C9.39891 18 7.19041 16.3415 6.35841 14.027L3.09741 16.5395C4.75241 19.778 8.11341 22 11.9999 22Z"
                              fill="#4CAF50"
                            />
                            <path
                              d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                              fill="#1976D2"
                            />
                          </svg>{" "}
                          <span
                            className="fs-15 color-gray-1000 fw-medium"
                            style={{ paddingLeft: "2px" }}
                          >
                            Login with Google
                          </span>
                        </button>
                      )}
                    />
                  </button>
                  <div className="line-before line-after mt-px-20 mb-px-16">
                    <span className="color-gray-600 px-px-20 fs-15">OR</span>
                  </div>
                  <div className="w-100 mb-px-20">
                    <div className="mb-1 text-start">
                      <label className="color-gray-600 fs-15 me-2">Email</label>
                    </div>
                    <input
                      className="form-control h-px-45"
                      placeholder="Enter your email address"
                      onChange={handleEmailChange}
                      value={email}
                      autoComplete="email"
                      type="email"
                      name="email"
                      id="email"
                    />
                    {/* {validationErrors.Email && (
                    <div className="error-text">Please select a login method.</div>
                  )} */}
                  </div>
                  <button
                    className="btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton"
                    type="submit"
                    onClick={handleEmailSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <ReactLoading
                        className="d-flex"
                        type="spin"
                        height="auto"
                        width={20}
                      />
                    ) : (
                      "Continue"
                    )}
                  </button>
                  <div className="fs-14 fw-normal color-gray-600 mb-px-30">
                    {" "}
                    Don’t have a Radvix account?{" "}
                    <a
                      className="text-primary fw-semibold cursor-pointer"
                      onClick={() => {
                        props.history.push(AppRoutes.signup);
                      }}
                    >
                      Sign Up
                    </a>
                  </div>
                  <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                  <div className="fs-14 fw-normal">
                    <a
                      className="color-gray-600 cursor-pointer"
                      href={`${base_url_site}/terms-of-services`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms of Service |{" "}
                    </a>
                    <a
                      className="color-gray-600 cursor-pointer"
                      href={`${base_url_site}/privacy-policy`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
                className="form-container form-style"
                style={{
                  display: "flow",
                  justifyContent: "center",
                  maxWidth: "390px",
                  width: "80%",
                  maxHeight: "458px",
                  height: "80%",
                  background:
                    "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
                }}
              >
                <h2
                  className="text-center P_Heading mb-4"
                  style={{ paddingTop: "11px" }}
                >
                  Welcome Back!
                </h2>

                <div className="row" style={{ paddingTop: "10px" }}>
                  <div className="col-6 group-wrapper">
                    <div className="overlap-group-wrapper">
                      <div className="overlap-group-2">
                        <GoogleLogin
                          disabled={false}
                          clientId={clientId}
                          buttonText="Google"
                          onSuccess={(res: any) => {
                            
                            const { email, familyName, givenName } =
                              res.profileObj;
                            handleSignInGoogle({
                              email,
                              familyName,
                              givenName,
                              tokenId: res.tokenId,
                            });
                          }}
                          onFailure={(err) => {}}
                          cookiePolicy={"single_host_origin"}
                          className="GoogleButton"
                          render={(renderProps) => (
                            <button
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                              className="SignupButton"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                                  fill="#FFC107"
                                />
                                <path
                                  d="M3.15308 7.3455L6.43858 9.755C7.32758 7.554 9.48058 6 12.0001 6C13.5296 6 14.9211 6.577 15.9806 7.5195L18.8091 4.691C17.0231 3.0265 14.6341 2 12.0001 2C8.15908 2 4.82808 4.1685 3.15308 7.3455Z"
                                  fill="#FF3D00"
                                />
                                <path
                                  d="M11.9999 22C14.5829 22 16.9299 21.0115 18.7044 19.404L15.6094 16.785C14.5717 17.5742 13.3036 18.001 11.9999 18C9.39891 18 7.19041 16.3415 6.35841 14.027L3.09741 16.5395C4.75241 19.778 8.11341 22 11.9999 22Z"
                                  fill="#4CAF50"
                                />
                                <path
                                  d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                                  fill="#1976D2"
                                />
                              </svg>{" "}
                              <span style={{ paddingLeft: "2px" }}>Google</span>
                            </button>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 div-wrapper">
                    <div className="overlap-group-2">
                      <button
                        onClick={initializeSignIn}
                        className="SignupButton"
                      >
                        <LogosMicrosoftIcon style={{ marginRight: "10px" }} />
                        <span>Microsoft</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="divider-OR">
                  <div className="overlap-2">
                    <img
                      className="divider-1"
                      alt="Divider"
                      src="/images/images/Divider.png"
                    />
                    <div className="OR">
                      <div className="Or-text">OR</div>
                    </div>
                    <img
                      className="divider-2"
                      alt="Divider"
                      src="/images/images/Divider.png"
                    />
                  </div>
                </div>
                <form
                  onSubmit={handleEmailSubmit}
                  style={{ paddingTop: "29px" }}
                >
                  <div
                    className="mt-3  mb-2 card_Holder_label"
                    style={{ paddingTop: "15px" }}
                  >
                    Email:{" "}
                  </div>
                  <div className="">
                    <input
                      name="Email"
                      onChange={handleEmailChange}
                      className="Payemnt_Card_input"
                      placeholder="Your Email"
                      style={{ width: "99%", height: "49px" }}
                    />
                  </div>
                  {validationErrors.Email && (
                    <div className="error-text">User doesn't exists.</div>
                  )}
                  <div>
                    <button
                      className="Payment_Button mt-4"
                      type="submit"
                      style={{ width: "99%", height: "47px" }}
                    >
                      {loading ? (
                        <ReactLoading
                          className="d-flex"
                          type="spin"
                          height="auto"
                          width={20}
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                    <p
                      style={{
                        paddingTop: "30px",
                        textAlign: "center",
                        marginBottom: "0px",
                      }}
                    >
                      <span className="span" style={{ fontSize: "14px" }}>
                        Don't have a Radvix account?
                      </span>
                      <span
                        className="text-wrapper-6"
                        style={{ fontSize: "14px" }}
                        onClick={() => {
                          props.history.push(AppRoutes.signup);
                        }}
                      >
                        {" "}
                        Sign up
                      </span>
                    </p>
                  </div>
                </form>
              </div> */}
          </>
        )}
        {showAdditionalForm && !IsEmailConfomred && (
          <>
            <div className="container">
              <div className="max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100">
                <div className="text-center mb-3">
                  <img
                    src="images/icons/new-radvix-logo.png"
                    width={111}
                    height={49}
                    // src='images/icons/radvix-logo.svg'
                    // srcSet='images/icons/radvix-logo.svg 111w'
                    // sizes='(max-width: 768px) 20vw, 10vw'
                    alt="Company logo"
                  />
                </div>
                <div className="bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder">
                  <div className="d-flex w-100">
                    <BaseButton
                      className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
                      style={{ fontWeight: "400", height: "19px" }}
                      onClick={() => {
                        setShowAdditionalForm(false);
                      }}
                    >
                      Back
                    </BaseButton>
                    <div className="verifyingAccount">
                      <h1 className="fs-19 color-gray-1000 fw-bold text-center mb-0">
                        Login
                      </h1>
                    </div>
                  </div>
                  <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                  <div className="w-100 mb-px-20">
                    <div className="mb-1 text-start">
                      <label className="color-gray-600 fs-15 me-2">Email</label>
                    </div>
                    <input
                      className="form-control h-px-45"
                      placeholder="Enter your email address"
                      readOnly={true}
                      value={email}
                      name="email"
                    />
                  </div>
                  <div className="w-100 mb-px-20">
                    <div className="mb-1 text-start">
                      <label className="color-gray-600 fs-15 me-2">
                        Password
                      </label>
                    </div>
                    <input
                      className="form-control h-px-45"
                      placeholder="Enter your password"
                      type="password"
                      id="password"
                      name="password"
                      onChange={handlePasswordChange}
                      readOnly={false}
                      disabled={false}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="forgotPasswordDiv w-100">
                    <Link
                      className="text-wrapper-6 forgotPasswordStyling"
                      style={{ fontSize: "14px" }}
                      to={{ pathname: "/reset", state: { email: email } }}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <button
                    className="d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {" "}
                    {loading ? (
                      <ReactLoading
                        className="d-flex"
                        type="spin"
                        height="auto"
                        width={20}
                      />
                    ) : (
                      "Continue"
                    )}{" "}
                  </button>
                  <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                  <div className="fs-14 fw-normal">
                    <a
                      className="color-gray-600 cursor-pointer"
                      href={`${base_url_site}/terms-of-services`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms of Service |{" "}
                    </a>
                    <a
                      className="color-gray-600 cursor-pointer"
                      href={`${base_url_site}/privacy-policy`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
                className="form-container"
                style={{
                  background:
                    "linear-gradient(360deg, rgba(212, 15, 237, 0) 0%, rgba(212, 15, 237, 1) 100%)",
                }}
              >
                <h2 className="text-center P_Heading mb-4">Welcome Back!</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mt-3  mb-2 card_Holder_label">Password: </div>
                  <div className="">
                    <input
                      className={`overlap-3 SignupInput w-100`}
                      type="password"
                      id="password"
                      style={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "23.092px",
                        letterSpacing: "0.071px",
                      }}
                      placeholder="Your Password"
                      onChange={handlePasswordChange}
                      readOnly={false}
                      disabled={false}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {/* <div className="ERROR_DIV">
                      <span style={{ color: "red" }} className="error-text">
                        Please enter your password.
                      </span>
                    </div> */}
            {/* </div>
                  <Link
                    className="text-wrapper-6"
                    style={{ fontSize: "14px" }}
                    to={{ pathname: "/reset", state: { email: email } }}
                  >
                    Forgot Password?
                  </Link>
                  <div>
                    <button className="Payment_Button mt-4 w-100" type="submit">
                      {loading ? (
                        <ReactLoading
                          className="d-flex"
                          type="spin"
                          height="auto"
                          width={20}
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                  <p
                    style={{
                      paddingTop: "20px",
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                  >
                    <span className="span" style={{ fontSize: "14px" }}>
                      Don't have a Radvix account?
                    </span>
                    <span
                      className="text-wrapper-6"
                      style={{ fontSize: "14px" }}
                      onClick={() => {
                        props.history.push(AppRoutes.signup);
                      }}
                    >
                      {" "}
                      Sign up
                    </span>
                  </p>
                </form> 
              </div> */}
          </>
        )}

        {!showAdditionalForm && !IsEmailConfomred && oAuth && (
          <>
            <div className="container">
              <div className="max-w-rem-23 m-auto d-flex flex-column align-items-center justify-content-center vh-100">
                <div className="text-center mb-3">
                  <img
                    src="images/icons/new-radvix-logo.png"
                    width={111}
                    height={49}
                    // src='images/icons/radvix-logo.svg'
                    // srcSet='images/icons/radvix-logo.svg 111w'
                    // sizes='(max-width: 768px) 20vw, 10vw'
                    alt="Company logo"
                  />
                </div>
                <div className="bg-white rounded-px-4 shadow-md w-100 d-flex p-px-20 align-items-center flex-column loginDivBorder">
                  <div className="d-flex w-100">
                    <BaseButton
                      className="btn btn-outline-primary fs-15 py-px-4 px-3 me-px-12 d-sm-block d-none back-button"
                      style={{ fontWeight: "400", height: "19px" }}
                      onClick={() => {
                        history.push(AppRoutes.login);
                      }}
                    >
                      Back
                    </BaseButton>
                    <div className="verifyingAccount">
                      <h1 className="fs-19 color-gray-1000 fw-bold text-center mb-0">
                        oAuth Successful
                      </h1>
                    </div>
                  </div>
                  <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />

                  <button
                    className="d-flex justify-content-center align-items-center btn btn-primary fs-15 mb-px-20 w-100 h-px-42 continurButton"
                    type="submit"
                    onClick={handleOAuthLogin}
                  >
                    {" "}
                    {loading ? (
                      <ReactLoading
                        className="d-flex"
                        type="spin"
                        height="auto"
                        width={20}
                      />
                    ) : (
                      "Back to mobile view"
                    )}{" "}
                  </button>
                  <hr className="border-gray-200 border w-100 opacity-100 my-px-20" />
                  <div className="fs-14 fw-normal">
                    <a
                      className="color-gray-600 cursor-pointer"
                      href={`${base_url_site}/terms-of-services`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms of Service |{" "}
                    </a>
                    <a
                      className="color-gray-600 cursor-pointer"
                      href={`${base_url_site}/privacy-policy`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* </SignupBackground> */}
    </>
  );
};

export function LogosMicrosoftIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 256 256"
      {...props}
    >
      <path fill="#F1511B" d="M121.666 121.666H0V0h121.666z"></path>
      <path fill="#80CC28" d="M256 121.666H134.335V0H256z"></path>
      <path fill="#00ADEF" d="M121.663 256.002H0V134.336h121.663z"></path>
      <path fill="#FBBC09" d="M256 256.002H134.335V134.336H256z"></path>
    </svg>
  );
}

export const MicrosoftLogin = ({
  disabled,
  onClick,
  variant = "contained",
  onSuccess,
  onFailure,
  text = "Login using Microsoft",
  className = "",
}: {
  onSuccess: (res: any) => void;
  onFailure: (res: any) => void;
  onClick?: () => void | undefined;
  text?: string;
  className?: string;
  variant?: string;
  disabled?: boolean;
}) => {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance
      .acquireTokenPopup({
        scopes: ["user.read"],
        prompt: "select_account",
      })
      .then((res) => {
        onSuccess(res);
      })
      .catch((err) => {
        onFailure(err);
      });
  };
  return (
    <button
      onClick={onClick ? onClick : initializeSignIn}
      className={`google-login-btn ${variant} signInHover ${className}`}
      style={{
        display: "inline-flex",
        alignItems: "center",
        boxShadow:
          "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
        border: "1px solid transparent",
        fontSize: 14,
        fontWeight: 500,
        fontFamily: "Roboto, sans-serif",
        opacity: disabled ? "0.6" : 1,
      }}
      disabled={disabled}
    >
      <div
        style={{
          marginRight: 10,
          padding: 10,
        }}
      >
        <LogosMicrosoftIcon />
      </div>
      <span
        className="max1LineText"
        style={{
          padding: "10px 10px 10px 0px",
          fontWeight: 500,
          flexGrow: 1,
          textAlign: "left",
        }}
      >
        {text}
      </span>
    </button>
  );
};
export default withRouter(LoginPage);

export const LeftBottomLoginLinks = () => {
  return (
    <div className="left_logo_login dNoneTablet">
      <span className="about linkOnHover">
        <a href="https://radvix.io" target="_blank" rel="noopener noreferrer">
          Radvix home
        </a>
      </span>
      <span className="about linkOnHover">
        <a
          href="https://radvix.io/support"
          rel="noopener noreferrer"
          target="_blank"
        >
          Client support
        </a>
      </span>
      <span className="about linkOnHover text-center">
        <a
          href={`${base_url_site}/privacy-policy`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy policy
        </a>
      </span>
      <span className="about linkOnHover text-center">
        <a
          href={`${base_url_site}/terms-of-services`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of service
        </a>
      </span>
    </div>
  );
};
