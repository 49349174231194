import { toast } from 'react-toastify';
import { SortType } from '../../core/number_extentions';
import { CreateGrantReq } from '../../data/models/requests/financials/create_grant_req';
import { AddProjectReq, EditGrantReq } from '../../data/models/requests/financials/update_grant_req';
import { CreateGrantResResult } from '../../data/models/responses/financials/grant/create_grant_res';
import { GetGrantByIDResult } from '../../data/models/responses/financials/grant/grant_by_id_res';
import { GrantResResult } from '../../data/models/responses/financials/grant/grant_res';
import { SearchGrantResResult } from '../../data/models/responses/financials/grant/search_grant_res';
import { RemoteGrant } from '../../data/remotes/financials/remote_grant';
import { store } from '../../data/storeMain';
import {
  DeleteGrantResResult,
  EditGrantResResult,
} from './../../data/models/responses/financials/grant/update_grant_res';
import { GrantResearchesResResult, ResearchesResResult } from '../../data/models/responses/research/researches_res';
import { IFilterOptions } from '../../views/components/FilterSection/IFilterOptions';
export class grantController {
  remote = new RemoteGrant();

  createGrant(
    body: CreateGrantReq,
    action: (res: CreateGrantResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.createGrant(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getGrantResearches(
    body: { PageNumber: number; PageSize: number; SearchParameter: string; columnHeaderName?: string; sortDirection?: SortType, filterOptions?: IFilterOptions },
    action: (res: GrantResearchesResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getGrantResearches(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message)
          toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }


  SearchGrant(action: (res: SearchGrantResResult) => any, error: (res: any) => any) {
    if (store.getState().ResearchId.value > 0) {
      this.remote.SearchGrant(
        { researchId: store.getState().ResearchId.value },
        (res) => {
          action(res.result!);
        },
        (err) => {
          if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
          error(err);
        }
      );
    } else {
      // toast.error(`please select research in search expense`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  }

  getGrant(
    body: {
      PageNumber: number;
      PageSize: number;
      SearchParameter: string;
      columnHeaderName?: string;
      sortDirection?: SortType;
    },
    action: (res: GrantResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getGrant(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  getGrantById(
    body: { id: number },
    action: (res: GetGrantByIDResult) => any,
    error: (res: any) => any
  ) {
    this.remote.getGrantById(
      body,
      (res) => {
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  updateGrant(
    body: EditGrantReq,
    action: (res: EditGrantResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.updateGrant(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  addProject(
    body: AddProjectReq,
    action: (res: any) => any,
    error: (res: any) => any
  ) {
    this.remote.addProject(
      body,
      (res) => {
        toast.success(`Project added successfully.`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }

  deleteGrant(
    body: { grantId: number },
    action: (res: DeleteGrantResResult) => any,
    error: (res: any) => any
  ) {
    this.remote.deleteGrant(
      body,
      (res) => {
        toast.success(`${res.message}`);
        action(res.result!);
      },
      (err) => {
        if (err.response?.data.message) toast.error(`${err.response?.data.message}`);
        error(err);
      }
    );
  }
}
