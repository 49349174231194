import { useHistory, useParams } from "react-router";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { InputComponent, InputType } from "../../components/inputs";
import NewTreeMenu from "../../components/NewTreeMenu";
import SimpleReactValidator from "simple-react-validator";
import BackButtonTitle from "./component/back_button_title";
import SubmitButton from "./component/submit_button";
import { grantController } from "../../../controllers/financials/grant_controller";
import { ResearchController } from "../../../controllers/research/research_controller";
import { GroupController } from "../../../controllers/group/group_controller";
import { store } from "../../../data/storeMain";
import { useSelector } from "react-redux";
import { SortType } from "../../../core/number_extentions";
import { EditGrantReq } from "../../../data/models/requests/financials/update_grant_req";
import { $ReplaceRoute } from "../../../core/utils";
import { AppRoutes } from "../../../core/constants";
import { toast } from "react-toastify";
import { SelectComponent } from "../../components/select_input";
import { LocalDataSources } from "../../../data/local_datasources";
import { ResearchesList } from "../../../data/models/responses/research/researches_res";

type Params = { company: string; id: string };

export default function GrantEdit() {
  const validator = useRef(
    new SimpleReactValidator({
      className: "text-danger",
    })
  );

  interface Manager {
    label: string;
    value: string;
    checked: boolean;
  }

  const { id }: Params = useParams();
  const controller = new grantController();
  const researchController = new ResearchController();
  const groupController = new GroupController();
  const history = useHistory();

  const [, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [source, setSource] = useState("");
  const [description, setDescription] = useState("");

  const [managerLoading, setManagerLoading] = useState(false);
  const [managers, setManagers] = useState<Manager[]>([]);
  const [selectedManagers, setSelectedManagers] = useState<any[]>([]);
  const [grantManagers, setGrantManagers] = useState<any[]>([]);

  const researchId = useSelector(() => store.getState().ResearchId);
  const [researchLoading, setResearchLoading] = useState(false);
  const [researches, setResearches] = useState<any[]>([]);
  const [researchList, setResearchList] = useState<any[]>([]);
  const [selectedResearch, setSelectedResearch] = useState<any[]>([]);
  const [projects, setProjects] = useState<ResearchesList[] | undefined>([]);
  const local = new LocalDataSources();
  useLayoutEffect(() => {
    document.title = `Edit Grant | Radvix`;
  }, []);

  function getGrantById() {
    setIsLoading(true);
    controller.getGrantById(
      { id: Number(id) },
      (res) => {
        if (res === null) {
          setIsLoading(false);
          return;
        }

        // Set general grant details
        const managers = res.managers.map((manager) => manager.userId);
        setTitle(res.title);
        setAmount(formatAmount(res.amount.toString()));
        setSource(res.source);
        setDescription(res.description);
        setGrantManagers([...res.managers]);
        setSelectedManagers([...managers]);

        // Set previously selected researches from the grant
        const grantResearches = res.researches.map(({ id, title }) => ({
          value: Number(id),
          label: title,
          checked: true, // Previously selected are always checked
          disabled: false, // Previously selected should not be disabled
        }));

        setResearches(grantResearches);
        setSelectedResearch(grantResearches); // Save selected researches
        loadGrantManagers(grantResearches,res.managers);
        setIsLoading(false);
      },
      (err) => {
        toast.error(err?.response?.data?.message);
        setIsLoading(false);
      }
    );
  }

  useEffect(() => {
    document.title = "Edit Grant | Radvix";    
    loadResearches();
  }, []);

  function formatAmount(value: string) {
    const numericValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
    const number = parseFloat(numericValue);

    if (!isNaN(number)) {
      return number.toLocaleString("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "";
  }

  const handleBlur = () => {
    setAmount(formatAmount(amount));
  };

  function loadResearches() {
    setResearchLoading(true);
    controller.getGrantResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: "",
        columnHeaderName: "",
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        if (res) {
          const excludedResearches = res.excludedResearches || [];
          const researchList = res.researchesList || [];

          // Get the previously selected (from getGrantById)
          const grantSelectedResearches = researches.map(
            ({ value, label, researchUsers }) => ({
              value: value,
              label: label,
              researchUsers: researchUsers,
              checked: true, // Preselect the research
              disabled: false, // Allow these to be editable
            })
          );

          const filteredExcludedResearches = excludedResearches.filter(
            (excluded) =>
              !grantSelectedResearches.some(
                (preselected) => preselected.value === excluded.id
              )
          );
          // Map over the loaded researches and exclude the ones in excludedResearches
          const otherResearches = researchList.map(({ id, title, researchUsers }) => ({
            value: id,
            label: title,
            researchUsers: researchUsers,
            checked: false, // Unchecked by default
            disabled: excludedResearches.some((excluded) => excluded.id === id), // Disable if part of excluded
          }));

          // Explicitly handle the excludedResearches as disabled entries
          const excludedResearchesMapped = filteredExcludedResearches.map(
            ({ id, title }) => ({
              value: id,
              label: title,
              checked: false, // Excluded should not be checked
              disabled: true, // Disabled because they are excluded
            })
          );

          // Combine the preselected researches, other researches, and excluded researches
          const mergedResearches = [
            ...grantSelectedResearches,
            ...otherResearches,
            ...excludedResearchesMapped,
          ];

          // Set the projects and research list
          const projects = mergedResearches.map(({ value, label }) => ({
            id: value,
            title: label,
          }));
          setProjects([...researchList,...excludedResearches]);
          setResearchList(mergedResearches);
        } else {
          console.log("Response is empty or undefined");
        }
        setResearchLoading(false);
      },
      (err) => {
        setResearchLoading(false);
      }
    );
  }

  useEffect(() => {
    getGroup();
    getGrantById();
  }, [researchList]);

  function getGroup() {
    setManagerLoading(true);
    controller.SearchGrant(
      (res) => {
        if (res && res.managers && res.managers.length > 0) {
          const managers = res.managers.map((item: any) => {
            return {
              label: item.firstName + " " + item.lastName,
              value: item.userId,
              checked: grantManagers.some(
                (manager: any) => item.userId === manager.userId
              ),
            };
          });
          //setManagers(managers);
          
        }
        setManagerLoading(false);
      },
      (err) => {
        setManagerLoading(false);
      }
    );
  }

  const loadGrantManagers = (researches: any[], defaultValues: any[]) => {
    const allResearchIds = researches.map(r => r.value);
    const selectedProjects = projects?.filter(project => allResearchIds.includes(project.id));
    const researchUsersArrays = selectedProjects?.map(project => project.researchUsers) || [];
    const commonUsers = researchUsersArrays.reduce((acc, users) => {
        return acc?.filter(user => users?.some(u => u.email === user.email));
    }, researchUsersArrays[0] || []);

    const initialManagers = commonUsers?.map(user => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
        checked: false,
    }));

    if (defaultValues) {
        const selectedValues = defaultValues.map(element => {
            const manager = initialManagers?.find(m => m.value === element.userId);
            if (manager) {
                manager.checked = true;
                return manager;
            }
            return null;
        }).filter(Boolean);

        setSelectedManagers(selectedValues);
    }

    setManagers(!initialManagers ? [] : initialManagers);
};


  function updateGrant(body: EditGrantReq) {
    setIsLoading(true);
    controller.updateGrant(
      body,
      (res) => {
        setIsLoading(false);
        history.push(
          $ReplaceRoute(
            `${AppRoutes.grant_detail.replace(":id", id.toString() ?? "")}`
          )
        );
      },
      (err) => {
        //toast.error(err?.response?.data?.message);
        setIsLoading(false);
      }
    );
  }

  function handelUpdateGrant() {
    setIsFormValid((prvState) => !prvState);
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      return;
    }
    const value = parseFloat(amount.replace(/,/g, ""));
    const selectedProject = selectedResearch.map(({ value, label }: any) => ({
      id: value,
      title: label,
    }));
    const valueArray = selectedManagers.map(item => item.value);
    updateGrant({
      id: Number(id),
      title,
      description,
      amount: value,
      source,
      managers: selectedManagers,
      researches: selectedProject,
    });
  }

  return (
    <div className="card grant-card">
      <div
        className="d-flex justify-content-between align-items-center p-3 row-gap-3 flex-wrap column-gap-3 border-bottom
        border-gray-400"
      >
        <BackButtonTitle title="Edit Grant" />
      </div>
      <div className="p-3 overflow-auto">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="mb-0">
                  <InputComponent
                    className="form-control"
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label="Grant Name*"
                    placeholder="Enter your grant name"
                    popQuestion="This will be the name of your grant. You can always edit this later."
                    value={title}
                    maxLength={150}
                    onChange={(e) => setTitle(e.target.value)}
                    inValid={validator.current.message(
                      "Grant Name",
                      title,
                      "required"
                    )}
                  ></InputComponent>
                </div>
                <div className="item">
                  <InputComponent
                    className="form-control"
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label="Grant Amount*"
                    placeholder="$100,000.00"
                    popQuestion="This will be the amount of your grant. You can always edit this later."
                    value={amount}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    inValid={validator.current.message(
                      "Grant Amount",
                      amount,
                      "required"
                    )}
                  ></InputComponent>
                </div>
                <div className="item">
                  <InputComponent
                    className="form-control"
                    disabled={isLoading}
                    addIcon={false}
                    type={InputType.text}
                    label="Grant Source"
                    placeholder="Enter grant source"
                    popQuestion="This will be the grant source. You can always edit this later."
                    value={source}
                    maxLength={150}
                    onChange={(e) => {
                      setSource(e.target.value);
                    }}
                  ></InputComponent>
                </div>

                <div className="item">
                  <div
                    className="text-primary cursor-pointer mb-1"
                    title="Info"
                  >
                    <label className="color-gray-600 fs-15 me-2">
                      Grant Description
                    </label>
                  </div>
                  <InputComponent
                    type={InputType.textarea}
                    disabled={isLoading}
                    placeholder="Description here"
                    height="132px"
                    popQuestion="You can write a description for your grant. For example, what is the main focus of this grant and what are the key objectives. You can always edit this later."
                    className="mt-1"
                    value={description}
                    defaultValue={description}
                    maxLength={1500}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  ></InputComponent>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
    <div className="col-lg-12 mb-3">
        <div className="d-flex align-items-center mb-1">
            <label className="color-gray-600 fs-15 me-2">
                Select Project*
            </label>
        </div>
        <SelectComponent
            disabled={researchLoading}
            items={researchList.map((item) => ({
                value: item.value,
                label: item.label,
                checked: item.checked,
                researchUsers: item.researchUsers,
                isDisabled: item.disabled, // Handle disabled state
            }))}
            TextItem="name"
            ValueItem="id"
            className="my-1"
            placeholder="Click to see the list…"
            popQuestion="Team managers have the power to add more members to a team as well as updating team information on this page."
            onChange={(e: any) => {
                setSelectedResearch(e);
                if (!e || e.length === 0) {
                    setManagers([]);
                    setSelectedManagers([]);
                    return;
                }
                const allResearchIds = e.map((research: any) => research.value);
                const selectedProjects = projects?.filter((project) => allResearchIds.includes(project.id));
                if (selectedProjects?.length === 0) {
                    setManagers([]);
                    setSelectedManagers([]);
                    return;
                }
                const researchUsersArrays = selectedProjects?.map((project) => project.researchUsers);
                const commonUsers = researchUsersArrays?.reduce((acc, users) => {
                    return acc?.filter(user => users?.some(u => u.email === user.email));
                }, researchUsersArrays[0] || []); // Start with the first project's users
                const managers = commonUsers?.map((user: any) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.id,
                    checked: false,
                })) || [];
                const defaultManager = managers.find(manager => manager.value === local.getUserId());
                if (defaultManager) {
                    defaultManager.checked = true;
                    setSelectedManagers([defaultManager.value]);
                } else {
                    setSelectedManagers([]); // Clear selected managers if no default
                }

                // Update the managers state
                setManagers(managers);
            }}
            defaultValue={selectedResearch}
            isMulti
        />
        {validator.current.message(
            "Project",
            selectedResearch,
            "required"
        )}
    </div>
            <div className="item">
              <div className="d-flex align-items-center mb-1">
                <label className="color-gray-600 fs-15 me-2">
                  Grant Manager*
                </label>
              </div>
              <NewTreeMenu
                onChange={(manager) => {
                  setSelectedManagers([...manager]);
                }}
                popQuestion="If you already have the teams or individual members for this project invited to Radvix, you can select and assign them here. You can always go back later and add members or teams in the future. Once you create this project, they will get a notification."
                loading={managerLoading}
                disabled={managerLoading}
                options={managers}
              />
              {validator.current.message(
                "Grant manager",
                selectedManagers,
                "required"
              )}
            </div>
          </div>
          <div className="mt-2">
            <SubmitButton
              isEdit={true}
              onClick={handelUpdateGrant}
              loading={isLoading}
              disabled={isLoading || researchLoading || managerLoading}
            >
              Save Grant
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
}
