/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ReactPaginate from "react-paginate";
import { CircleIcon, ThemeCircleIcon } from "../../components/circle_icon";
import { store } from "../../../data/storeMain";
import { SelectComponent } from "../../components/select_input";
import { ResearchController } from "../../../controllers/research/research_controller";
import { ResearchesList } from "../../../data/models/responses/research/researches_res";
import { AppTask } from "../../../data/models/responses/task/get_all_tasks_res";
import { TaskController } from "../../../controllers/task/task_controller";
import { DataList } from "../../../data/models/responses/data/get_all_data_res";
import { DataController } from "../../../controllers/data/data_controller";
import { TeamController } from "../../../controllers/team/team_controller";
import { LocalDataSources } from "../../../data/local_datasources";
import { MemberController } from "../../../controllers/member/member_controller";
import { RouteComponentProps, withRouter } from "react-router";
import { AppRoutes, MOMENT_DATE_TIME_FORMAT } from "../../../core/constants";
import { UserController } from "../../../controllers/user/user_controller";
import { DashboardMyReportResult } from "../../../data/models/responses/user/dashboard_report";
import {
  $ReplaceRoute,
  getUserRole,
  handleNotificationTypeToProfileType,
  isTokenInHttpAuth,
  removeDuplicateObj,
  UserRoles,
  verifyUserSubscription,
} from "../../../core/utils";
import { HTTP } from "../../../core/http_common";
import NewTable from "../../components/NewTable/NewTable";
import moment from "moment";
import debounce from "lodash.debounce";
import Skeleton from "react-loading-skeleton";
import { MainButton, MainButtonType } from "../../components/button";
import AddPayment from "../setting/component/add_Payment";
import { ProfileTypes, SortType } from "../../../core/number_extentions";
import "react-toggle/style.css";
import { RemoteResearch } from "../../../data/remotes/research/remote_research";
import { ImageWithToken } from "../../components/box_list_scroll";
import { Link } from "react-router-dom";
import { ConnectMicrosoft } from "../../components/ConnectMicrosoft/connectMicrosoft";
import { ProductFruits } from "react-product-fruits";
import { BreadCrumbs } from "./component/EmailIntegration/BreadCrumbs";
import { Button, Flex, Table } from "@radix-ui/themes";
import DashboardProjectDropdown from "../../components/ActionComponent/DashboardProjectDropdown";
import RadixTable from "../../components/NewRadixTable/RadixTable";
import {
  OpenInNewWindowIcon,
  CheckIcon,
  ViewGridIcon,
  TransformIcon,
  ChevronDownIcon,
  RocketIcon,
  AvatarIcon,
  RulerSquareIcon,
  Pencil2Icon,
  Pencil1Icon,
  MobileIcon,
  InfoCircledIcon,
  HomeIcon,
  ChatBubbleIcon,
  GearIcon,
  MixerHorizontalIcon,
  EnterFullScreenIcon,
  HamburgerMenuIcon,
  ReaderIcon,
  PieChartIcon,
} from "@radix-ui/react-icons";
import { Label } from "@radix-ui/themes/dist/cjs/components/context-menu";
import SelectRadix from "../../components/SelectRadix/selectRadix";
import Pagination from "../../components/NewRadixTable/RadixPagination";
import { SetNotificationCount } from "../../../data/storeMain/actions/notification_count_action";
import { SetResearchId } from "../../../data/storeMain/actions/research_action";
import ExportModal from "../../components/RadixModal/ExportModal";
import { IFilterOptions } from "../../components/FilterSection/IFilterOptions";
import {
  HeaderTitleAction,
  SetHeaderTitle,
} from "../../../data/storeMain/actions/header_title_action";
type StateType = {
  loadingFirsttime: boolean;
  filterEmail: boolean;
  loading: boolean;
  workSpace: string;
  openedResearch: string;
  openedTask: string;
  openedTeam: string;
  openedData: string;
  breadcrumbs: string;
  profileId: number;
  profileType: number;
  newEmail: boolean;
  isEmail: boolean;
  Researches: ResearchesList[];
  ResearchesPageNumber: number;
  ResearchesPageSize: number;
  ResearchesPageCount: number;
  ResearchesTotalCount: number;
  ResearchesSearch: string;
  Tasks: AppTask[];
  TasksPageNumber: number;
  TasksPageSize: number;
  selectedTaskPageSize: { value: number; label: string };
  TasksPageCount: number;
  TasksTotalCount: number;
  TasksSearch: string;
  Datas: DataList[];
  DatasPageNumber: number;
  DatasPageSize: number;
  DatasPageCount: number;
  DatasTotalCount: number;
  DatasSearch: string;
  notifications: [];
  report: DashboardMyReportResult;
  moz?: any;
  isResearchAssistantActive: boolean;
  notificationSummary: string;
  notificationPageNumber: number;
  notificationCategory: number;
  notificationCategories: number[];
  notificationProjectSelected: any;
  emailProjectSelected: any;
  notificationPageSize: { value: number; label: string };
  notificationPageCount: number;
  notificationTotalCount: number;
  notificationSearch: string;
  dashboardReportLoading: boolean;
  getNotificationLoading: boolean;
  getTaskLoading: boolean;
  userId: string;
  paymentMethodAdded: boolean;
  errorMessage: string;
  showPopup: boolean;
  currentTypingPosition: number;
  researhList: any[];
  defaultResearch: number;
  showProductFruits: any;
};
let unsubscribeMe: any = undefined;

class DashboardPage extends React.Component<RouteComponentProps> {
  handlePageClick = (e: any) => {
    const notificationType = handleNotificationTypeToProfileType(
      this.state.notificationCategory
    );
    if (notificationType == ProfileTypes.RESEARCH) {
      this.setState({
        ResearchesPageNumber: e.selected,
      });
      this.GetResearch(e.selected, this.state.ResearchesPageSize);
    } else if (notificationType == ProfileTypes.TASK) {
      this.setState({
        TasksPageNumber: e.selected,
      });
      this.GetTasks(
        this.state.notificationProjectSelected?.value,
        e.selected,
        this.state.TasksPageSize
      );
    } else if (notificationType == ProfileTypes.DATA) {
      this.setState({
        DatasPageNumber: e.selected,
      });
      this.GetDatas(e.selected, this.state.DatasPageSize);
    } else {
      this.setState({
        notificationPageNumber: e.selected,
      });
      this.handleGetNotification(
        e.selected,
        this.state.notificationPageSize.value,
        this.state.notificationSearch,
        "",
        SortType.AutoSelect,
        this.state.notificationCategory,
        this.state.notificationProjectSelected?.value
      );
    }
  };
  RoleUser = store.getState().userRole;
  researchController = new ResearchController();
  private taskcontroller = new TaskController();
  private Datacontroller = new DataController();
  private userController = new UserController();
  private TeamController = new TeamController();
  remote = new RemoteResearch();
  private memberController: MemberController = new MemberController();
  local = new LocalDataSources();

  state: StateType = {
    loadingFirsttime: false,
    filterEmail: false,
    loading: false,
    workSpace: "",
    openedResearch: "",
    openedTask: "",
    openedTeam: "",
    openedData: "",
    breadcrumbs: "",
    profileId: 0,
    profileType: 0,
    newEmail: false,
    isEmail: false,
    notifications: [],
    Researches: [],
    ResearchesPageNumber: 1,
    ResearchesPageSize: 20,
    ResearchesPageCount: 0,
    ResearchesTotalCount: 0,
    Tasks: [],
    TasksPageNumber: 1,
    TasksPageSize: 10,
    selectedTaskPageSize: { value: 10, label: "10" },
    TasksPageCount: 0,
    TasksTotalCount: 0,
    Datas: [],
    DatasPageNumber: 1,
    DatasPageSize: 10,
    DatasPageCount: 0,
    DatasTotalCount: 0,
    ResearchesSearch: "",
    TasksSearch: "",
    DatasSearch: "",
    dashboardReportLoading: false,
    getNotificationLoading: false,
    getTaskLoading: false,
    report: {
      countEquipment: 0,
      countTaskCompleted: 0,
      countTaskPending: 0,
      countUsers: 0,
      daysLeftDeadline: 0,
      newResearch: false,
      timeLine: 0,
      researchStatus: 0,
      loading: false,
    },
    userId: "",
    paymentMethodAdded: false,
    isResearchAssistantActive: false,
    notificationSummary: "",
    currentTypingPosition: 0,
    notificationPageNumber: 1,
    notificationCategory: 0,
    notificationCategories: [],
    // notificationProjectSelected: { value: 0, label: 'All Projects' },
    notificationProjectSelected: store.getState().ResearchId,
    emailProjectSelected: { value: 0, label: "All Projects" },
    notificationPageSize: { value: 10, label: "10" },
    notificationPageCount: 0,
    notificationTotalCount: 0,
    notificationSearch: "",
    errorMessage: "",
    showPopup: false,
    researhList: [],
    defaultResearch: 0,
    showProductFruits: null,
  };

  handleClose = () => {
    this.setState({
      showPopup: false,
    });
  };

  constructor(props: any) {
    super(props);
    this.handler = this.handler.bind(this);
    this.handleChangeNotificationSearch = debounce(
      this.handleChangeNotificationSearch.bind(this),
      500
    );
    this.handleChangeRecentTaskSearch = debounce(
      this.handleChangeRecentTaskSearch.bind(this),
      500
    );
  }

  GetResearchGroups(
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType,
    filterOptions?: IFilterOptions
  ) {
    this.setState({
      loading: true,
    });
    if (sortDirection == undefined || sortDirection == null) {
      sortDirection = SortType.AutoSelect;
    }
    this.researchController.getGroupResearches(
      {
        PageNumber: PageNumber,
        PageSize: PageSize,
        SearchParameter: "",
        columnHeaderName,
        sortDirection,
        filterOptions,
      },
      (res) => {
        this.setState({
          Researches: res.researchesList,
          PageCount: 1000,
          TotalCount: res.count,
          Groups: res.groups,
          loading: false,
        });

        this.setState({
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  loadResearches() {
    this.researchController.getResearches(
      {
        PageNumber: 1,
        PageSize: 100,
        SearchParameter: "",
        columnHeaderName: "",
        sortDirection: SortType.AutoSelect,
      },
      (res) => {
        let researches: { value: any; label: any }[] = [];
        if (res && res.researchesList && res.researchesList.length > 0) {
          researches = res.researchesList?.map(({ id, title }) => ({
            value: id,
            label: title,
          }));
        }
        this.setState({ researhList: researches });
      },
      (err) => {}
    );
  }

  updateResearchDetails(that: any) {
    return () => {
      that.GetDashboardReport();
    };
  }

  componentDidMount() {
    ;
    document.title = "Dashboard | Radvix";
    store.dispatch(SetHeaderTitle("Dashboard"));
    if (!isTokenInHttpAuth(HTTP)) {
      return;
    }
    this.setState({ loadingFirsttime: true });

    const _isTokenHere = localStorage.getItem("token");
    if (!_isTokenHere) {
      this.props.history.push(AppRoutes.login);
    } else {
      const _userRole = getUserRole();
      if (store.getState().userRole == UserRoles.L1Client) {
        verifyUserSubscription(this.props);
      }
      if (_userRole === UserRoles.L1Client || _userRole === UserRoles.L1User) {
        this.handlePaymentMethod();
        this.GetDashboardReport();
        // @ts-ignore
        this.moz = this.updateResearchDetails(this);
        window.addEventListener(
          "RESEARCH_CHANGED",
          // this.updateResearchDetails(this)
          // @ts-ignore
          this.moz
        );
      }

      if (true) {
        this.GetResearch(
          this.state.ResearchesPageNumber,
          this.state.ResearchesPageSize
        );
        this.GetTasks(
          this.state.notificationProjectSelected?.value,
          this.state.TasksPageNumber,
          this.state.selectedTaskPageSize.value
        );

        this.GetDatas(this.state.DatasPageNumber, this.state.DatasPageSize);
        this.handleGetNotification(
          this.state.notificationPageNumber,
          this.state.notificationPageSize.value,
          this.state.notificationSearch,
          "",
          SortType.AutoSelect,
          this.state.notificationCategory,
          this.state.notificationProjectSelected?.value
        );
        this.getNotificationCategories(
          this.state.notificationPageNumber,
          this.state.notificationPageSize.value,
          ""
        );
      } else {
      }
      unsubscribeMe = store.subscribe(() => {
        if (
          !store.getState().ResearchId ||
          !store.getState().ResearchId.value ||
          store.getState().ResearchId.value === -1
        )
          return;
        this.GetResearch(
          this.state.ResearchesPageNumber,
          this.state.ResearchesPageSize
        );
        this.GetTasks(
          this.state.notificationProjectSelected?.value,
          this.state.TasksPageNumber,
          this.state.selectedTaskPageSize.value
        );
        this.GetDatas(this.state.DatasPageNumber, this.state.DatasPageSize);
        this.handleGetNotification(
          this.state.notificationPageNumber,
          this.state.notificationPageSize.value,
          this.state.notificationSearch,
          "",
          SortType.AutoSelect,
          this.state.notificationCategory,
          this.state.notificationProjectSelected?.value
        );
        this.getNotificationCategories(
          this.state.notificationPageNumber,
          this.state.notificationPageSize.value,
          ""
        );
      });
    }
    this.userController.getEmailIntegrationStatus(
      (res) => {
        if (res == true) {
          this.setState({ isEmail: true });
          this.setState({ applyStyleClass: true });
        } else {
          this.loadResearches();
          this.GetResearchGroups(1, 1000, "");
        }
      },
      (err) => {
        this.loadResearches();
      }
    );

    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const json = JSON.parse(userInfo);
      this.setState({
        showProductFruits: json,
      });
    }

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const queryParams = new URLSearchParams(url.search);
    const isEmail = queryParams.get("isEmail");
    const profileId = queryParams.get("profileId");
    const profileType = parseInt(queryParams.get("profileType") ?? "0", 10);
    const newEmail = queryParams.get("NewEmail");
    var selectedProfileTypeId = 0;
    if (profileType == ProfileTypes.RESEARCH) selectedProfileTypeId = 1;
    else if (profileType == ProfileTypes.DATA) selectedProfileTypeId = 5;
    else if (profileType == ProfileTypes.TEAMPROFILE) selectedProfileTypeId = 2;
    else if (profileType == ProfileTypes.TASK) selectedProfileTypeId = 4;
    else if (profileType == ProfileTypes.LABORTARYPROFILE)
      selectedProfileTypeId = 3;
    else if (profileType == ProfileTypes.PUBLICATIONFLOW)
      selectedProfileTypeId = 7;
    this.setState({
      isEmail: isEmail,
      profileId: profileId,
      profileType: profileType,
      newEmail: newEmail,
      notificationCategory: selectedProfileTypeId,
      workSpace: store.getState().userInfo.workSpaceName,
    });
  }

  // clean up event listener
  componentWillUnmount() {
    window.removeEventListener(
      "RESEARCH_CHANGED",
      // @ts-ignore
      this.moz
    );
    if (unsubscribeMe) unsubscribeMe();
  }

  handleChangeProject(e: any) {
    if (this.state.isEmail) {
      this.setState(
        {
          emailProjectSelected: e,
        },
        () => {
          if (this.state.notificationCategory == 0) {
            this.props.history.push(
              $ReplaceRoute(`${AppRoutes.dashboard}?isEmail=true`)
            );
          } else {
            this.props.history.push(
              $ReplaceRoute(
                `${AppRoutes.dashboard}?isEmail=true&profileId=${this.state.emailProjectSelected?.value}&profileType=${this.state.notificationCategory}`
              )
            );
          }
        }
      );
    } else {
      this.setState({
        notificationProjectSelected: e,
      });
    }
    store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    // if (e && e.value != 0) {
    //   store.dispatch(SetResearchId({ label: e?.label, value: e?.value }));
    // }
    if (e && e.value != 0) {
      this.userController.lastSelectedResearch(
        e.value,
        (res) => {},
        () => {
          // setTimelineLoading(false);
        }
      );
    }
    this.handleGetNotification(
      this.state.notificationPageNumber,
      this.state.notificationPageSize.value,
      this.state.notificationSearch,
      "",
      SortType.AutoSelect,
      this.state.notificationCategory,
      e.value
    );
  }

  handleClearClick() {}

  handler() {
    this.setState((prevState: any) => ({
      profileId: null,
      profileType: null,
      newEmail: null,
      filterEmail: true,
    }));
    let url = new URL(window.location.href);
    url.searchParams.delete("profileId");
    url.searchParams.delete("profileType");
    window.history.replaceState({}, document.title, url.toString());
    // this.props.history.push(
    //   $ReplaceRoute(
    //     `${AppRoutes.dashboard}?isEmail=true&profileType=${this.state.notificationCategory}`
    //   )
    // );
  }

  handleCategoryNotificationSearch(e: number) {
    this.setState({
      notificationCategory: e,
      profileId: null,
      profileType: null,
      newEmail: null,
      filterEmail: true,
    });
    let url = new URL(window.location.href);
    url.searchParams.delete("profileId");
    url.searchParams.delete("profileType");
    url.searchParams.delete("NewEmail");
    url.searchParams.delete("isEmail");
    window.history.replaceState({}, document.title, url.toString());

    this.handleGetNotification(
      this.state.notificationPageNumber,
      this.state.notificationPageSize.value,
      "",
      "",
      SortType.AutoSelect,
      e,
      this.state.notificationProjectSelected?.value
    );
  }

  handlePaymentMethod() {
    HTTP.post(`/User/PaymentMethodInfo`)
      .then((res) => {
        if (res.data.result == false) {
          this.setState({
            showPopup: true,
            errorMessage: res.data.result.subscriptionEndedMessage,
          });
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error adding payment method:", error);
      });
  }

  GetResearch(PageNumber: number, PageSize: number) {
    const _isTokenHere = localStorage.getItem("token");
    if (_isTokenHere) {
      this.researchController.getResearches(
        {
          PageNumber: PageNumber,
          PageSize: PageSize,
          SearchParameter: this.state.ResearchesSearch,
        },
        (res) => {
          this.setState({
            Researches: res.researchesList,
            ResearchesPageCount: Math.ceil(
              res.count! / this.state.ResearchesPageSize
            ),
            ResearchesTotalCount: res.count,
            userId: res.userId,
          });
        },
        (err) => {}
      );
    }
  }

  handleGetNotification(
    // _selectedResearchId: number,
    _pageNumber: number,
    _pageSize: number,
    _search: string,
    _columnHeaderName: string,
    _sortDirection: SortType,
    _category: number,
    _researchId: number
  ) {
    // const _selectedResearchId =this.state.notificationProjectSelected?.value; //store.getState().ResearchId.value;
    const _selectedResearchId = _researchId;

    this.setState({
      getNotificationLoading: true,
    });
    if (_sortDirection == undefined || _sortDirection == null) {
      _sortDirection = SortType.AutoSelect;
    }
    var category = _category;
    this.userController.getNotification(
      {
        researchId: _selectedResearchId,
        pageNumber: _pageNumber,
        pageSize: _pageSize,
        searchParameter: _search,
        columnName: _columnHeaderName,
        sortDirection:
          _sortDirection === SortType.ASC
            ? 0
            : _sortDirection === SortType.DESC
            ? 1
            : 2,
        notificationCategory: category,
      },
      (_notifications) => {
        // Dispatch the Nitification Count from Here

        this.setState({
          notifications: _notifications.notifications,
          notificationPageCount: Math.ceil(
            _notifications.count! / this.state.notificationPageSize.value
          ),
          notificationTotalCount: _notifications.count,
          getNotificationLoading: false,
        });
      },
      (error) => {
        this.setState({
          getNotificationLoading: false,
        });
      }
    );
  }

  getNotificationCategories(
    _pageNumber: number,
    _pageSize: number,
    _search: string
  ) {
    this.userController.getNotification(
      {
        pageNumber: _pageNumber,
        pageSize: _pageSize,
        searchParameter: _search,
      },
      (_notifications) => {
        const currentCount = store.getState().notificationCount;
        if (_notifications.count !== currentCount) {
          store.dispatch(SetNotificationCount(_notifications.count));
        }
        const categoriesId = new Set(
          _notifications.notifications.map(
            (notification: any) => notification.categoryId
          )
        );
        this.setState({
          notificationCategories: Array.from(categoriesId),
        });
      },
      (error) => {}
    );
  }

  ResearchAIAssistant = async (currentState: boolean) => {
    this.setState({
      isResearchAssistantActive: !currentState,
      notificationSummary: null,
      currentTypingPosition: 0,
    });

    if (currentState === false) {
      const ResearchId = store.getState().ResearchId?.value;

      this.userController.summarizeNotifications(
        ResearchId,

        (_res) => {
          if (_res != null) {
            this.setState({
              notificationSummary: _res,
            });
          } else {
            this.setState({
              notificationSummary: "No Response From ChatGPT",
            });
          }
        },
        (_err) => {}
      );
    }
  };

  handleSingleReadNotification(e: number) {
    const _allNotificationsIds = [];
    _allNotificationsIds.push(e);

    // return;
    this.userController.readNotification(
      {
        notificationIds: _allNotificationsIds,
      },
      (_res) => {
        this.handleGetNotification(
          this.state.notificationPageNumber,
          this.state.notificationPageSize.value,
          this.state.notificationSearch,
          "",
          SortType.AutoSelect,
          this.state.notificationCategory,
          this.state.notificationProjectSelected?.value
        );
        this.getNotificationCategories(
          this.state.notificationPageNumber,
          this.state.notificationPageSize.value,
          ""
        );
      },
      (_err) => {}
    );
  }

  handleReadNotifications() {
    const _allNotificationsIds = this.state.notifications.map(
      (_notif: any) => _notif.id
    );

    // return;
    this.userController.readNotification(
      {
        notificationIds: _allNotificationsIds,
      },
      (_res) => {
        //this.state.userId = _res.userId,
        this.handleGetNotification(
          this.state.notificationPageNumber,
          this.state.notificationPageSize.value,
          this.state.notificationSearch,
          "",
          SortType.AutoSelect,
          this.state.notificationCategory,
          this.state.notificationProjectSelected?.value
        );
      },
      (_err) => {}
    );
  }

  HandleOnClickRedirect(_row: any) {
    window.dispatchEvent(
      new CustomEvent("HANDLE_RESEARCH_CHANGED", {
        detail: {
          name: _row.researchName,
          id: _row.researchId,
        },
      })
    );

    this.props.history.push(_row.redirectUrl?.toString());
  }

  GetDashboardReport() {
    console.error("GetDashboardReport", store.getState().ResearchId);
    if (
      store.getState().ResearchId?.value === 0 ||
      store.getState().ResearchId?.value === undefined
    ) {
      this.setState({
        report: {
          countEquipment: 0,
          countTaskCompleted: 0,
          countTaskPending: 0,
          countUsers: 0,
          daysLeftDeadline: 0,
          newResearch: false,
          timeLine: 0,
          researchStatus: 0,
          loading: false,
        },
        dashboardReportLoading: false,
        getNotificationLoading: false,
        getTaskLoading: false,
      });
      return;
    }
    if (
      !store.getState().ResearchId ||
      store.getState().ResearchId.value === -1
    )
      return;

    this.setState({
      dashboardReportLoading: true,
    });

    const _selectedResearchId = store.getState().ResearchId.value;

    this.userController.dashboardReport(
      // store.getState().ResearchId.value,
      _selectedResearchId,
      (res) => {
        //
        this.setState({
          dashboardReportLoading: false,
        });
        this.setState({
          report: {
            countEquipment: res.countEquipment,
            countTaskCompleted: res.countTaskCompleted,
            countTaskPending: res.countTaskPending,
            countUsers: res.countUsers,
            daysLeftDeadline: res.daysLeftDeadline,
            newResearch: res.newResearch,
            researchStatus: res.researchStatus,
          },
        });
        localStorage.setItem("newResearch", res.newResearch.toString());
      },
      () => {
        this.setState({
          dashboardReportLoading: true,
        });
      }
    );
  }

  TaskheaderClick = (columnHeaderName?: string, sortDirection?: SortType) => {
    this.GetTasks(
      this.state.notificationProjectSelected?.value,
      this.state.TasksPageNumber,
      this.state.TasksPageSize,
      this.state.TasksSearch,
      columnHeaderName,
      sortDirection
    );
  };

  GetTasks(
    researchId: number,
    PageNumber: number,
    PageSize: number,
    Search?: string,
    columnHeaderName?: string,
    sortDirection?: SortType
  ) {
    this.setState({
      getTaskLoading: true,
    });
    this.taskcontroller.getTasks(
      {
        researchId,
        PageNumber,
        PageSize,
        SearchParameter: Search ? Search : this.state.TasksSearch,
        columnHeaderName,
        sortDirection,
      },
      (res) => {
        if (!res) {
          this.setState({
            getTaskLoading: false,
          });
          return;
        }
        const _tasks = res.appTasks.map((_task) => {
          let _taskTeamUsers = _task.appTask.teams
            .map((_t) => _t?.users)
            .flat();

          const _usersWithTeamUsers = [
            ..._task.appTask.users,
            ..._taskTeamUsers,
          ];

          return {
            appTask: {
              ..._task.appTask,
              users: removeDuplicateObj(_usersWithTeamUsers),
            },
            subAppTasks: _task.subAppTasks.map((_subTask) => {
              let _subtaskTeamUsers = _subTask.teams
                .map((_t) => _t?.users)
                .flat();

              const _usersWithTeamUsers = [
                ..._subTask.users,
                ..._subtaskTeamUsers,
              ];
              return {
                ..._subTask,
                users: removeDuplicateObj(_usersWithTeamUsers),
              };
            }),
          };
        });

        this.setState({
          Tasks: _tasks,
          TasksPageCount: Math.ceil(
            res.count! / this.state.selectedTaskPageSize.value
          ),
          TasksTotalCount: res.count,
          getTaskLoading: false,
        });
      },
      (err) => {
        this.setState({
          getTaskLoading: false,
        });
      }
    );
  }

  GetDatas(PageNumber: number, PageSize: number) {
    this.Datacontroller.getAllData(
      { PageNumber, PageSize, SearchParameter: this.state.DatasSearch },
      (res) => {
        if (!res) return;
        this.setState({
          Datas: res.dataLists,
          DatasPageCount: Math.ceil(res.count! / this.state.DatasPageSize),
          DatasTotalCount: res.count,
        });
      },
      (err) => {}
    );
  }

  handelChangePageNumber(e: { selected: number }, Type: string) {
    if (Type === "Researches") {
      this.setState({
        ResearchesPageNumber: e.selected,
      });
      this.GetResearch(e.selected + 1, this.state.ResearchesPageSize);
    } else if (Type === "Tasks") {
      this.setState({
        TasksPageNumber: e.selected,
      });
      this.GetTasks(
        this.state.notificationProjectSelected?.value,
        e.selected + 1,
        this.state.selectedTaskPageSize.value
      );
    } else {
      this.setState({
        DatasPageNumber: e.selected,
      });
      this.GetDatas(e.selected + 1, this.state.DatasPageSize);
    }
  }

  handelChangePageSize(e: { label: string; value: number }, Type: number) {
    const notificationType = handleNotificationTypeToProfileType(Type);
    if (notificationType == ProfileTypes.RESEARCH) {
      this.setState({
        ResearchesPageSize: e.value,
      });
      this.GetResearch(this.state.ResearchesPageNumber, e.value);
    } else if (notificationType == ProfileTypes.TASK) {
      this.setState({
        TasksPageSize: e.value,
      });
      this.GetTasks(
        this.state.notificationProjectSelected?.value,
        this.state.TasksPageNumber,
        e.value
      );
    } else if (notificationType == ProfileTypes.DATA) {
      this.setState({
        DatasPageSize: e.value,
      });
      this.GetDatas(this.state.DatasPageNumber, e.value);
    } else {
      this.setState({
        notificationPageSize: e,
      });
      this.handleGetNotification(
        this.state.notificationPageNumber,
        e.value,
        this.state.notificationSearch,
        "",
        SortType.AutoSelect,
        this.state.notificationCategory,
        this.state.notificationProjectSelected?.value
      );
    }
  }

  handleChangeNotificationSearch(e: any) {
    this.handleGetNotification(
      this.state.notificationPageNumber,
      this.state.notificationPageSize.value,
      e.target.value,
      "",
      SortType.AutoSelect,
      this.state.notificationCategory,
      this.state.notificationProjectSelected?.value
    );
  }

  headerClick = (columnName?: string, sortDirection?: SortType) => {
    this.handleGetNotification(
      this.state.notificationPageNumber,
      this.state.notificationPageSize.value,
      this.state.notificationSearch,
      columnName || "",
      sortDirection || SortType.AutoSelect,
      this.state.notificationCategory,
      this.state.notificationProjectSelected?.value
    );
  };

  handleNotificationClick = () => {
    this.setState({ isEmail: false });
    this.setState({ applyStyleClass: false });
  };

  handleEmailClick = () => {
    this.setState({ isEmail: true });
    this.setState({ applyStyleClass: true });
  };

  handleChangeRecentTaskSearch(e: any) {
    this.GetTasks(
      this.state.TasksPageNumber,
      this.state.selectedTaskPageSize.value,
      e.target.value
    );
  }

  formatRedirectUrl = (redirectURl: string, researchId: any) => {
    if(redirectURl != undefined)
    {
      if (redirectURl.includes("Discussion/panel")) {
        const parts = redirectURl.split("/");
        const chatId = parts[parts.length - 1];
        return `Discussion/panel?discussionId=${chatId}`;
      }
      if (redirectURl.includes("Task")) {
        return redirectURl + "?researchId=" + researchId;
      }
      return redirectURl;
    }    
  };

  render() {
    const _userRole = getUserRole();
    return (
      <>
        {this.state.loadingFirsttime === true && (
          <div
            style={{
              background: "#fff",
              border: "1px solid rgba(0, 0, 0, 0.175)",
            }}
          >
            <div className="d-flex flex-column p-3 gap-3 flex-lg-row align-items-lg-center justify-content-lg-between flex-lg-wrap">
              <h2 className="fw-medium fs-15 mb-0 color-gray-1000">
                Recent Update
              </h2>

              <div className="d-flex flex-column gap-2 flex-sm-row align-items-sm-center">
                <h2 className="fw-normal flex-shrink-0 fs-15 mb-0 color-gray-600 ">
                  Filter by project :
                </h2>

                <div className="position-relative w-100 w-sm-auto flex-grow-1 dashboard-drowpdown-wrapper">
                  <DashboardProjectDropdown
                    id={1}
                    items={this.state.researhList}
                    onSelect={(e) => {
                      this.handleChangeProject(e);
                    }}
                    selectedItems={this.state.notificationProjectSelected}
                    className="dashboard-filter-project-dropdown"
                  />
                </div>
              </div>
            </div>

            <div className="row flex-nowrap scrollmenu">
              <div className="dashboardProjectDetail dashboard-section overviwe align-items-center">
                <div
                  className={
                    this.state.notificationCategory === 0
                      ? "overviwe-item"
                      : "overviwe-item button-dashboard-category"
                  }
                  onClick={(e) => {
                    this.handleCategoryNotificationSearch(0);
                  }}
                >
                  <div className="dashboardProjectDetailItem">
                    <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                      <div
                        className={
                          this.state.notificationCategory === 0
                            ? "dashboardSelectedCategory"
                            : "dashboardCategory"
                        }
                      >
                        <ViewGridIcon
                          color={
                            this.state.notificationCategory === 0
                              ? "white"
                              : "black"
                          }
                          className={"dashboardCategoryIconAll"}
                        ></ViewGridIcon>
                        {/* <img
                      src={
                        this.state.notificationCategory === 0
                          ? "/images/icons/world.svg"
                          : "/images/icons/worldBlack.svg"
                      }
                      alt="Research"
                      className={"dashboardCategoryIconAll"}
                    /> */}
                        <span className="textWhite">All</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.notificationCategory === 1
                      ? "overviwe-item"
                      : "overviwe-item button-dashboard-category"
                  }
                  onClick={(e) => {
                    this.setState({
                      notificationCategory: 1,
                    });
                    this.handleCategoryNotificationSearch(1);
                  }}
                >
                  <div className=" dashboardProjectDetailItem">
                    <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                      <div
                        className={
                          this.state.notificationCategory === 1
                            ? "dashboardSelectedCategory"
                            : "dashboardCategory"
                        }
                      >
                        <TransformIcon
                          color={
                            this.state.notificationCategory === 1
                              ? "white"
                              : "black"
                          }
                          className={"dashboardCategoryIconAll"}
                        ></TransformIcon>

                        <span className="textWhite">Research</span>

                        {this.state.notificationCategories.includes(1) && (
                          <span
                            className="d-inline-block bg-danger rounded-circle"
                            style={{ width: "6px", height: "6px" }}
                          ></span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!this.state.isEmail ? (
                  <div
                    className={
                      this.state.notificationCategory === 2
                        ? "overviwe-item"
                        : "overviwe-item button-dashboard-category"
                    }
                    onClick={(e) => {
                      this.handleCategoryNotificationSearch(2);
                    }}
                  >
                    <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                      <div
                        className={
                          this.state.notificationCategory === 2
                            ? "dashboardSelectedCategory"
                            : "dashboardCategory"
                        }
                      >
                        <AvatarIcon
                          color={
                            this.state.notificationCategory === 2
                              ? "white"
                              : "black"
                          }
                          className={"dashboardCategoryIconAll"}
                        ></AvatarIcon>

                        <span className="textWhite">Team</span>
                        {this.state.notificationCategories.includes(2) && (
                          <span
                            className="d-block bg-danger rounded-circle"
                            style={{ width: "6px", height: "6px" }}
                          ></span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  className={
                    this.state.notificationCategory === 3
                      ? "overviwe-item"
                      : "overviwe-item button-dashboard-category"
                  }
                  onClick={(e) => {
                    this.handleCategoryNotificationSearch(3);
                  }}
                >
                  <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                    <div
                      className={
                        this.state.notificationCategory === 3
                          ? "dashboardSelectedCategory"
                          : "dashboardCategory"
                      }
                    >
                      <RulerSquareIcon
                        color={
                          this.state.notificationCategory === 3
                            ? "white"
                            : "black"
                        }
                        className={"dashboardCategoryIconAll"}
                      ></RulerSquareIcon>

                      <span className="textWhite">Laboratory</span>
                      {this.state.notificationCategories.includes(3) && (
                        <span
                          className="d-block bg-danger rounded-circle"
                          style={{ width: "6px", height: "6px" }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.notificationCategory === 4
                      ? "overviwe-item"
                      : "overviwe-item button-dashboard-category"
                  }
                  onClick={(e) => {
                    this.handleCategoryNotificationSearch(4);
                  }}
                >
                  <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                    <div
                      className={
                        this.state.notificationCategory === 4
                          ? "dashboardSelectedCategory"
                          : "dashboardCategory"
                      }
                    >
                      <ReaderIcon
                        color={
                          this.state.notificationCategory === 4
                            ? "white"
                            : "black"
                        }
                        className={"dashboardCategoryIconAll"}
                      ></ReaderIcon>

                      <span className="textWhite">Task</span>
                      {this.state.notificationCategories.includes(4) && (
                        <span
                          className="d-block bg-danger rounded-circle"
                          style={{ width: "6px", height: "6px" }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.notificationCategory === 5
                      ? "overviwe-item"
                      : "overviwe-item button-dashboard-category"
                  }
                  onClick={(e) => {
                    this.handleCategoryNotificationSearch(5);
                  }}
                >
                  <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                    <div
                      className={
                        this.state.notificationCategory === 5
                          ? "dashboardSelectedCategory"
                          : "dashboardCategory"
                      }
                    >
                      <PieChartIcon
                        color={
                          this.state.notificationCategory === 5
                            ? "white"
                            : "black"
                        }
                        className={"dashboardCategoryIconAll"}
                      ></PieChartIcon>
                      <span className="textWhite">Data</span>
                      {this.state.notificationCategories.includes(5) && (
                        <span
                          className="d-block bg-danger rounded-circle"
                          style={{ width: "6px", height: "6px" }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
                {!this.state.isEmail ? (
                  <div
                    className={
                      this.state.notificationCategory === 6
                        ? "overviwe-item"
                        : "overviwe-item button-dashboard-category"
                    }
                    onClick={(e) => {
                      this.handleCategoryNotificationSearch(6);
                    }}
                  >
                    <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                      <div
                        className={
                          this.state.notificationCategory === 6
                            ? "dashboardSelectedCategory"
                            : "dashboardCategory"
                        }
                      >
                        <ChatBubbleIcon
                          color={
                            this.state.notificationCategory === 6
                              ? "white"
                              : "black"
                          }
                          className={"dashboardCategoryIconAll"}
                        ></ChatBubbleIcon>
                        <span className="textWhite">Chat</span>
                        {this.state.notificationCategories.includes(6) && (
                          <span
                            className="d-block bg-danger rounded-circle"
                            style={{ width: "6px", height: "6px" }}
                          ></span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  className={
                    this.state.notificationCategory === 7
                      ? "overviwe-item"
                      : "overviwe-item button-dashboard-category"
                  }
                  onClick={(e) => {
                    this.handleCategoryNotificationSearch(7);
                  }}
                >
                  <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                    <div
                      className={
                        this.state.notificationCategory === 7
                          ? "dashboardSelectedCategory"
                          : "dashboardCategory"
                      }
                    >
                      <Pencil1Icon
                        color={
                          this.state.notificationCategory === 7
                            ? "white"
                            : "black"
                        }
                        className={"dashboardCategoryIconAll"}
                      ></Pencil1Icon>

                      <span className="textWhite">Write & Publish</span>
                      {this.state.notificationCategories.includes(7) && (
                        <span
                          className="d-block bg-danger rounded-circle"
                          style={{ width: "6px", height: "6px" }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
                {!this.state.isEmail ? (
                  <div
                    className={
                      this.state.notificationCategory === 8
                        ? "overviwe-item"
                        : "overviwe-item button-dashboard-category"
                    }
                    onClick={(e) => {
                      this.handleCategoryNotificationSearch(8);
                    }}
                  >
                    <div className="d-flex flex-column align-items-center dashboardProjectDetailItem">
                      <div
                        className={
                          this.state.notificationCategory === 8
                            ? "dashboardSelectedCategory"
                            : "dashboardCategory"
                        }
                      >
                        <img
                          src={
                            this.state.notificationCategory === 8
                              ? "/images/images/money-white.svg"
                              : "/images/images/money.svg"
                          }
                          className={"dashboardCategoryIconAll"}
                        />

                        <span className="textWhite">Financials</span>
                        {this.state.notificationCategories.includes(8) && (
                          <span
                            className="d-block bg-danger rounded-circle"
                            style={{ width: "6px", height: "6px" }}
                          ></span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <div className="item align-items-center justify-content-end layout-width-dashboard">
              <h6 className="small-label">Filter Projects:</h6>
              <div className="mt-1 d-flex justify-content-between align-items-center">
                <SelectComponent
                  // disabled={this.state.searchLoading}
                  items={this.state.researhList}
                  TextItem="name"
                  ValueItem="id"
                  className="w-100"
                  placeholder="All Projects"
                  isMulti={false}
                  onChange={(e) => {
                    this.handleChangeProject(e);
                  }}
                  defaultValue={
                    this.state.isEmail
                      ? this.state.emailProjectSelected
                      : this.state.notificationProjectSelected
                  }
                ></SelectComponent>
              </div>
            </div> */}
              </div>
            </div>
            {!this.state.isEmail && (
              <div className="" style={{ marginLeft: 0 }}>
                {this.state.errorMessage !== "" && this.state.showPopup && (
                  <div className="overlay">
                    <div className="popup">
                      <button
                        className="close-button"
                        onClick={this.handleClose}
                        style={{
                          float: "right",
                          color: "rgb(255, 255, 255)",
                          cursor: "pointer",
                          padding: "8px",
                        }}
                      >
                        X
                      </button>
                      <AddPayment
                        usersId={this.state.userId}
                        showCloseButton={false}
                      />
                    </div>
                  </div>
                )}
                {_userRole === UserRoles.L1Client ||
                _userRole === UserRoles.L1User ? (
                  <div className="col-12" style={{}}>
                    <HeadDashboardPage
                      loading={this.state.dashboardReportLoading}
                      countEquipment={this.state.report.countEquipment}
                      countTaskCompleted={this.state.report.countTaskCompleted}
                      countTaskPending={this.state.report.countTaskPending}
                      countUsers={this.state.report.countUsers}
                      daysLeftDeadline={this.state.report.daysLeftDeadline}
                      newResearch={this.state.report.newResearch}
                      timeLine={this.state.report.timeLine}
                      researchStatus={this.state.report.researchStatus}
                    ></HeadDashboardPage>
                  </div>
                ) : null}

                <div className="col-12">
                  <div
                    className=" dashboard-bottom-section"
                    style={{
                      marginTop: "0px",
                      borderRadius: "0 0 14px 14px !important",
                    }}
                  >
                    {this.state.isResearchAssistantActive && (
                      <div className={`Research-AI-textbox`}>
                        {!this.state.notificationSummary ? (
                          <>
                            <Skeleton
                              width="100%"
                              height="1.8em"
                              className="typewriter"
                              style={{ marginBottom: "-10px" }}
                            />
                            <Skeleton
                              width="100%"
                              height="1.8em"
                              className="typewriter"
                              style={{ marginBottom: "-10px" }}
                            />
                            <Skeleton
                              width="100%"
                              height="1.8em"
                              className="typewriter"
                              style={{ marginBottom: "-10px" }}
                            />
                          </>
                        ) : (
                          <span>
                            <div
                              style={{
                                border: "none",
                                outline: "none",
                                width: "100%",
                                resize: "none",
                                overflowY: "auto",
                                overflowX: "hidden",
                                height: "300px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: this.state.notificationSummary,
                              }}
                            ></div>
                          </span>
                        )}
                      </div>
                    )}
                    <RadixTable
                      data={this.state.notifications}
                      loading={this.state.getNotificationLoading}
                      onHeaderClick={(
                        columnHeaderName: string,
                        sortDirection: SortType
                      ) => this.headerClick(columnHeaderName, sortDirection)}
                      // customHeaderWidth={false}
                      columns={[
                        {
                          Header: "Event Type",
                          accessor: "notificationtypetext",
                          width: "207px",
                          Cell: (_props: any) => {
                            return (
                              <Table.Cell className="p-3">
                                <div className="line-clamp-2">
                                  <i className="fa fa-grip-vertical me-2 color-gray-600 fs-13"></i>

                                  <Link
                                    className="fontBoldSm"
                                    to={this.formatRedirectUrl(
                                      _props.redirectUrl?.toString(),
                                      _props.researchId
                                    )}
                                    onClick={() => {
                                      window.dispatchEvent(
                                        new CustomEvent(
                                          "HANDLE_RESEARCH_CHANGED",
                                          {
                                            detail: {
                                              name: _props.researchName,
                                              id: _props.researchId,
                                            },
                                          }
                                        )
                                      );
                                    }}
                                  >
                                    {_props.notificationTypeText}
                                  </Link>
                                </div>
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: "User",
                          accessor: "userfullname",
                          width: "235px",
                          Cell: (_row: any) => {
                            return (
                              <Table.Cell className="p-3 ">
                                <div className="d-flex dNoneSm align-items-center">
                                  <ImageWithToken
                                    originalImage={_row.profileImage}
                                    hasImage={_row.profileImage ? true : false}
                                    alt="Avatar"
                                    className="rounded-circle avatar rounded-avatar-dashboard"
                                    src={
                                      _row.profileImage === null ||
                                      _row.profileImage === "" ||
                                      _row.profileImage === undefined
                                        ? "/images/images/img_avatar.png"
                                        : _row.profileImage
                                    }
                                  />

                                  <span
                                    className="dashboardGridText"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.props.history.push(
                                        $ReplaceRoute(
                                          `${AppRoutes.member_profile.replace(
                                            ":id",
                                            _row.createdBy.toString() ?? ""
                                          )}`
                                        )
                                      );
                                    }}
                                  >
                                    {_row.userFullName}
                                  </span>
                                </div>{" "}
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: "Name",
                          accessor: "title",
                          width: "307px",
                          Cell: (_row: any) => {
                            return (
                              <Table.Cell className="p-3 text-nowrap">
                                <div className="overflowTextInGrid dashboardGridText">
                                  {_row.categoryId != 8 ? (
                                  <Link
                                    className="fontBoldSm"
                                    to={this.formatRedirectUrl(
                                      _row.redirectUrl?.toString(),
                                      _row.researchId
                                    )}
                                    onClick={() => {
                                      window.dispatchEvent(
                                        new CustomEvent(
                                          "HANDLE_RESEARCH_CHANGED",
                                          {
                                            detail: {
                                              name: _row.researchName,
                                              id: _row.researchId,
                                            },
                                          }
                                        )
                                      );
                                    }}
                                  >
                                    {_row.title}
                                  </Link>
                                  ): 
                                  <>{_row.message}</>                                  
                                  }
                                </div>
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: "Date and Time",
                          accessor: "createdat",
                          width: "225px",
                          Cell: (_row: any) => {
                            return (
                              <Table.Cell className="p-3 text-nowrap">
                                <div className="textWhiteSm dashboardGridText">
                                  {moment(_row.createdAt).format(
                                    MOMENT_DATE_TIME_FORMAT
                                  )}
                                </div>
                              </Table.Cell>
                            );
                          },
                        },
                        {
                          Header: "",
                          width: "200px",
                          Cell: (_row: any) => {
                            return (
                              <Table.Cell className="p-3 text-nowrap">
                                <div className="d-flex  dNoneSm">
                                  <div style={{ marginRight: "0.5rem" }}>
                                    <MainButton
                                      onClick={() =>
                                        this.HandleOnClickRedirect(_row)
                                      }
                                      className="btn btn-soft-primary btn-sm fs-13 px-2 h-px-25 py-0 "
                                    >
                                      {" "}
                                      <OpenInNewWindowIcon></OpenInNewWindowIcon>
                                      View
                                    </MainButton>
                                  </div>
                                  <button>
                                    <div
                                      className="d-flex h-px-25 w-px-25 p-0 align-items-center justify-content-center
                                   markAsChecked"
                                    >
                                      <CheckIcon
                                        style={{
                                          height: "15px",
                                          width: "15px",
                                          color: "#299764",
                                        }}
                                        onClick={(e) =>
                                          this.handleSingleReadNotification(
                                            _row.id
                                          )
                                        }
                                      ></CheckIcon>
                                    </div>
                                  </button>
                                </div>
                              </Table.Cell>
                            );
                          },
                        },
                      ]}
                    />

                    {this.state.notificationTotalCount > 0 && (
                      <Pagination
                        pageCount={this.state.notificationPageCount}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={(e: any) => {
                          this.handelChangePageSize(
                            e,
                            this.state.notificationCategory
                          );
                        }}
                      />
                    )}
                    {/* <div className="d-flex justify-content-between align-items-center">
                  <div className="dFlexSm dNone hidden">
                    <SelectComponent
                      className="me-1"
                      width="140px"
                      height="44px"
                      defaultValue={this.state.notificationPageSize}
                      selectProps={{ isSearchable: false }}
                      items={[
                        { label: "10", value: 10 },
                        { label: "15", value: 15 },
                        { label: "20", value: 20 },
                      ]}
                      TextItem="item"
                      ValueItem="id"
                      isMulti={false}
                      placeholder="Select"
                      onChange={(e) => {
                        this.setState({
                          notificationPageSize: e,
                        });
                        this.handleGetNotification(
                          this.state.notificationPageNumber,
                          e.value,
                          this.state.notificationSearch,
                          "",
                          SortType.AutoSelect,
                          this.state.notificationCategory,
                          this.state.notificationProjectSelected?.value
                        );
                      }}
                    ></SelectComponent>
                  </div>
                  {this.state.notificationPageCount > 1 ? (
                    <div className="d-flex justify-content-end flex-fill">
                      <ReactPaginate
                        previousLabel={
                          <CircleIcon
                            padding="0"
                            width="24px"
                            backgroundColor="#4E0057"
                            height="24px"
                            type={ThemeCircleIcon.dark}
                          >
                            <i className="fas fa-chevron-left"></i>
                          </CircleIcon>
                        }
                        nextLabel={
                          <CircleIcon
                            width="24px"
                            padding="0"
                            backgroundColor="#4E0057"
                            height="24px"
                            type={ThemeCircleIcon.dark}
                          >
                            <i className="fas fa-angle-right"></i>
                          </CircleIcon>
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.notificationPageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(e) => {
                          // this.handelChangePageNumber(e, "Researches");

                          this.setState({
                            notificationPageNumber: e.selected,
                          });
                          this.handleGetNotification(
                            e.selected + 1,
                            this.state.notificationPageSize.value,
                            this.state.notificationSearch,
                            "",
                            SortType.AutoSelect,
                            this.state.notificationCategory,
                            this.state.notificationProjectSelected?.value
                          );
                        }}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  ) : null}

                  {this.state.notificationPageCount > 0 ? (
                    <div className="d-flex justify-content-end flex-fill hidden">
                      <p className="text-right mb-0 hidden">
                        Total Results: {this.state.notificationTotalCount}
                      </p>
                    </div>
                  ) : null}
                </div> */}
                  </div>
                </div>
              </div>
            )}
            {this.state.isEmail ? (
              <>
                {this.state.profileId ? (
                  <div className="overviwe" style={{ borderRadius: "0px" }}>
                    <div style={{ borderRadius: "10px", display: "flex" }}>
                      <BreadCrumbs
                        profileId={this.state.profileId}
                        profileType={this.state.profileType}
                        handler={this.handler}
                      />
                    </div>
                  </div>
                ) : null}
                <div
                  className="dashboardProjectDetail dashboard-section overviwe align-items-center changePadding-email"
                  style={{ display: "block" }}
                >
                  <Flex direction="column" gap="2">
                    <Button variant="classic" size={"4"}>
                      This is Radix button
                    </Button>
                    <Button>Let's go</Button>
                  </Flex>
                </div>
              </>
            ) : null}
            {this.state.showProductFruits && (
              <ProductFruits
                workspaceCode="gzFjsS9us8sSpCda"
                language="en"
                user={{
                  username: `${this.state.showProductFruits.email || ""}`,
                  email: `${this.state.showProductFruits.email || ""}`,
                  role: `${this.state.showProductFruits.role || ""}`,
                  signUpAt: undefined,
                  firstname: `${this.state.showProductFruits.firstName || ""}`,
                  lastname: `${this.state.showProductFruits.lastName || ""}`,
                  props: undefined,
                  hmac: undefined,
                  group: undefined,
                }}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const HeadDashboardPage: React.FC<DashboardMyReportResult> = (props) => {
  return <div></div>;
};
export default withRouter(DashboardPage);
